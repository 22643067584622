.offer-grid {
  position: relative;
  width: 100%;
  height: auto;
  &__background {
    width: 100%;
    height: 100%;
    &--mobile {
      width: 100%;
      height: 100%;
      @include breakpoint($portrait-up) {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @include breakpoint($portrait-up) {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  } // &__background-image

  &__content {
    position: absolute;
    top: 0;
    bottom: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1em;
  }
  &__show-all {
    display: block;
    float: left;
    width: 100%;
    height: 3em;
    margin-top: 10%;
    font-weight: bold;
    line-height: 3em;
    @include breakpoint($portrait-up) {
      display: none;
    }
  }
} // .offer-grid
