.cart-block {
  font-family: $font--helvetica-neue-regular;
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-gray;
    padding: 10px 25px;
    &__item-count {
      float: right;
    }
  }
  &__products {
    overflow: auto;
  }
  &__title {
    float: left;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    color: $black;
    &__text {
      display: none;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__item-count {
    font-size: 12px;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: inline-block;
      margin-left: 3px;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    padding: 10px 25px;
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      display: block;
      padding: 1em 0 2em;
    }
  }
  &__footer {
    margin: 1.25em auto;
    padding: 0 25px;
    text-align: right;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 1em;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__item-count {
      float: left;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      font-size: 12px;
      border: 1px solid $black;
      border-width: 1px 0;
      font-weight: bold;
      .cart-block--added & {
        display: none;
      }
    }
    &__button {
      width: auto;
      background: $color-dark-blue;
      &--checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: inline-block;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
  }
}
