.mpp-container {
  .mpp__filters {
    display: none;
  }
  .product-brief__container {
    .product__image--large {
      min-height: 380px;
    }
  }
}

.section-offers {
  .header-large {
    &__text {
      text-align: center;
    }
  }
}

.product-full {
  .product-sku-price__value {
    font-size: 16px;
  }
  .product-size,
  .product-sku-price {
    font-weight: bold;
    display: inline-block;
    padding: 0px;
    margin: 0px;
  }
}

.site-header--sticky.active {
  .gnav-util__content--cart {
    .cart-block__products {
      max-height: 290px;
    }
  }
}

.section-trustmark {
  .text-image-block {
    &__text {
      font-weight: 400;
      ul {
        li {
          padding-bottom: 10px;
          line-height: 1.5rem;
          font-size: 12px;
          list-style-type: disc;
          margin-left: 24px;
        }
      }
      h2 {
        font-weight: bold;
        &.title5s {
          font-size: 20px;
        }
        &.title {
          font-size: 18px;
        }
      }
    }
  }
}

.spp__container {
  .product-full__details {
    .product-full__btn__container {
      padding-bottom: 20px;
      .share {
        .social-share__link {
          margin-right: 15px;
        }
        .facebook {
          margin-left: 10px;
        }
        .weixinWrapper {
          .qrcodeTable {
            border: 1px solid $color-grey;
            padding: 15px;
            margin-top: 10px;
            h3 {
              text-align: center;
            }
          }
        }
      }
    }
    .p-w-r {
      .pr-snippet-stars-reco-inline.pr-snippet-compact {
        .pr-snippet-read-and-write {
          margin-top: 0px;
        }
      }
    }
  }
}

.site-header__banner-utility-nav {
  li {
    .site-header__content {
      top: 34px;
    }
  }
  li:nth-child(4) {
    .site-header__content {
      right: 1px;
    }
  }
}

.site-header {
  &.active.sticky-nav {
    height: 150px;
    &.site-header.sticky-nav {
      .site-header__trustmark {
        left: 5%;
        top: 55%;
      }
      .site-header__menu-hamburger {
        padding-top: 35px;
      }
      .site-header__menu-logo {
        padding-top: 25px;
      }
      .site-header__menu-search {
        padding: 35px 2px 10px 0px;
      }
      &.hover {
        height: auto;
        .site-header__trustmark {
          left: 0px;
        }
      }
    }
  }
  .site-header__menu-search {
    padding: 45px 2px 10px 0px;
  }
  &.spp-sticky-nav {
    .site-header__utility {
      li {
        padding-top: 15px;
      }
      .site-header__utility-help {
        .site-header__menu-item {
          padding-top: 5px;
          padding-bottom: 16px;
        }
        .site-header__content {
          top: 59px;
        }
      }
    }
    .site-header__primary {
      .site-header__spp-rgn-name {
        display: none;
      }
    }
  }
  .site-header__utility {
    li {
      padding-top: 7px;
    }
    .site-header__utility-help {
      .site-header__menu-item {
        padding-top: 5px;
      }
      .site-header__content {
        top: 46px;
      }
    }
  }
}

.site-content {
  .product-brief {
    &__name {
      a {
        height: auto;
      }
    }
    &__description {
      font-weight: bold;
    }
    &__cta {
      padding: 11px 20px;
    }
  }
  .product-full {
    &__subline {
      font-weight: bold;
    }
  }
  .esearch-product {
    &__content {
      font-size: 14px;
      font-weight: bold;
    }
    &__subline {
      font-size: 12px;
      height: 71px;
    }
    .product-brief {
      &__cta {
        padding: 11px 20px;
        line-height: 35px;
      }
    }
  }
  .spp__container {
    .product-full {
      &__btn__container {
        a {
          height: 50px;
        }
      }
    }
  }
  .product-sku-price {
    margin-right: 15px;
    padding-right: 20px;
    display: inline-block;
  }
}

.section-customer-service-corporate-info {
  .site-content {
    .basic-responsive {
      h2,
      p {
        line-height: 1.5rem;
        font-size: 13px;
      }
    }
  }
}

.section-wechatqr {
  .content.column {
    margin-top: 10px;
  }
}

.site-footer {
  .site-footer__column {
    .site-footer__main {
      display: table-cell;
    }
  }
}

.site-header__nav {
  .site-header__menu-wrapper {
    .esearch-nav {
      #search {
        padding-right: 30px !important;
      }
    }
  }
}

.front {
  .field-collection-slides {
    .basic-carousel-formatter,
    .basic-grid-formatter {
      .content-block-large__content {
        .button {
          display: none;
        }
      }
    }
  }
}

.section-offers {
  .content-block-large__content {
    .button {
      display: none;
    }
  }
}

.section-collection-expert-skincare {
  .hero-large__inner {
    .hero-large__text {
      font-size: 2.2em;
      .expert-skincare-subtitle {
        font-size: 30px;
      }
    }
  }
}

.section-gifts-and-sets {
  .product-brief__review-snippet {
    display: inline-block;
    margin: 0 auto;
    .pr-category-snippet__total {
      padding-top: 3px;
    }
  }
}

#colorbox {
  #overlay_alipay_qr {
    iframe.alipay_qrcdoe_iframe {
      width: 296px;
      height: 280px;
      overflow-y: hidden;
      position: relative;
      left: 95px;
      & + div {
        text-align: center;
      }
    }
  }
}

.section-customer-service-privacy-policy {
  .site-content {
    section.cs-page__content {
      width: 70%;
    }
  }
}

.product-full__container,
.spp__container--section .after-product {
  margin-bottom: 90px;
}

.product-full__container {
  margin-bottom: 110px;
}

.product-full__carousel__thumbnail img {
  padding-left: 0px !important;
}

.product-full__carousel__thumbnails .thumbs-track {
  width: 200% !important;
}

.thumbs-track {
  height: 150px;
  line-height: 115px;
}

#registration_short {
  .password {
    width: 80%;
  }
}
.site-header {
  .site-email-signup {
    h3, .site-email-signup__fields, .site-email-signup__submit {
      display: none;
    }
  }
}
.site-footer {
  .site-email-signup {
    display: none;
  }
}