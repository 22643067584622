.filter-carousel {
  padding-top: 40px;
  border-top: 2px solid #b5e4e6;
  &__title {
    margin: 0;
    height: 147px;
    background: #ffffff url('/media/export/cms/solid_water_essence/filter-carousel__background--mobile.png') no-repeat
      center center;
    background-size: contain;
    font-weight: 300;
    strong {
      font-family: 'Helvetica Neue', Helvectica, sans-serif;
      font-weight: 700;
    }
    p {
      margin: 0;
      padding-top: 42px;
      line-height: 1.75em;
      @include breakpoint($medium-up) {
        padding-top: 0;
        line-height: 65px;
      }
    }
    @include breakpoint($medium-up) {
      background: #ffffff url('/media/export/cms/solid_water_essence/filter-carousel__background--desktop.png')
        no-repeat center center;
      background-size: auto;
      height: 65px;
    }
  } // &__title

  &__subtitle {
    margin-top: -20px;
    font-weight: 300;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
  }
  &__content {
    margin-top: 30px;
    text-align: center;
  }
  &__slider {
    margin: 0 25px;
    margin-top: 25px;
    @include breakpoint($medium-up) {
      margin: 0;
      margin-top: 75px;
    }
    .slick-prev:before,
    .slick-next:before {
      @include breakpoint($medium-up) {
        font-size: 40px;
      }
    }
  } // &__slider

  &__slide {
    .product-brief {
      &__info-container {
        padding: 0 20%;
        @include breakpoint($medium-up) {
          padding: 0 10%;
        }
      }
      &__name {
        font-size: 24px;
        @include breakpoint($medium-up) {
          font-size: 16px;
        }
      }
      &__product-title-spacer {
        height: 10px;
      }
    }
    .p-w-r,
    .p-w-r .pr-category-snippet {
      margin: 0;
      &__total {
        display: none;
      }
    }
  } // &__slide

  &__select {
    padding: 1em;
    background-color: #ffffff;
    border: 4px solid #b5e4e6;
    font-size: 18px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .selectBox {
    border: 4px solid #b5e4e6;
    &-arrow {
      top: 20px;
      right: 20px;
      color: #b5e4e6;
      font-size: 20px;
    }
    &-dropdown {
      min-width: 500px;
      text-align: center;
    }
    &-label {
      height: 50px;
      font-weight: bold;
      line-height: 50px;
    }
  } // .selectBox

  // The selectBox JS injects the dropdown options outside of the filter-carousel node.
  &__select-selectBox-dropdown-menu {
    max-height: 220px;
    padding: 0;
    border: 4px solid #b5e4e6;
    border-top: 2px solid #b5e4e6;
    li {
      height: 50px;
      background-color: #ffffff;
      border-top: 2px solid #b5e4e6;
      line-height: 50px;
      &:first-child {
        border-top: none;
      }
      a {
        text-align: center;
        &:hover {
          color: #b5e4e6;
        }
      }
    }
  } // &__select-selectBox-dropdown-menu
  @include breakpoint($medium-up) {
    padding-top: 50px;
    border-top: none;
  }
} // .filter-carousel
