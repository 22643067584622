.future-rescue-hero {
  position: relative;
  &__image img {
    display: block;
    width: 100%;
  }
  &__text-wrapper {
    position: absolute;
    top: 8%;
    #{$rdirection}: 10%;
    min-width: 45%;
    max-width: 370px;
    text-align: center;
    @include breakpoint($small-down) {
      #{$rdirection}: 5px;
      top: 0;
      margin-top: 25px;
    }
  }
  &__text {
    margin-bottom: 15px;
    &--1 {
      letter-spacing: 3px;
      @include breakpoint($small-down) {
        font-size: 20px;
        letter-spacing: 2px;
        margin-bottom: 12px;
      }
    }
    &--2 {
      letter-spacing: 1px;
      font-size: 15px;
      &:after {
        content: '';
        display: block;
        width: 90%;
        max-width: 267px;
        height: 1px;
        margin: auto;
        margin-top: 14px;
        border-bottom: 1px solid $color-black;
        @include breakpoint($small-down) {
          margin-top: 10px;
          margin-bottom: 10px;
          width: 65%;
        }
      }
      @include breakpoint($small-down) {
        font-size: 10px;
      }
    }
    &--3 {
      margin: auto;
      letter-spacing: 1px;
      @include breakpoint($small-down) {
        font-size: 9px;
        max-width: 60%;
      }
    }
  }
  &__cta {
    margin-top: 15px;
    @include breakpoint($small-down) {
      margin-top: 7px;
    }
  }
  &__footer {
    text-align: center;
    width: 80%;
    position: absolute;
    bottom: 50px;
    #{$ldirection}: 0;
    z-index: 2;
    font-size: 11px;
    .small-content {
      display: none;
    }
    @include breakpoint($small-down) {
      max-width: 55%;
      bottom: 30px;
      .small-content {
        display: block;
      }
      .big-content {
        display: none;
      }
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: $color-black;
      text-decoration: none;
    }
  }
}
