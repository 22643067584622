.cart-item {
  @include pie-clearfix;
  display: flex;
  text-align: left;
  color: $color-black;
  padding: 10px 0;
  border-bottom: 1px dotted $color-dark-gray;
  &:hover {
    color: $color-black;
    text-decoration: none;
    outline: none;
  }
  // Layout:
  &__img,
  &__info,
  &__cart-details {
    display: block;
  }
  &__img {
    width: 100px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__info {
    flex: 1 1 auto;
    line-height: 1.2em;
    @include breakpoint($large-up) {
      line-height: 1;
    }
    &__header {
      min-height: 50px;
    }
  }
  &__cart-details {
    width: 22.25%;
    text-align: right;
  }

  // Styles:
  &__subheading {
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__title {
  }
  &__sku-label {
  }
  &__qty {
  }
  &__price {
    letter-spacing: 0;
  }
  &__shade {
    @include pie-clearfix;
    margin: 10px 0 0;
    &__swatch {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
    }
    &__name {
      display: inline-block;
      margin: 0;
      float: left;
    }
  }
}
