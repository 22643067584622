#loyalty__panel__how-to-earn {
  @include loyalty-panel;
  &.is-member {
    display: none;
    #loyalty__page__about & {
      display: block;
    }
  }
  @include breakpoint($medium-up) {
    float: left;
    width: 50%;
  }
  .loyalty__panel__how-to-earn__title {
    @include loyalty-panel-title;
  }
}
