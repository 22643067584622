.future-rescue {
  // All sections
  &__sections {
  }

  // Specific Sections
  &__section {
    .copy-quickshop,
    .technology-block,
    .benefits,
    .basic-noderef .basic-carousel-formatter,
    .content-block-large--video {
      padding-bottom: 20px;
      @include breakpoint($portrait-up) {
        padding-bottom: 50px;
      }
    }
    .basic-carousel-formatter .carousel-controls .slick-arrow {
      color: $color-white;
    }
    .copy-quickshop {
      &__header,
      &__header + div {
        font-family: $font--sans;
      }
    }
  }
}
