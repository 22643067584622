.sitewide-ie-upgrade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10011;
  width: auto;
  @include breakpoint($medium-up) {
    margin: 0 10%;
    width: 80%;
  }
  @include breakpoint($portrait-up) {
    top: 140px;
    margin: 0 20%;
    width: 60%;
  }
  @include breakpoint($large-up) {
    margin: 0 25%;
    width: 50%;
  }
  &__container {
    position: relative;
    padding: 35px 30px 30px;
    @include breakpoint($large-up) {
      padding: 50px 40px 40px;
    }
    button {
      background-color: transparent;
      color: $color-black;
      position: absolute;
      bottom: auto;
      top: 8px;
      right: 8px;
      display: block;
      font-size: 24px;
      width: 30px;
    }
    h3 {
      margin-bottom: 12px;
      background: none;
      font-size: 20px;
      text-indent: 0;
      width: auto;
      height: auto;
      line-height: 24px;
      @include breakpoint($large-up) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    a.button {
      margin-top: 12px;
    }
  }
  &__background {
    background: $color-black;
    opacity: 0.9;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10010;
  }
} // .sitewide-ie-upgrade

.ie7 .sitewide-ie-upgrade,
.ie7 .sitewide-ie-upgrade__background {
  position: absolute;
}
