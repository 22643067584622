#paid_sampling {
  .wrap {
    width: 100%;
    position: relative;
    img {
      max-width: 100%;
      width: auto;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .sampling_submit_btn {
      width: 100%;
      padding-top: 140px;
    }
  }
  #cart {
    ul {
      &.sampleskus-list {
        width: 1024px;
        background: url(/media/images/paid_sampling/pc/phase_02.jpg) no-repeat;
        height: 544px;
        background-size: 100%;
        margin: 0 auto;
        padding-top: 140px;
      }
      li {
        &.sampleskus_details {
          width: 33%;
          height: auto;
          float: left;
        }
        .sku_size {
          text-align: center;
          font-size: 14px;
        }
        .sku_subline {
          margin: 25px 0 7px 0;
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .forcheck {
      text-align: center;
      position: relative;
      input.sample-input {
        left: 0;
        position: absolute;
        display: none;
        top: 1px;
      }
      .sample-input[disabled] {
        cursor: not-allowed;
      }
      .sample-input + label {
        position: relative;
        display: block;
        padding-left: 30px;
        cursor: pointer;
      }
      .sample-input + label:hover:before {
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: hover-color;
      }
      .sample-input + label:before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 17px;
        height: 17px;
        content: '';
        border: 1px solid #c0c0c0;
      }
      .sample-input + label:after {
        position: absolute;
        display: none;
        content: '';
      }
      .sample-input[disabled] + label {
        cursor: not-allowed;
        color: #e4e4e4;
      }
      .sample-input[disabled] + label:hover,
      .sample-input[disabled] + label:before,
      .sample-input[disabled] + label:after {
        cursor: not-allowed;
      }
      .sample-input[disabled] + label:hover:before {
        border: 1px solid #e4e4e4;
        animation-name: none;
      }
      .sample-input[disabled] + label:before {
        border-color: #e4e4e4;
      }
      .sample-input:checked + label:before {
        animation-name: none;
      }
      .sample-input:checked + label:after {
        display: block;
        margin-left: 40%;
      }
      .sample-input + label:before {
        border-radius: 3px;
        margin-left: 40%;
      }
      .sample-input + label:after {
        top: 0px;
        left: 5px;
        box-sizing: border-box;
        width: 8px;
        height: 14px;
        transform: rotate(45deg);
        border-width: 3px;
        border-style: solid;
        border-color: #3e97eb;
        border-top: 0;
        border-left: 0;
      }
      .sample-input:checked + label:before {
        border: 1px solid #3e97eb;
        background: #fff;
        margin-left: 40%;
      }
      .sample-input:checked[disabled] + label:before {
        border: #c9e2f9;
        background: #c9e2f9;
      }
      .pls_select {
        font-size: 14px;
        margin: 0 0 0 5px;
        display: inline-block;
      }
    }
  }
  p.campaignsample_btn {
    width: 210px;
    height: 58px;
    position: absolute;
    bottom: 0;
    margin: 0;
    right: 43%;
    cursor: pointer;
  }
  .campover .over_jump {
    height: 60px;
    width: 215px;
    position: absolute;
    top: 81%;
    right: 34%;
  }
  .forpop {
    display: none;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: $color-grey;
    position: absolute;
    top: 0;
    opacity: 0.3;
  }
  .ComFloatCenterBox {
    display: block;
    z-index: 1000;
    left: 50%;
    position: fixed;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 50%;
    img {
      width: 100%;
    }
  }
  .pc_pormatText {
    top: 22%;
    width: 69%;
    left: 15%;
    position: absolute;
  }
  .pc_shoppingbtn {
    top: 70%;
    width: 21.26%;
    left: 38%;
    position: absolute;
  }
  .pc_closebtn {
    cursor: pointer;
    position: absolute;
    right: 3.3%;
    top: 3.3%;
    width: 4.5%;
  }
}

@media screen and (max-width: 2000px) {
  #paid_sampling {
    .confirm_jump .confirm_jump_a {
      height: 90px;
      width: 250px;
      position: absolute;
      top: 70%;
      right: 32%;
    }
    .campain_orderid {
      height: 40px;
      position: absolute;
      top: 32%;
      right: 33.5%;
      font-size: 23px;
    }
  }
}

@media screen and (max-width: 1400px) {
  #paid_sampling {
    .confirm_jump .confirm_jump_a {
      height: 60px;
      width: 215px;
      position: absolute;
      top: 70%;
    }
    .campain_orderid {
      height: 32px;
      position: absolute;
      top: 40%;
      right: 22%;
      font-size: 23px;
    }
  }
}
