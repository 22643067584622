///
/// @file components/prouducts/_spp.scss
///
/// \brief Styles for multi-product pages
///
///

.spp__container {
  margin: 0 10px 0 10px;
}

// On SPP hide the sticky menu
.page-product .site-header--sticky.site-header {
  .site-header__primary {
    float: none;
    text-align: center;
    padding: 2px 0;
    .site-header__menu-wrapper {
      display: none;
    }
  }
  .site-header__menu-logo {
    margin-#{$rdirection}: 120px;
  }
  .site-header__spp {
    font-weight: bold;
    display: table;
    color: $color-white;
    height: $header-height;
    &-cell {
      display: table-cell;
      padding: 0 10px;
      vertical-align: middle;
    }
    &-cell--image {
      padding: 0;
      img {
        display: block;
        width: 23px;
        height: 49px;
      }
    }
    &-cell--name {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      text-align: left;
      margin: 12px 0 0 10px;
      width: 140px;
      line-height: 1.2em;
    }
    &-cell--size {
      margin-left: 40px;
    }
    &-cell--price {
      margin-left: 20px;
    }
    &-cell--addtobag {
      margin-left: 20px;
    }
    &-cell--addtobag .button {
      background: $color-white;
      color: $color-black;
      padding: 10px 15px;
    }
  }
}

.spp-cross-sells {
  padding-top: 25px;
}

//social sharing icons
.product-social {
  // display: none;
  display: block;
  @include breakpoint($medium-up) {
    display: inline-block;
  }
  @include breakpoint($large-up) {
    position: relative;
    top: 18px;
    margin-#{$ldirection}: 20px;
  }
  &__icon {
    display: inline-block;
    margin: 0 15px 0 0;
    height: 25px;
    color: $black;
    font-size: 18px;
    cursor: pointer;
    &--facebook {
      width: 10px;
      @include icon('facebook', after);
    }
    &--twitter {
      @include icon('twitter', after) width: 22px;
    }
    &--pinterest {
      @include icon('pinterest', after) width: 21px;
    }
  }
}

.replenishment__description--link {
  cursor: pointer;
  color: $color-link;
}

.replenishment-overlay {
  padding: 20px;
  width: 100%;
  @include breakpoint($medium-up) {
    width: 600px;
  }
  &__title {
    color: #025084;
    text-transform: uppercase;
    margin-bottom: 12px;
    font-weight: bold;
  }
}

.spp__container {
  &-contents {
    margin-bottom: 7%;
    border-bottom: $black 1px solid;
    font-size: 16px;
    &--details,
    &--reviews {
      @include swap_direction(padding, 10px 30px 10px 10px);
      @include swap_direction(margin, 0 auto);
      display: inline-block;
      width: 100%;
      text-align: $ldirection;
      text-transform: uppercase;
      font-size: $body-font-size;
    }
    &--reviews {
      display: none;
    }
  }
}

.show-details {
  .spp__container {
    &--section {
      display: block;
    }
  }
  .spp_customer_reviews {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
}

.show-reviews {
  .spp_customer_reviews {
    display: block;
  }
  .spp__container {
    &--section {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    &-contents {
      &--reviews {
        font-family: $font--helvetica-neue-regular;
      }
    }
  }
}
