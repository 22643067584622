.image-text {
  position: relative;
  @include swap_direction(margin, 15% 0);
  @include breakpoint($portrait-up) {
    @include swap_direction(margin, 15% 10%);
  }
  &__image {
    position: relative;
    img {
      width: 100%;
    }
  }
  &__subheader {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 22%;
    font-family: $font--helvetica-neue-light;
    @include breakpoint($portrait-up) {
      position: relative;
      #{$ldirection}: 0;
    }
  }
}
