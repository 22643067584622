///
/// @file components/prouducts/_mpp.scss
///
/// \brief Styles for multi-product pages
///
///

.mpp__container {
  margin: 0 10px 0 10px;
}

.site-content {
  .mpp-container {
    position: relative;
    z-index: 3;
    .product-grid-wrapper {
      .product-grid__item {
        border: 0;
        .product-brief__info-container {
          .product-brief__product-title {
            .product-brief__name {
              font-size: 14px;
            }
          }
          .product__inventory-status-container {
            margin-top: 10px;
          }
        }
      }
      .product-grid__mpp-tile {
        position: relative;
        display: flex;
        align-items: flex-end;
      }
    }
    .mpp__filters {
      @include breakpoint($landscape-up) {
        margin-top: 20px;
      }
    }
  }
}
