.sign-in-page,
.sign-in-panel {
  .sms-otp {
    &__section {
      max-width: 400px;
      display: inline-block;
      text-align: left;
      input {
        height: 47px;
      }
    }
    &__fieldset {
      max-width: initial;
      padding: 0px 5px;
      text-align: center;
    }
    &__container {
      padding-top: 10px;
    }
    &__item {
      margin-bottom: 10px;
      input[type='checkbox'] {
        vertical-align: top;
      }
      .btn--full {
        min-width: 360px;
      }
    }
  }
  .left {
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .return-user {
    &__section {
      max-width: 400px;
      margin: auto;
      padding: 10px;
      .email-address,
      .forgot,
      .submit {
        padding-right: 0px;
      }
    }
    &__fieldset {
      max-width: initial;
      padding: 0px 5px;
      margin: 0px;
    }
    #signin {
      padding: 0px;
      input[type='TEXT'],
      input[type='PASSWORD'],
      input[type='SUBMIT'] {
        width: 100%;
        padding: 0px;
      }
      .submit {
        width: 100%;
      }
      .password-container,
      .password {
        padding: 0px;
      }
    }
    .form-item {
      &.email-address,
      &.password {
        label {
          display: block;
          text-align: left;
        }
        .eye-icon {
          margin-top: calc(1em + 1px);
          @include breakpoint($landscape-up) {
            margin-top: auto;
          }
        }
      }
    }
  }
  .form-item {
    input,
    input::placeholder {
      font-size: 12px;
      padding-left: 5px;
    }
    .btn {
      &--full {
        width: 100%;
      }
    }
    &--full {
      input {
        width: 100%;
      }
    }
  }
  .error_messages {
    text-align: center;
    li {
      max-width: 390px;
      text-align: left;
      &:last-child {
        margin-bottom: 1em;
      }
    }
  }
  h2 {
    width: 100%;
    margin: 1em auto;
    font: 1.5em bold;
  }
}
#signin_new_mobile {
  .form-item-section {
    .form-item {
      width: 100%;
      padding: 0px;
      input[name='MOBILE_NUMBER'] {
        display: inline;
        width: 100%;
        padding: 0px;
        width: calc(100% - 150px);
        @include breakpoint($medium-up) {
          width: calc(100% - 160px);
        }
      }
      input[type='submit'] {
        float: right;
        padding: 0px;
        width: calc(100% - 260px);
        @include breakpoint($medium-up) {
          width: calc(100% - 260px);
          margin: 0px;
        }
        &.loading {
          background: url(/sites/esteelauder/themes/estee_base/img/ajax-loading.gif) no-repeat center center;
          background-color: transparent;
          border: none;
          cursor: not-allowed;
          &:hover {
            background: no-repeat center center;
          }
        }
      }
    }
  }
}

#sms_pincode {
  .form-item-section {
    .form-item {
      width: 100%;
      padding: 0px;
      input[type='password'],
      input[type='submit'] {
        width: 100%;
        padding: 0px;
      }
      input[type='checkbox'] {
        width: 15px;
        height: 15px;
      }
      label[for='form--sms_pincode--field--ACCEPTED_PRIVACY_POLICY'],
      label[for='form--sms_pincode--field--PC_EMAIL_PROMOTIONS'] {
        display: inline-block;
        width: 90%;
        margin: 0px;
        .label-content {
          margin-left: 1em;
          font-weight: 400;
        }
      }
    }
  }
}

.checkout#index {
  #sign-in-panel {
    margin-top: 20px;
    #signin {
      .sign-in-page {
        @include breakpoint($medium-up) {
          padding: 0px;
        }
      }
      .form-item {
        margin-bottom: 0px;
      }
      .sms-otp {
        .left {
          width: 100%;
          border: none;
        }
        .sign {
          input[type='submit'] {
            width: calc(100% - 200px);
            @include breakpoint($medium-up) {
              width: calc(100% - 240px);
            }
          }
        }
        &__item {
          .btn--full {
            min-width: 340px;
          }
        }
      }
      input[type='submit'] {
        margin: 0px;
      }
    }
  }
  #signin {
    .return-user {
      &__fieldset {
        width: 100%;
        border: none;
        .form-item {
          .password {
            padding: 0px;
          }
          .form-submit {
            margin: 0px;
          }
          width: 100%;
          padding: 0px;
        }
      }
      &__section {
        margin: initial;
      }
    }
  }
}

#registration {
  #content {
    #registration-wrapper {
      input[type='email'],
      input[type='text'] {
        &.grayout_dummy_email {
          color: $white;
        }
      }
    }
  }
}

.checkout {
  .sign-in-panel {
    .return-user {
      margin: 0;
    }
    .panel-header {
      .signin-panel {
        &__title,
        &__account-title {
          text-align: left;
          margin: 0;
        }
      }
    }
  }
  &__content {
    .invoice-change-email {
      width: 340px;
      @include breakpoint($medium-up) {
        width: 100%;
      }
      .loading {
        margin: 0;
      }
    }
  }
}

.invoice-change-email {
  width: 49%;
  input[name='PC_EMAIL_ADDRESS'] {
    width: 98%;
    &.error {
      width: 98%;
    }
  }
  input[type='submit'] {
    float: right;
    margin-right: 2%;
    &.loading {
      background: url(/sites/esteelauder/themes/estee_base/img/ajax-loading.gif) no-repeat center center;
      background-color: transparent;
      border: none;
      cursor: not-allowed;
      &:hover {
        background: no-repeat center center;
      }
    }
  }
}

h3 {
  &.invoice-options {
    &__title {
      width: 49%;
    }
  }
}

.gnav-util--account {
  .form-item {
    margin-top: 1em;
    &.privacy-policy {
      text-align: left;
      line-height: 1.45em;
      input[type='checkbox'] {
        vertical-align: top;
      }
    }
  }
  .signin-switch {
    margin-top: 3em;
    padding-top: 2em;
    border-top: 1px dashed $color-light-gray;
  }
  input[type='email'],
  input[type='text'],
  input[type='password'] {
    &.error {
      width: 100%;
    }
  }
  form[name='signin_new_mobile'] {
    input[type='submit'] {
      width: 140px;
      float: right;
      margin-left: 5px;
    }
  }
}

body#signin {
  #content {
    .sign-in-page {
      input[type='password'],
      input[name='ACCOUNT'] {
        width: 99%;
      }
      @include breakpoint($medium-up) {
        padding-left: 5px;
        padding-right: 10px;
      }
    }
  }
}

body#index {
  #main {
    #content {
      #sign-in-panel {
        .sign-in-page {
          padding-top: 0px;
          .return-user,
          .sms-otp {
            input,
            input::placeholder {
              font-size: 12px;
              font-weight: 400;
              padding-left: 5px;
            }
            .sms-otp__fieldset {
              border: none;
            }
            input[type='password'],
            input[type='text'] {
              height: 47px;
            }
            input[type='submit'] {
              background: none;
              background-color: $color-black;
              padding: 0px;
            }
          }
          .return-user {
            .return-user {
              &__fieldset {
                width: 100%;
              }
            }
          }
        }
      }
      #shipping-page-info-panel {
        input,
        input::placeholder {
          font-size: 12px;
          font-weight: 400;
          padding-left: 5px;
        }
      }
    }
  }
}

.signin-block {
  padding: 10px;
  .login,
  .sms-otp {
    input[type='text'],
    input[type='submit'],
    input[type='password'] {
      width: 100%;
      margin-bottom: 5px;
    }
    input[type='submit'] {
      &.loading {
        background: url(/sites/esteelauder/themes/estee_base/img/ajax-loading.gif) no-repeat center center;
        background-color: transparent;
        border: none;
        cursor: not-allowed;
        &:hover {
          background: no-repeat center center;
        }
      }
    }
  }
  .privacy-policy {
    input[type='checkbox'] {
      vertical-align: top;
      margin-top: 3px;
    }
    label {
      width: 95%;
      line-height: 1.5em;
      .label-content {
        margin: 0px;
      }
    }
  }
}

.return-user .form-item #lpw-text {
  padding: 0px;
  border: none;
  margin: 0px;
}
