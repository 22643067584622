///
/// customer service internal page
///
.cs-page__title {
  text-align: center;
  border-bottom: $color-black solid 1px;
}

.cs-page__footer {
  position: relative;
}

///
/// CS expanding menu
///
.cs-menu {
}

.cs-menu__parent {
  > h3 {
    &:after {
      font-size: 12px;
    }
  }
}

.cs-menu__children {
  padding: 0 0 1em 1em;
  line-height: 2em;
}
// static link inside expando-menu
.collapsible-menu__static {
}
