.video-text {
  text-align: center;
  @include breakpoint($portrait-up) {
    @include swap_direction(margin, 30px 0);
  }
  p {
    @include swap_direction(margin, auto);
    line-height: 1.25em;
  }
  &__header {
    font-family: $font--helvetica-neue-regular;
    font-size: 36px;
    width: 50%;
    @include swap_direction(margin, auto);
    @include breakpoint($landscape-up) {
      font-size: 49px;
      width: 100%;
    }
  }
  &__subheader {
    font-family: $font--helvetica-neue-light;
    font-size: 12px;
    @include breakpoint($landscape-up) {
      font-size: 20px;
    }
  }
  &__large-video {
    .content-block-large {
      margin-top: 5%;
    }
  }
  &__copy-one {
    font-family: $font--helvetica-neue-light;
    font-size: 12px;
    width: 65%;
    @include swap_direction(margin, 5% auto);
    @include breakpoint($portrait-up) {
      font-size: 14px;
      width: 100%;
      @include swap_direction(margin, 2% auto);
    }
  }
  &__copy-two {
    font-family: $font--helvetica-neue-light;
    font-size: 12px;
    width: 70%;
    @include swap_direction(margin, 10% auto 5%);
    @include breakpoint($portrait-up) {
      font-size: 18px;
      width: 80%;
      @include swap_direction(margin, 5% auto 3%);
    }
  }
  &__copy-three {
    font-family: $font--helvetica-neue-regular;
    font-size: 12px;
    width: 60%;
    @include swap_direction(margin, 0 auto 10%);
    @include breakpoint($portrait-up) {
      font-size: 18px;
      width: 70%;
      @include swap_direction(margin, 0 auto 3%);
    }
  }
}
