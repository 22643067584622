.product-quickshop-wrapper {
  clear: both;
  position: relative;
  .quickshop-style-overlay & {
    display: none;
  }
}

.product-quickshop {
  margin: 0 auto;
  width: 100%;
  &__image {
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      margin-bottom: 0;
      clear: none;
    }
  }
  &__content {
    padding: 30px 60px;
    float: $ldirection;
    clear: none;
  }
  &__product {
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 220px;
    }
  }
  &__section {
    &--title {
      font-weight: bold;
      text-transform: uppercase;
    }
    &--text {
      margin-bottom: 0.8em;
    }
  }
  .product {
    &-sku-price {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      margin-right: 1em;
      padding-right: 1em;
      line-height: 1em;
    }
    &-size {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0.5em;
      display: inline-block;
    }
    &-replenishment-select {
      margin-top: 1em;
    }
    &-replenishment-select__select {
      width: 180px;
    }
  }
  .replenishment__description--link {
    display: none;
  }
  &__btn__container {
    @include clearfix;
    height: 60px;
  }
  .product__inventory-status-container {
    clear: both;
    @include breakpoint($medium-up) {
      margin: 10px;
      clear: none;
    }
  }
  .product__button--add-to-bag,
  .product__button--loading {
    @include swap_direction(margin, 1em 10px 0 0);
    display: block;
    float: #{$ldirection};
    font-size: 16px;
    .product-sku-price {
      margin-right: 0;
      margin-left: 1em;
      padding-right: 0;
      padding-left: 1em;
      border-left: 2px solid $color-white;
    }
  }
  &__close {
    .qs-overlay-cbox & {
      display: none;
    }
  }
}
