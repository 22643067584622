.social-login {
  &__container {
    text-align: center;
    padding: 20px 0;
  }
  &__divider {
    @include h5;
    @include swap_direction(margin, 15px auto 0);
    @include swap_direction(padding, 15px 0 21px 0);
    width: 100%;
  }
  &__terms {
    text-align: left;
    margin-top: 15px;
    padding: 0 33px;
    p {
      margin: 0;
    }
  }
  &__email-opt-in {
    text-align: left;
    padding-top: 20px;
    padding-#{$ldirection}: 0;
    input[type='checkbox'] ~ label {
      display: inline;
    }
    @include breakpoint($portrait-up) {
      padding-top: 25px;
      padding-#{$ldirection}: 34px;
    }
  }
  &__title,
  &__info {
    display: none;
  }
}
/* FB GNAV styling */
.social-login.gnav {
  .social-login {
    &__terms {
      padding: 0 20px;
    }
    &__email-opt-in {
      padding: 25px 20px 0;
      label {
        display: inline;
        padding-#{$ldirection}: 5px;
      }
      input[type='checkbox'] {
        vertical-align: text-bottom;
      }
    }
    &__legal {
      padding: 15px 20px;
      text-align: #{$ldirection};
      line-height: 1.85;
      p {
        margin: 0;
        line-height: 1.85;
      }
    }
  }
  &.social-login__btn-bottom {
    width: 350px;
    .social-login__container {
      display: flex;
      flex-direction: column;
      .fb-overlay-container {
        order: 1;
      }
    }
    .social-login__email-opt-in {
      padding: 0 20px 20px;
    }
    .social-login__legal {
      padding: 0 20px 15px;
    }
  }
}
/* FB Disconnect button styling */
#facebook_disconnect {
  display: inline;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
    background: $color-dark-blue;
  }
}
/* FB Profile page styling */
.content-block {
  border-top: 1px solid $color-grey-border;
  border-bottom: 1px solid $color-grey-border;
  .social-info {
    .social-info {
      &__header {
        padding-#{$ldirection}: 0;
        font-weight: bold;
        text-transform: uppercase;
      }
      &__content {
        padding-#{$ldirection}: 13px;
        @include breakpoint($portrait-up) {
          padding-#{$ldirection}: 25px;
        }
      }
      &__connect {
        @include swap_direction(margin, 30px 0 30px 0);
        margin: 30px 0 30px 0;
        form {
          padding-#{$ldirection}: 24px;
        }
      }
    }
  }
}

#registration {
  .account_page {
    #content {
      h3 {
        padding: 13px 13px;
        @include breakpoint($portrait-up) {
          padding-#{$ldirection}: 23px;
        }
      }
    }
  }
}
/* FB Account profile page */
.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
  }
}
/* FB Order confirmation page */
.confirmation-panel {
  .social-login {
    padding-#{$ldirection}: 0;
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
    &__email-opt-in {
      padding-#{$ldirection}: 0;
    }
  }
}

div#registration-wrapper {
  div.content-block {
    .social-info {
      margin-top: 0;
    }
  }
}

#wrapper {
  h2.social-login__divider {
    @include swap_direction(padding, 15px 0 21px 0);
    border-style: none;
    padding: 15px 0 21px 0;
  }
}

.checkout {
  #signin-block {
    h2.social-login__divider {
      border-style: none;
    }
    #new-account {
      height: 210px;
      @include breakpoint($portrait-up) {
        height: 450px;
      }
      .social-login {
        display: none;
        @include breakpoint($portrait-up) {
          display: block;
        }
      }
    }
    #return-user {
      height: 550px;
      @include breakpoint($portrait-up) {
        height: 530px;
      }
      .social-login {
        .social-login__container {
          padding: 0;
          @include breakpoint($portrait-up) {
            padding: 20 0;
          }
          .social-login__divider {
            margin: 0;
            @include breakpoint($portrait-up) {
              @include swap_direction(margin, 15px auto 0);
            }
          }
        }
      }
    }
  }
}

#signin {
  .account_page {
    #wrapper {
      #content {
        h2.social-login__divider {
          @include swap_direction(padding, 15px 0 21px 0);
          border-style: none;
          padding: 15px 0 21px 0;
        }
      }
    }
  }
  .checkout {
    .address_spacing_head {
      .social-login {
        &__terms {
          padding: 0;
          p {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.address_spacing_head {
  .social-login {
    &__terms {
      padding: 0;
    }
  }
}
