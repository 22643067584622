.infographics-section {
  clear: both;
  @include breakpoint($portrait-up) {
    width: 80%;
    @include swap_direction(margin, 3% auto);
  }
  p {
    @include swap_direction(margin, auto);
  }
  &__image {
    width: 50%;
    float: #{$ldirection};
    img {
      width: 100%;
    }
    @include breakpoint($portrait-up) {
      margin-bottom: 9%;
    }
  }
  &__content {
    text-align: center;
    @include breakpoint($portrait-up) {
      float: #{$rdirection};
      width: 50%;
    }
    .statistics-section {
      @include swap_direction(margin, 12% 0);
      @include breakpoint($portrait-up) {
        @include swap_direction(margin, 5% 0);
      }
      &__header {
        font-family: $primary-font;
        font-size: 66px;
        @include breakpoint($landscape-up) {
          font-size: 90px;
        }
      }
      &__subheader {
        font-family: $font--helvetica-neue-light;
        font-size: 12px;
        @include swap_direction(margin, auto);
        width: 45%;
        @include breakpoint($portrait-up) {
          font-size: 18px;
          width: 80%;
        }
      }
      &__subheader-text {
        @include swap_direction(margin, 4% auto);
        font-family: $font--helvetica-neue-light;
        font-size: 12px;
        width: 40%;
        @include breakpoint($portrait-up) {
          width: 100%;
        }
      }
    }
  }
}
