.content-block {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      #{$ldirection}: 0;
    }
  }
  &__line {
    display: block;
    p {
      margin: 0;
      padding: 0;
    }
  }
  &__links {
    display: block;
  }
  &__link-wrapper {
    display: contents;
    &--column {
      display: flex;
      flex-direction: column;
      align-items: inherit;
    }
    &--row {
      display: flex;
      flex-direction: row;
      align-items: inherit;
    }
  }
  &--positioned {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
  }
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

///////////////////////////////
/// CONTENT BLOCK LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////

.content-block-large {
  height: auto;
  position: relative;
  width: 100%;
  @include breakpoint($portrait-down) {
    background: $white;
  }
  //   &.content-block-large--color-light {
  //     background-color: $color-black;
  //   }
  &__inner {
    position: relative;
    .content-block-large__content--text-bg {
      @include breakpoint($med-small-down) {
        background: transparent !important; // Override inline bg color
      }
      @include breakpoint($portrait-up) {
        padding: 20px;
      }
    }
    //
    //  Custom Border Options
    //
    &--row-1-border {
      .content-block-large__text--1 {
        border-bottom: 1px solid $black;
        margin: 20px auto;
        padding-bottom: 20px;
      }
    }
    &--row-2-border {
      .content-block-large__text--2 {
        border-bottom: 1px solid $black;
        margin: 20px auto;
        padding-bottom: 20px;
      }
    }
    &--row-3-border {
      .content-block-large__text--3 {
        border-bottom: 1px solid $black;
        margin: 20px auto;
        padding-bottom: 20px;
      }
    }
    &--bottom-border {
      .content-block-large__content {
        @include breakpoint($portrait-up) {
          border-bottom: $border-thick-width solid $black;
          padding-bottom: 25px;
        }
      }
    }
    @include breakpoint($portrait-up) {
      position: absolute;
      z-index: 3;
      top: 1em;
      bottom: auto;
      overflow: hidden;
      // content widths
      &.content-block-large__inner-width--20 {
        width: 20%;
      }
      &.content-block-large__inner-width--30 {
        width: 30%;
      }
      &.content-block-large__inner-width--40 {
        width: 40%;
      }
      &.content-block-large__inner-width--50 {
        width: 50%;
      }
      &.content-block-large__inner-width--60 {
        width: 60%;
      }
      &.content-block-large__inner-width--70 {
        width: 70%;
      }
      &.content-block-large__inner-width--80 {
        width: 80%;
      }
      &.content-block-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--col {
        @include transform(translateX(-50%));
        left: 50%;
        .content-block-large__logo {
          margin-right: 20px;
        }
        .content-block-large__logo,
        .content-block-large__text-wrapper {
          display: inline-block;
          vertical-align: middle;
        }
      }
      &-hpos--center {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &-hpos--left {
        left: 40px;
        // margin details
        &.content-block-large__inner-margin--50 {
          left: 50%;
        }
        &.content-block-large__inner-margin--40 {
          left: 40%;
        }
        &.content-block-large__inner-margin--30 {
          left: 30%;
        }
        &.content-block-large__inner-margin--20 {
          left: 20%;
        }
        &.content-block-large__inner-margin--10 {
          left: 10%;
        }
      }
      &-hpos--right {
        right: 40px;
        // margin details
        &.content-block-large__inner-margin--50 {
          right: 50%;
        }
        &.content-block-large__inner-margin--40 {
          right: 40%;
        }
        &.content-block-large__inner-margin--30 {
          right: 30%;
        }
        &.content-block-large__inner-margin--20 {
          right: 20%;
        }
        &.content-block-large__inner-margin--10 {
          right: 10%;
        }
      }
      &-text--center {
        text-align: center;
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .content-block-large__content {
          @include vertical-horizontal-center;
          position: absolute;
        }

        // Fix a bug with right and left aligned blocks collapsing
        &.content-block-large__inner-hpos--right,
        &.content-block-large__inner-hpos--left {
          .content-block-large__content {
            width: 100%;
          }
        }
      }
      &-vpos--top {
        top: 20px;
      }
      &-vpos--bottom {
        position: absolute;
        bottom: 20px;
        top: auto;
      }
    }
    &--copy-above-image {
      top: 0;
      .content-block-large__text-wrapper {
        @include breakpoint($portrait-up) {
          margin-top: 0;
        }
        margin-top: -176%;
      }
    }
    &--copy-over {
      &-top-image,
      &-bottom-image {
        @include breakpoint($portrait-up) {
          #{$ldirection}: auto;
          #{$rdirection}: 40px;
        }
        position: absolute;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
      }
      &-top-image {
        top: 0;
      }
      &-center-image {
        top: 50%;
        transform: translate(0, -50%);
        position: absolute;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
      }
      &-bottom-image {
        bottom: 0;
      }
    }
  }
  &__content {
    padding: 15px 10px;
    @include breakpoint($portrait-up) {
      padding: 0;
      // when both top and bottom borders are set, pad the inner content more
      .content-block-large__inner--bottom-border.content-block-large__inner--top-border & {
        padding-left: 10%;
        padding-right: 10%;
      }
    }
  }
  &__text {
    p {
      line-height: inherit;
      margin: 0;
    }
    &--2,
    &--3,
    &--4,
    &--5 {
      margin-top: 7px;
    }
    .header-text--one {
      font-size: 14px;
      line-height: 1.2;
      @include breakpoint($portrait-up) {
        font-size: 24px;
      }
    }
  }
  &__image {
    &.content-block-large__image--copy-above-image {
      @include breakpoint($portrait-up) {
        margin-top: 0;
      }
      margin-top: 200px;
    }
  }
  &__image,
  &__image img {
    width: 100%;
  }
  &__image--foreground {
    text-align: center;
  }
  &__cta-wrap {
    margin-top: 15px;
    @include breakpoint($portrait-up) {
      margin-top: 30px;
    }
  }
  &__cta {
    display: inline-block;
    // CTA next to CTA
    & + .content-block-large__cta {
      margin-left: 15px;
      @include breakpoint($portrait-up) {
        margin-left: 30px;
      }
    }
    .button {
      border: 1px solid $color-black;
      &--dark {
        background: $color-black;
        color: $color-white;
      }
      &--light {
        background: $color-white;
        color: $color-black;
      }
      &--transparent {
        &:hover {
          color: inherit;
        }
      }
    }
  }
  // If we have no bg color, on mobile the light color
  // should be dark since it would be white on white otherwise
  &--no-bg-color {
    .font-color--light {
      @include breakpoint($portrait-down) {
        color: $black;
      }
    }
  }
  // If we have no image but are using vpos middle, tweak the design
  &--no-image {
    padding: 0;
    @include breakpoint($portrait-up) {
      padding: 50px 0;
    }
    .content-block-large__inner-vpos--middle {
      @include breakpoint($portrait-up) {
        position: relative;
      }
      .content-block-large__content {
        @include transform(none);
        left: auto;
      }
    }
  }
  .content-block-extole & {
    &__inner {
      position: static;
    }
    &__image,
    &__text-wrapper {
      cursor: pointer;
    }
    &__content {
      top: 50%;
      #{$ldirection}: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 100%;
      @include breakpoint($portrait-up) {
        width: 70%;
      }
      .header--subhead a {
        font-size: 14px;
        @include breakpoint($medium-up) {
          font-size: 20px;
        }
      }
    }
    &__text {
      span {
        display: inline-block;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      img {
        vertical-align: baseline;
      }
    }
  }
}
