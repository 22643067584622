///
/// @file template_api/endeca/_esearch_forms.scss
///
/// \brief Search Block for site header area; form on search page
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

// @todo check main variables, see if we need them here

//header search section
.site-header__search {
  position: fixed;
  width: 100%;
  top: 0;
  #{$ldirection}: 0;
  height: 100%;
  background-color: $color-white;
  z-index: 999;
  @include breakpoint($large-up) {
    position: absolute;
    display: block;
    top: 180px;
  }
}

.esearch-nav {
  position: fixed;
  z-index: 9999;
  display: block;
  width: 100%;
  height: 100%;
  background: $white;
  @include breakpoint($large-up) {
    position: unset;
    background: none;
  }
  &__form-fields {
    @include pie-clearfix;
    width: 100%;
    border-bottom: 2px solid $black;
    input[type='text'] {
      display: block;
      width: 100%;
      background: $white;
      border: none;
      float: $ldirection;
      text-transform: uppercase;
      outline: 0;
      font-family: $font--helvetica-neue;
      font-size: 24px;
      padding-#{$ldirection}: 45px;
      height: 50px;
      line-height: 25px;
      @include breakpoint($large-up) {
        background: $color-lighter-gray-2;
        height: 40px;
        padding-#{$ldirection}: 30px;
        font-size: 30px;
      }
      &::placeholder {
        font-size: 24px;
        color: $black;
        text-transform: uppercase;
      }
    }
    .esearch-nav__form-submit {
      @include icon('search');
      @include swap_direction(margin, 5px 0 0 -25px);
      cursor: pointer;
      display: none;
      font-size: 15px;
      @include breakpoint($large-up) {
        display: inline-block;
        margin: 0;
        position: absolute;
        $ldirection: 4px;
        top: 7px;
      }
    }
    .esearch-nav__form-close {
      @include icon('close_v2');
      @include swap_direction(margin, 7px 0 0 -35px);
      display: inline-block;
      font-size: 20px;
      @include breakpoint($large-up) {
        font-size: 15px;
        position: absolute;
        #{$rdirection}: $container-pad-landscape;
        margin: 0;
        top: 4px;
        cursor: pointer;
      }
      &:before {
        font-weight: bold;
        color: $black;
      }
      svg {
        height: 25px;
        width: 25px;
        padding-bottom: 2px;
        margin: 5px 0 0 -10px;
        @include breakpoint($large-up) {
          margin: 0;
        }
      }
    }
  }
  &__popular {
    .typeahead-wrapper {
      text-align: $ldirection;
      .esearch-nav {
        &__popular-header {
          @include swap_direction(padding, 15px 0 20px 15px);
          display: inline-block;
          font-family: $font--helvetica-neue;
          font-size: 24px;
          line-height: 25px;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          margin-bottom: 18px;
          font-weight: bold;
          height: 18px;
        }
        &__popular-list {
          font-family: $font--helvetica-neue;
          font-size: 24px;
          line-height: 40px;
          li {
            padding-bottom: 10px;
            padding-#{$ldirection}: 15px;
            a {
              text-decoration: none;
              color: $color-black;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  &__submit {
    display: inline-block;
    margin: 0;
    position: absolute;
    #{$ldirection}: 15px;
    top: 10px;
    @include breakpoint($large-up) {
      #{$ldirection}: $container-pad-landscape;
      top: 6px;
    }
    .svgicon--search--left {
      width: 25px;
      height: 25px;
    }
  }
}

.search-form {
  width: 100%;
  &__fields {
    @include pie-clearfix;
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
  }
  input[type='text'].search-form__field {
    width: 100%;
    background: transparent;
    height: auto;
    padding: 0;
    text-transform: uppercase;
    &:focus {
      border-color: $color-black;
    }
    @include breakpoint($large-up) {
      border: 1px solid $color-black;
      padding: 0 7px;
      float: $ldirection;
      &:focus {
        border-color: $color-black;
      }
    }
  }
  input[type='submit'].search-form__submit {
    @include swap_direction(margin, 0 0 0 5px);
    display: block;
    float: $ldirection;
  }
  .typeahead-wrapper {
    text-align: $ldirection;
    width: 100%;
    background: $color-white;
    @include breakpoint($large-up) {
      background: $color-lighter-gray-2;
    }
    .product--typeahead,
    .product-brief__cta,
    .esearch-product__size {
      @include breakpoint($portrait-only) {
        display: inline-block;
      }
    }
    .product-brief__cta {
      @include breakpoint($portrait-only) {
        clear: both;
      }
    }
  }
  .search-suggestions__link {
    font-size: 24px;
    display: block;
    padding: 0 7px;
  }
  .result {
    padding: 1em 0;
    border-bottom: solid 1px $color-normal-grey;
    text-align: center;
    margin: auto;
    @include breakpoint($large-up) {
      width: 24%;
      border-bottom: none;
    }
    a,
    a:active {
      text-decoration: none;
      color: $black;
    }
  }
  &--results {
    margin: 0 auto;
    position: relative;
    overflow-y: auto;
    height: 100%;
    min-height: $mobile_search_header_height; // Same height as .search-results__header--mobile. We do this so that it completely covers it.
    @include breakpoint($large-up) {
      min-height: auto;
      background: $color-lighter-gray-2;
      padding: 0 $container-pad-landscape;
      overflow-y: unset;
    }
    .search-form__results-count {
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      #{$rdirection}: auto;
      width: 100%;
      text-align: center;
      padding: 0 12px;
      @include breakpoint($large-up) {
        width: auto;
        text-align: $ldirection;
      }
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($large-up) {
    padding: 0 20px;
  }
}
// @todo check styles
.search-results__header--mobile {
  text-align: center;
}

.search-results__header {
  display: block;
  @include breakpoint($large-up) {
    display: none;
  }
  input[type='text'].search-form__field {
    width: 70%;
    float: $ldirection;
    clear: none;
  }
  input[type='submit'].search-form__submit {
    float: $rdirection;
  }
}

.gnav-esearch__icon {
  font-size: 18px;
  padding-top: 15px;
  @include breakpoint($large-up) {
    padding-top: 0;
  }
}

.gnav-esearch__icon .svgicon {
  width: 30px;
  height: 25px;
  cursor: pointer;
}

.search-mobile__block {
  width: 100%;
  height: 100vh;
  background: $white;
  z-index: 9999;
  display: block;
}

.esearch-product--typeahead {
  padding: 10px;
  width: 25%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  .esearch-product {
    &__headline {
      width: 100%;
      margin: auto;
      @include breakpoint($large-up) {
        text-align: center;
        margin: 0 0 30px;
      }
    }
    &__subline {
      @include breakpoint($large-up) {
        text-align: center;
      }
    }
    &__details {
      display: flex;
      justify-content: center;
    }
    &__lineitem .esearch-product__image {
      width: 29%;
      margin: 0 auto;
      float: $ldirection;
      min-height: auto;
      img {
        width: 95%;
        margin-#{$ldirection}: 5px;
        &.esearch_enable_old__image {
          width: 50px;
        }
      }
    }
    &__subheading {
      font-family: $font--helvetica-neue;
      font-size: 18px;
      text-transform: uppercase;
      @include breakpoint($large-up) {
        line-height: 1.3em;
      }
    }
    &__name {
      font-family: $font--helvetica-neue-bold;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &__shortdesc {
      display: none;
      @include breakpoint($large-up) {
        font-size: 16px;
        font-family: $font--helvetica-neue;
        display: block;
      }
    }
    &__info {
      float: $rdirection;
      width: 71%;
      margin: 0;
      padding-top: 20px;
      @include breakpoint($large-up) {
        padding-top: 15px;
      }
    }
    &__review-snippet {
      .pr-snippet-rating-decimal {
        display: none;
      }
      .pr-category-snippet__total {
        vertical-align: bottom;
        padding-top: 5px;
      }
      .pr-snippet-stars {
        @include breakpoint($large-up) {
          background: $color-lighter-gray-2;
        }
      }
      .p-w-r .pr-star-v4 {
        color: $black;
        @include svg-icon-inline-mask('star_v2.svg');
        width: 16px;
        height: 14px;
        color: $color-black;
        background: $color-black;
        @include breakpoint($large-up) {
          width: 23px;
          height: 22px;
        }
        &:before {
          font-size: get-rem(16px);
          @include breakpoint($large-up) {
            font-size: get-rem(24px);
          }
        }
        &-0-filled {
          @include svg-icon-inline-mask('star-outline_v2.svg');
        }
      }
      .pr-rating-stars {
        vertical-align: top;
        padding-top: 4px;
      }
    }
  }
}

.esearch-nav-products {
  @include breakpoint($large-up) {
    padding-top: 10px;
  }
}

.gnav-search__block {
  padding: 0 20px;
  position: fixed;
  top: 10px;
  width: 100%;
  .gnav-menu__icon {
    @include swap_direction(padding, 6px 8px 0 0);
    border-bottom: 2px solid $white;
  }
  .svgicon {
    color: $white;
    fill: $white;
    padding-bottom: 3px;
  }
}

.gnav-esearch__block {
  overflow-y: hidden;
}

.site-header__wrapper-inner .gnav-esearch__icon {
  float: $rdirection;
}

.site-header__menu .site-header__search {
  top: 180px;
}

.device-pc .esearch-nav__form {
  .esearch-nav__form-fields input[type='text'] {
    width: 100%;
  }
}
