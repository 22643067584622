.lucky_draw {
  #content {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  img {
    border: none;
    vertical-align: middle;
    max-width: 100%;
  }
  .page1_logn {
    position: absolute;
    bottom: 36%;
    left: 9.5%;
  }
  .page1_list {
    position: absolute;
    bottom: 92px;
    right: 182px;
  }
  label {
    color: $color-black;
    display: inline;
    font-weight: normal;
  }
  .back_to_top {
    position: absolute;
    left: 61%;
    top: 55%;
  }
  .page_form {
    width: 450px;
    position: absolute;
    top: 30%;
    left: 12%;
    font-size: 14px;
    color: $color-black;
    height: 55%;
    overflow-y: auto;
    ul li {
      margin-bottom: 10px;
    }
  }
  .error {
    color: $color-red;
  }
  .new_address {
    display: none;
    margin-left: 0px;
    ul li input {
      background-color: $color-white;
      border: 1px solid $color-lucky-draw;
      font-size: 16px;
      color: $color-black;
      margin-left: 10px;
      margin-right: 10px;
      line-height: 24px;
      &.noboder {
        border: none;
      }
    }
    select {
      margin-left: 5px;
      width: 20%;
      height: 26px;
      line-height: 14px;
      min-width: initial;
      font-size: 14px;
      display: inline;
      padding: 0px 0px 0px 10px;
      border: 1px solid $color-lucky-draw;
    }
  }
  .name,
  .mobile {
    width: 141px;
  }
  .phone {
    width: 121px;
  }
  .youbian {
    width: 141px;
  }
  .Address {
    width: 161px;
  }
  .lucky_draw .new_address .h30 {
    height: 26px;
    padding: 0px;
  }
  .sumbit_btn {
    margin-top: 30px;
    img {
      width: 135px;
    }
  }
  .allbtn {
    display: block;
    text-align: center;
    img {
      width: 135px;
    }
  }
  .page3_form {
    position: absolute;
    top: 48%;
    left: 16%;
    a {
      top: 120%;
      margin-top: 20px;
      display: inline-block;
    }
    ul li {
      padding-bottom: 10px;
      font-size: 14px;
      &.last {
        float: left;
      }
      &.mobile {
        margin-right: 5px;
      }
    }
  }
  .page4_form {
    position: absolute;
    top: 57%;
    left: 16%;
    color: $color-white;
  }
  .page5_form {
    position: absolute;
    top: 230px;
    left: 530px;
    color: $color-white;
    input {
      margin-right: 5px;
    }
    select {
      margin: -5px 5px;
      line-height: 12px;
    }
    ul li {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
  .gou_message {
    position: relative;
    margin-top: 20px;
  }
  .page_form .check_btn {
    width: 11px;
    height: 11px;
    cursor: pointer;
    background: url(/media/images/lucky_draw/gou_normal.png) no-repeat;
    display: inline;
    position: absolute;
    top: 2px;
    left: 1px;
    border: 1px solid $color-lucky-draw;
    padding: 5px;
  }
  .chek_word {
    text-indent: 1.5em;
  }
  .page_form .check_btn.on {
    background: url(/media/images/lucky_draw/gou_on2.png) no-repeat;
  }
  .form_list {
    display: block;
    margin: 10px 0;
    font-size: 14px;
  }
  .sumbit_btn2 {
    margin-top: 50px;
  }
  #blacklayer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: url(/media/images/lucky_draw//layerbg.png);
    display: none;
  }
  .price_meun {
    width: 536px;
    height: 431px;
    z-index: 9999;
    background: $color-white;
    color: $color-black;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -268px;
    margin-top: -215px;
    display: none;
  }
  .close_btn {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .price_meun h1 {
    padding: 50px 0 0 0;
    text-align: center;
  }
  .menulist {
    width: 430px;
    height: 255px;
    position: absolute;
    top: 118px;
    left: 90px;
    color: $color-black;
    dl {
      float: left;
      width: 123px;
    }
    dt {
      margin-bottom: 10px;
    }
    dd {
      margin-bottom: 5px;
      color: $color-gray-light;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .middle {
    margin-right: 30px;
  }
  input[type='text'] {
    height: 30px;
  }
  .scroll {
    .jspVerticalBar {
      top: 15px;
      background-image: url(/media/images/lucky_draw/lm_pic_9.png);
      background-color: transparent;
      background-repeat: repeat-y;
      width: 3px;
      right: 30px;
    }
    .jspTrack {
      background-color: transparent;
    }
    .jspArrow {
      background: none;
    }
    .jspDrag {
      width: 3px;
      background-color: $color-black;
      height: 83px;
    }
  }
}

.lucky_draw_m {
  padding-top: 140px;
  > #content > img {
    width: 100%;
  }
  .page1_logn {
    position: absolute;
    top: 33%;
    left: 10%;
    bottom: initial;
    right: initial;
  }
  .back_to_top {
    position: absolute;
    top: 35%;
    left: 10%;
  }
  .page4_form {
    top: 20%;
    left: 10%;
  }
  .page_form {
    left: 0px;
    top: 12%;
    width: 100%;
    padding: 0px 5px;
  }
  .newadd,
  .noboder {
    height: 15px;
    width: 15px;
  }
  .page3_form {
    position: absolute;
    top: 20%;
    left: 10%;
    a {
      top: 120%;
    }
  }
  .new_address select {
    width: 20%;
  }
}

@media only screen and (max-width: 768px) {
  .lucky_draw {
    .name,
    .mobile,
    .phone {
      width: 35%;
    }
    .new_address ul li input {
      margin-left: 0px;
      margin-right: 0px;
    }
    .youbian,
    .Address {
      width: 50%;
    }
    .page_form {
      height: 45%;
    }
    .price_meun {
      width: 90%;
      height: 431px;
      left: 5%;
      margin-left: 0px;
    }
    .menulist {
      left: 15px;
    }
  }
}
