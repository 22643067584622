// Header/navigation area styles

.blog-header {
  margin: 14px 0;
  &__nav-content {
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 10px 0 0 38px);
    }
  }
  &__logo {
    background: url('/media/images/blog/blog_header.gif');
    width: 960px;
    height: 126px;
    @include breakpoint($medium-down) {
      display: none;
    }
  }
  &__title {
    padding: 8px 0;
  }
  .menu__item {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    background: $color-light-gray;
    text-align: center;
    line-height: 2em;
    border-top: $color-dark-gray 1px solid;
    &:last-child {
      border-bottom: $color-dark-gray 1px solid;
      @include breakpoint($medium-up) {
        border: none;
      }
    }
    @include breakpoint($medium-up) {
      display: inline-block;
      width: 24%;
      text-align: #{$ldirection};
      background: none;
      line-height: 1em;
      border: none;
    }
    a {
      color: $color-dark-gray;
      text-decoration: none;
    }
  }
}
