// invoke in scss as example: #{$base-theme-path}
$base-theme-path: '/sites/labseries/themes/labseries_base';

$img-base-url: '../img/';
$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

$netstorage-fonts-path: '/_fonts/labseries/'; // Netstorage path.

@import 'compass';
@import 'breakpoint';
@import 'compass/css3/animation';
@import 'overrides';
@import 'global/global';
