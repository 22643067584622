///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

.search-wrapper {
  width: 100%;
  &__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  .summary-results__result {
    text-align: center;
    font-size: 24px;
    .user-search-term {
      font-weight: bolder;
    }
  }
  .summary {
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
    .user-search-term {
      font-weight: bolder;
    }
  }
  .results {
    &__products {
      .products {
        &__header {
          // temp hide sort
          display: none;
        }
      }
    }
    &__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
  }
  &__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
    }
  }
  .breadcrumb,
  .dimension {
    padding: 0.5em;
  }
}

// temporary 2 col layout for genie only
// @setup new brand may remove these classes from markup
// or adjust accordingly
// genie_base/template_api/endeca/esearch_results_pc_v1.mustache
.results__header--column {
  text-align: center;
  @include breakpoint($medium-up) {
    width: 25%;
    float: $ldirection;
    height: 100%;
    padding-#{$ldirection}: 1em;
    position: relative;
    display: block;
    text-align: $ldirection;
  }
}

.results__products--column {
  margin: 0 1em;
  @include breakpoint($medium-up) {
    margin: 0;
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
}

.esearch-product {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  margin: 0 0 30px 0;
  position: relative;
  &__headline {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-bottom: 20px;
    text-transform: uppercase;
    float: $ldirection;
    width: 100%;
    font-size: 18px;
    @include breakpoint($medium-up) {
      padding: 0;
      float: none;
      width: auto;
      min-height: 3em;
      width: 95%;
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  &__short-desc {
    float: $ldirection;
    clear: $rdirection;
    margin: 0 0 0 10px;
    width: 65%;
    @include breakpoint($medium-up) {
      display: none;
      min-height: 165px;
    }
  }
  &__quickshop-wrapper {
    display: none;
  }
  &:hover &__quickshop-wrapper {
    @include breakpoint($medium-up) {
      display: block;
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__price,
  &__size {
    float: $ldirection;
    clear: none;
    padding-top: 1em;
    font-size: 18px;
    font-weight: bold;
    margin-#{$rdirection}: 30px;
    text-align: center;
    @include breakpoint($medium-up) {
      font-weight: bold;
      text-align: #{$ldirection};
    }
  }
  &__price {
    @include breakpoint($medium-up) {
      display: block;
      font-size: 20px;
    }
  }
  &__review-snippet {
    margin-bottom: 0.5em;
  }
  &__shop-now,
  .product-brief__cta--shop-now {
    float: $rdirection;
    font-size: 16px;
  }
  .p-w-r .pr-category-snippet {
    margin: 0;
  }
  &__shop-now {
    @include breakpoint($small-down) {
      display: none;
    }
  }
  .product__button--add-to-bag {
    @include breakpoint($medium-up) {
      display: none;
      float: none;
    }
    .product-sku-price {
      margin-#{$ldirection}: 14px;
      padding-#{$ldirection}: 14px;
      border-#{$ldirection}: 2px solid $white;
      font-size: 14px;
      display: inline-block;
    }
  }
  &--typeahead {
    text-align: center;
    .esearch-product {
      &__info {
        text-align: $ldirection;
      }
      &__headline {
        margin: 0;
      }
      &__price,
      &__size {
        padding-top: 0;
      }
    }
    a.product-brief__cta--shop-now {
      color: $white;
      float: $ldirection;
      font-size: 12px;
      line-height: 2em;
      @include swap_direction(margin, 5px 0px 0px 10px);
      &:active {
        color: $white;
      }
    }
  }
}

.esearch-product__image {
  text-align: center;
  @include breakpoint($medium-up) {
    width: 100px;
    margin: 0 auto;
    min-height: 190px;
  }
  img {
    width: 200px;
    @include breakpoint($medium-up) {
      max-width: 100%;
      width: 100px;
    }
  }
}

.esearch-product__content {
  text-align: $ldirection;
  margin: 0;
  a.esearch-product__headline-link {
    color: $color-black;
    font-size: 18px;
  }
  .product__button--add-to-bag {
    display: none;
  }
}

// plain styles for responsive result on search page
.endeca-product-result {
  padding: 1em 0;
  border-bottom: $color-grey solid 2px;
  display: inline-block;
  width: 100%;
  @include breakpoint($medium-up) {
    padding: 1em 0 2em 0;
    width: 33%;
    float: $ldirection;
    border-#{$rdirection}: $color-black solid 1px;
    border-bottom: none;
    background: none;
    height: 520px;
  }
  .esearch-product__image {
    @include breakpoint($medium-up) {
      width: 200px;
      margin-bottom: 5px;
    }
    img {
      @include breakpoint($medium-up) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .esearch-product__review .p-w-r .pr-category-snippet__item {
    font-size: 18px;
  }
  .esearch-product__review-snippet {
    .pr-category-snippet__item {
      font-size: 18px;
    }
    .p-w-r .pr-star-v4 {
      color: $black;
      @include svg-icon-inline-mask('star_v2.svg');
      width: 16px;
      height: 14px;
      color: $color-black;
      background: $color-black;
      @include breakpoint($large-up) {
        width: 23px;
        height: 22px;
      }
      &:before {
        font-size: get-rem(16px);
        @include breakpoint($large-up) {
          font-size: get-rem(24px);
        }
      }
      &-0-filled {
        @include svg-icon-inline-mask('star-outline_v2.svg');
      }
    }
  }
}

.endeca-test-image {
  width: 100px;
  height: 125px;
  background-color: $color-gray;
  &--small {
    width: 50px;
    height: 62px;
  }
}

// dimensions
.results__header--dimensions {
  .results-header {
    cursor: pointer;
  }
}

//mobile results header
.search-results {
  &__no-results {
    @include breakpoint($medium-down) {
      padding-top: 0.5em;
      text-align: center;
    }
  }
}

.search-wrapper .summary-results__result {
  font-size: 24px;
  &.user-search-term {
    font-weight: bold;
  }
}

.site-content .esearch-product__subline {
  font-size: 12px;
  height: 10px;
}
