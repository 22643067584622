/* Gift Card Global Elements */
@include breakpoint($portrait-up) {
  #giftcard-balance.giftcards {
    .form_column {
      float: #{$ldirection};
      width: 460px;
      border-#{$rdirection}: 2px;
      margin-bottom: 20px;
      border-#{$rdirection}: 2px solid $color-grey;
    }
    #nav_balance {
      a {
        color: $color-black;
      }
    }
    #content {
      h2 {
        border: 0;
        font-size: 25px;
        font-weight: bold;
        padding-top: 5px;
        text-transform: none;
        background: none;
        margin-bottom: 30px;
      }
    }
    fieldset.fs {
      .form_element {
        input {
          width: 214px;
          height: 28px;
          font-size: 12px;
        }
      }
    }
  }
  #giftcard-about.giftcards {
    #nav_about {
      a {
        color: $color-black;
      }
    }
    .egift-card-content {
      h1 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      h3 {
        font-size: 12px;
        color: $color-black;
        font-weight: bold;
        margin-bottom: 6px;
      }
      #content_body {
        font-size: 12px;
        a {
          font-weight: bold;
        }
      }
    }
    #content_body {
      ul {
        li {
          list-style-type: disc;
          margin-#{$ldirection}: 17px;
          padding: 3px 0;
        }
      }
    }
    .purchasing {
      border-bottom: 2px solid $color-grey;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    .redeeming {
      border-bottom: 2px solid $color-grey;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    #content {
      h2 {
        border: 0;
        font-size: 25px;
        font-weight: bold;
        padding-top: 5px;
        text-transform: none;
        background: none;
        margin-bottom: 30px;
      }
    }
  }
  #egiftcards.giftcards {
    #content {
      h2 {
        border: 0;
        font-size: 25px;
        font-weight: bold;
        padding-top: 5px;
        text-transform: none;
        background: none;
        margin-bottom: 30px;
      }
    }
    fieldset.fs {
      .form_element {
        input {
          width: 214px;
          height: 28px;
          font-size: 12px;
        }
        margin: 0px;
        padding: 0px 0 22px 0;
        label {
          white-space: nowrap;
        }
      }
    }
    #cboxClose {
      &:before {
        color: $color-black;
      }
    }
    #nav_buy_egift {
      a {
        color: $color-black;
      }
    }
  }
  #giftcard-landing.giftcards {
    #nav_landing {
      a {
        color: $color-black;
      }
    }
  }
  #pgiftcards.giftcards {
    #nav_buy_pgift {
      a {
        color: $color-black;
      }
    }
  }
  #giftcard-terms.giftcards {
    #nav_terms {
      a {
        color: $color-black;
      }
    }
  }
  body.giftcards {
    .egift-card-content {
      .errors-giftcard_balance {
        margin-bottom: 20px;
      }
      #form--errors--egiftcard {
        margin-bottom: 20px;
        display: none;
      }
      #content {
        float: #{$rdirection};
        width: 706px;
        .add-button {
          .add_progress {
            line-height: 35px;
          }
        }
        h2 {
          color: $color-dark-blue;
          margin-bottom: 30px;
          font-size: 26px;
          font-weight: bold;
        }
      }
      #sidebar {
        float: #{$ldirection};
        margin-#{$rdirection}: 25px;
        width: 150px;
      }
      .img_column {
        margin-#{$rdirection}: 20px;
        width: 230px;
        margin-bottom: 15px;
        p {
          margin-bottom: 12px;
          width: 230px;
          height: 150px;
          border: 1px solid #ccc;
          text-align: center;
        }
      }
      #home {
        border-bottom: 1px solid $color-grey;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
        a {
          color: $color-black;
        }
      }
      #content_body {
        margin-bottom: 25px;
      }
      float: #{$ldirection};
      margin-bottom: 25px;
      padding: 25px 46px 50px 25px;
      width: 889px;
      background-color: $color-white;
      box-sizing: content-box;
      fieldset.fs {
        padding: 0;
        margin: 0px;
      }
      .form_column {
        float: #{$rdirection};
        width: 214px;
      }
      .return-btn {
        margin-top: 15px;
        font-size: 14px;
        font-weight: normal;
        line-height: 35px;
        height: 35px;
      }
      .update-wrapper {
        input {
          padding: 0;
          font-weight: normal;
        }
      }
    }
    fieldset.giftcard {
      width: 460px;
      width: 214px;
    }
    .giftcard_terms {
      margin-bottom: 22px;
      margin-top: 5px;
      float: #{$ldirection};
    }
    .denotes_required {
      margin-bottom: 30px;
    }
    #nav_giftcards {
      font-size: 14px;
      margin: 0 0 40px;
      li {
        margin: 0 0 12px;
        display: block;
        font-size: 12px;
        a {
          color: $color-dark-blue;
          text-decoration: none;
        }
      }
    }
    ul#gc-bal-info {
      list-style: disc outside none;
      margin: 5px 0;
      padding-#{$ldirection}: 18px;
      li {
        padding: 5px 0;
      }
    }
    input.GC_GIFTCARD_NUMBER {
      width: 188px !important;
    }
    input.GC_GIFTCARD_PIN {
      width: 188px !important;
    }
    #remaining-balance {
      border: 1px dashed $color-black;
      margin: 10px -10px;
      padding: 10px;
      width: 220px;
      span {
        color: $color-red;
      }
    }
    .egift-card-header {
      .egift-card-title {
        img {
          color: $color-dark-blue;
          float: #{$ldirection};
          font-weight: bold;
          margin: 36px 0 36px 28px;
        }
        float: #{$ldirection};
        width: 650px;
      }
      border: 2px solid $color-grey;
      float: #{$ldirection};
      height: 99px;
      width: 956px;
      margin-bottom: 20px;
    }
    .easy_to_use {
      float: #{$ldirection};
      width: 200px;
      padding-#{$ldirection}: 30px;
      li {
        list-style-type: decimal;
        font-weight: bold;
        margin-bottom: 15px;
        margin-#{$ldirection}: 18px;
        span {
          font-weight: normal;
        }
      }
    }
    .balance_instruction {
      margin-bottom: 25px;
      padding-#{$rdirection}: 20px;
    }
    .giftcard_container {
      input {
        margin: 8px 0 10px;
      }
    }
    .giftpin_container {
      input {
        margin: 8px 0 10px;
      }
    }
    #giftcard_balance {
      fieldset.fs {
        .giftcard_container {
          margin: 0px;
          padding: 5px 40px 10px 0;
        }
        .giftpin_container {
          margin: 0px;
          padding: 5px 0 10px 0;
        }
      }
      #check-balance-btn {
        border: none;
      }
    }
    .other-policies-popup {
      li {
        list-style-type: disc;
        margin-#{$ldirection}: 17px;
      }
      height: auto;
    }
    #overlay-container {
      .other-policies-popup {
        > h2 {
          color: $color-dark-blue;
        }
      }
    }
    .giftcard-retrieval {
      #content {
        h2 {
          border: none;
          margin-bottom: 14px !important;
          padding-bottom: 0;
        }
        p {
          font-size: 18px;
        }
        p#print-records {
          font-size: 14px !important;
          font-weight: normal !important;
        }
      }
      #egc-retrieval {
        p {
          font-weight: bold;
          font-size: 14px !important;
          span {
            font-weight: normal !important;
          }
        }
      }
    }
    #egc-retrieval {
      margin-top: 15px;
    }
    .egiftcard-info {
      float: #{$ldirection};
      width: 425px;
      padding-#{$rdirection}: 25px;
      border-#{$rdirection}: 2px solid $color-grey;
      p {
        margin-bottom: 8px;
        width: 423px;
      }
    }
    .add-button {
      float: #{$ldirection};
    }
    fieldset.fs {
      .form_element {
        select {
          width: 214px;
        }
      }
    }
    textarea.egiftcard-message {
      display: block;
      width: 214px;
      height: 160px;
      font-size: 12px;
      padding-top: 10px;
    }
    .select_design {
      h3 {
        margin-bottom: 6px;
      }
      .img_column {
        width: 790px;
        height: 215px;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;
        ul {
          position: absolute;
          top: 0;
          left: 0;
        }
        li {
          float: #{$ldirection};
          margin-#{$rdirection}: 15px;
          text-align: center;
          a {
            margin-top: 8px;
            display: block;
          }
          div {
            display: inline;
          }
          .title {
            span {
              display: none;
            }
          }
        }
        li.highlight {
          .title {
            span {
              display: inline;
              color: #777777;
            }
          }
          p {
            border: 3px solid;
            width: 232px;
            height: 152px;
            margin: 0px 0px 5px 0px;
          }
        }
        p {
          cursor: pointer;
          margin: 2px 0 5px 2px;
        }
        a.left {
          display: block;
          position: absolute;
        }
        a.right {
          display: block;
          position: absolute;
          right: -0px;
        }
      }
      #scroller {
        position: relative;
        width: 10000px;
        margin-#{$ldirection}: 25px;
      }
      .form_column {
        float: #{$ldirection};
        width: 800px;
      }
      fieldset.fs {
        .form_element {
          width: 180px;
          input {
            width: 160px;
          }
        }
      }
      fieldset {
        margin: 5px 0;
      }
      fieldset.top {
        width: 400px;
        float: #{$rdirection};
        width: 400px;
        float: #{$rdirection};
      }
      fieldset.value_container {
        float: #{$ldirection};
        width: 350px;
      }
      fieldset.email_info {
        float: #{$ldirection};
        width: 400px;
      }
      fieldset.message {
        float: #{$rdirection};
        width: 400px;
        .form_element {
          width: 380px;
        }
      }
      fieldset.bottom {
        width: 340px;
        float: #{$ldirection};
      }
      textarea.egiftcard-message {
        width: 365px;
      }
      .egiftcard-info {
        margin-top: 15px;
        width: 750px;
      }
    }
    #egift_sample_container {
      h3 {
        margin-bottom: 12px;
      }
      .img_column {
        float: #{$ldirection};
      }
      p {
        margin-bottom: 10px;
      }
      .redeem_info {
        margin-bottom: 12px;
        li {
          list-style-type: decimal;
          margin-#{$ldirection}: 18px;
        }
      }
      p.message {
        margin-bottom: 25px;
      }
      p.egiftcard_info {
        margin: 15px 0;
      }
    }
  }
}
