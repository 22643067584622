.header-links {
  width: 100%;
  background-color: #b9b9bb;
  @include swap_direction(padding, 30px 15px 15px 30px);
  @include breakpoint($medium-down) {
    @include swap_direction(padding, 30px 15px 15px 10px);
  }
  margin-bottom: 25px;
  &__title {
    font-size: 36px;
    line-height: 1;
    text-transform: uppercase;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid $black;
    @include breakpoint($portrait-up) {
      padding-top: 0;
    }
  }
  &__link {
    display: inline-block;
    @include swap_direction(margin, 15px 10px 0 0);
    color: #666666;
    font-size: 20px;
    text-transform: uppercase;
    @include breakpoint($medium-down) {
      display: block;
    }
    &.active {
      color: $black;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__link:last-child {
    margin-right: 0;
  }
  @include breakpoint($med-small-down) {
    background: $color-white;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 20px 0 0 0);
  }
  background-color: inherit;
  margin-bottom: 0px;
  @include swap_direction(padding, 30px 0 0 0);
  text-transform: none;
  .header-links__title {
    border-bottom: none;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    padding-bottom: 10px;
  }
}
