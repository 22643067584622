.content-block-large.content-block-large--text {
  margin: 20px auto;
  .content-block-large__text {
    margin-bottom: 10px;
  }
  .content-block-large__inner,
  .content-block-large__content {
    position: static;
    transform: none;
  }
  .content-block-large__content {
    padding: 0 10px;
  }
}
