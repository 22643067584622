///////////////////////////////
/// HERO LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////
.hero-large {
  height: auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  &.hero-large--color-light {
    background-color: $color-black;
  }
  &__inner {
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      position: absolute;
      z-index: 3;
      top: 1em;
      bottom: auto;
      // content widths
      &.hero-large__inner-width--20 {
        width: 20%;
      }
      &.hero-large__inner-width--30 {
        width: 30%;
      }
      &.hero-large__inner-width--40 {
        width: 40%;
      }
      &.hero-large__inner-width--50 {
        width: 50%;
      }
      &.hero-large__inner-width--60 {
        width: 60%;
      }
      &.hero-large__inner-width--70 {
        width: 70%;
      }
      &.hero-large__inner-width--80 {
        width: 80%;
      }
      &.hero-large__inner-width--90 {
        width: 90%;
      }
      &.hero-large__inner-height--200 {
        height: 200px;
      }
      &.hero-large__inner-height--400 {
        height: 400px;
      }
      &.hero-large__inner-height--600 {
        height: 600px;
      }

      // horizontal position
      &-hpos--center {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &-hpos--left {
        left: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          left: 50%;
        }
        &.hero-large__inner-margin--40 {
          left: 40%;
        }
        &.hero-large__inner-margin--30 {
          left: 30%;
        }
        &.hero-large__inner-margin--20 {
          left: 20%;
        }
        &.hero-large__inner-margin--10 {
          left: 10%;
        }
      }
      &-hpos--right {
        right: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          right: 50%;
        }
        &.hero-large__inner-margin--40 {
          right: 40%;
        }
        &.hero-large__inner-margin--30 {
          right: 30%;
        }
        &.hero-large__inner-margin--20 {
          right: 20%;
        }
        &.hero-large__inner-margin--10 {
          right: 10%;
        }
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .hero-large__content {
          @include vertical-horizontal-center;
        }
      }
      &-vpos--top {
        top: 1em;
      }
      &-vpos--bottom {
        position: absolute;
        bottom: 1em;
        top: auto;
      }
    }
    &--copy-above-image {
      top: 0;
      .hero-large__text {
        @include breakpoint($portrait-up) {
          margin-top: 0;
        }
        margin-top: -150%;
      }
    }
    &--copy-over {
      &-top-image,
      &-center-image,
      &-bottom-image {
        @include breakpoint($portrait-up) {
          #{$ldirection}: auto;
          #{$rdirection}: 40px;
        }
        position: absolute;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
      }
      &-top-image {
        top: 0;
      }
      &-center-image {
        @include breakpoint($portrait-up) {
          top: auto;
        }
        top: 30%;
      }
      &-bottom-image {
        bottom: 0;
      }
    }
  }
  &__content {
    padding: 1em;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__image {
    &.hero-large__image--copy-above-image {
      @include breakpoint($portrait-up) {
        margin-top: 0;
      }
      margin-top: 150px;
    }
  }
  &__text {
    margin-bottom: 10px;
    .hero-large--color-light & {
      color: $color-white;
    }
    p {
      margin: 0;
    }
  }
  &__cta {
  }
  &__image,
  &__image img {
    width: 100%;
  }
  &__image img {
    @include breakpoint($large-up) {
      float: left;
    }
  }
}

.hero-2-col {
  &__header {
    margin: 50px auto 25px auto;
  }
  &__heading {
    font-weight: bold;
    text-align: center;
  }
  &__left,
  &__right {
    position: relative;
    background-repeat: no-repeat;
    background-position: center top;
    @include breakpoint($large-up) {
      float: left;
      clear: none;
      width: 50%;
      min-height: 250px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &__left {
    @include breakpoint($large-up) {
      padding: 30px 10px 30px 30px;
      //column Split
      &-split--80-20 {
        width: 80%;
      }
      &-split--70-30 {
        width: 70%;
      }
      &-split--60-40 {
        width: 40%;
      }
      &-split--50-50 {
        width: 50%;
      }
      &-split--40-60 {
        width: 40%;
      }
      &-split--30-70 {
        width: 30%;
      }
      &-split--20-80 {
        width: 20%;
      }

      //gutter width
      &-gutter--10 {
        padding-right: 10px;
      }
      &-gutter--15 {
        padding-right: 15px;
      }
      &-gutter--20 {
        padding-right: 20px;
      }
      &-gutter--25 {
        padding-right: 20px;
      }
      &-gutter--30 {
        padding-right: 30px;
      }
      &-gutter--35 {
        padding-right: 35px;
      }
      &-gutter--40 {
        padding-right: 40px;
      }
      &-gutter--45 {
        padding-right: 45px;
      }
      &-gutter--50 {
        padding-right: 50px;
      }
    }
  }
  &__right {
    @include breakpoint($large-up) {
      padding: 30px 30px 30px 15px;
      &-split--80-20 {
        width: 20%;
      }
      &-split--70-30 {
        width: 30%;
      }
      &-split--60-40 {
        width: 40%;
      }
      &-split--50-50 {
        width: 50%;
      }
      &-split--40-60 {
        width: 60%;
      }
      &-split--30-70 {
        width: 70%;
      }
      &-split--20-80 {
        width: 80%;
      }
      gutter--10 {
        padding-left: 10px;
      }
      &-gutter--15 {
        padding-left: 15px;
      }
      &-gutter--20 {
        padding-left: 20px;
      }
      &-gutter--25 {
        padding-left: 20px;
      }
      &-gutter--30 {
        padding-left: 30px;
      }
      &-gutter--35 {
        padding-left: 35px;
      }
      &-gutter--40 {
        padding-left: 40px;
      }
      &-gutter--45 {
        padding-left: 45px;
      }
      &-gutter--50 {
        padding-left: 50px;
      }
    }
  }
  &__copy p {
    line-height: 1.5rem;
  }
}
