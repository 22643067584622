/*
  Colorbox Core Style:
  The following CSS is consistent between example themes and should not be altered.
*/
// @setup new site - this is cleaned up css from the plugin
// alter as needed per brand
// default is from example #3 dark bg, light overlay

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/*
  User Style:
  Change the following styles to modify the appearance of Colorbox.
*/

#cboxOverlay {
  background: #000;
  opacity: 0.9;
}

#colorbox {
  outline: 0;
}

#cboxContent {
  margin-top: 20px;
  background: #000;
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  background: #fff;
}

#cboxTitle {
  position: absolute;
  top: -20px;
  left: 0;
  color: #ccc;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0px;
  color: #ccc;
}

#cboxLoadingGraphic {
  background: url(#{$base-theme-path}/img/vendor/colorbox/loading.gif) no-repeat center center;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: #fff;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top left;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxNext:hover {
  background-position: bottom right;
}

#cboxClose {
  @include icon(close);
  background-color: transparent;
  color: $white;
  position: absolute;
  bottom: auto;
  top: 5px;
  right: 5px;
  display: block;
  font-size: 1px;
  width: 30px;
  &:before {
    // border: $border;
    color: $black;
    font-size: 20px;
    width: 22px;
    height: 22px;
  }
}
