.basic-responsive {
  p {
    line-height: 1;
  }
  &--padding {
    padding: 20px 0;
    @include breakpoint($portrait-up) {
      padding: 25px 0;
    }
    > p {
      padding: 0 10px;
    }
  }
}

.basic-formatter__item {
  // Formatter item next to a formatter item
  & + & {
    padding-top: 5px;
  }
}
