.apply-steps {
  text-align: center;
  position: relative;
  overflow: hidden;
  &__bg {
    position: absolute;
    #{$ldirection}: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
  }
  img {
    display: block;
    max-width: 100%;
  }
  &__content {
    position: relative;
    padding: 30px 10px;
  }
  &__image {
    height: auto;
    margin: auto;
  }
  &__header {
    display: inline-block;
    position: relative;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: bold;
    &:after {
      border-bottom: 1px solid $color-black;
      position: absolute;
      content: '';
      bottom: -12px;
      width: 80%;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
    }
  }
  &__label {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  &__copy {
    margin-bottom: 30px;
    line-height: 2.5em;
    font-size: 14px;
  }
}
