.product-promo-img--v1 {
  overflow: hidden;
  min-height: 400px;
  @include breakpoint($medium-up) {
    min-height: auto;
  }
  .mpp-promo-img-wrapper {
    padding: 1px;
    .mpp-promo-image-block__image {
      position: relative;
      img {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        z-index: -1;
        height: 400px;
        @include breakpoint($medium-up) {
          left: 21px;
        }
      }
    }
    .promo-header {
      margin-top: 40px;
      text-transform: none;
      @include breakpoint($medium-up) {
        margin-top: 60px;
      }
      font-size: 22px;
      @include breakpoint($medium-up) {
        font-size: 19px;
      }
    }
    .button {
      background: none;
      border: 1px solid white;
    }
  }
}
