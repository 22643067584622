$filter-bar-height: 42px !default;
$filter-border-color: #b9b9b9 !default;
$filter-label-font-size: 18px !default;

@mixin filter-content() {
  @include breakpoint($large-up) {
    margin: 20px;
  }
}

// Filter bar

.mpp-filter-bar {
  margin: 24px 0;
  padding: 24px 0;
  border-top: 1px solid $filter-border-color;
  border-bottom: 1px solid $filter-border-color;
  white-space: nowrap;
  @include breakpoint($large-up) {
    padding: 0;
    border: 1px solid $filter-border-color;
  }
  .mpp__filters__headline {
    line-height: $filter-bar-height;
    padding: 0 24px;
    font-size: $filter-label-font-size;
    font-weight: bold;
    white-space: normal;
    word-break: break-word;
    @include breakpoint($large-up) {
      display: inline-block;
      // border-#{$rdirection}: 1px solid $filter-border-color;
      width: 40%;
      letter-spacing: -1px;
    }
  }
  &__items {
    font-size: 0;
    @include breakpoint($large-up) {
      float: $rdirection;
      text-align: $rdirection;
      width: 60%;
    }
  }
  &__item {
    font-size: 12px;
    cursor: pointer;
    overflow: visible;
    padding: 0 24px;
    @include breakpoint($large-up) {
      display: inline-block;
      position: relative;
      border-#{$rdirection}: 1px solid $filter-border-color;
      text-align: center;
      width: 33%;
      &:first-child {
        border-#{$ldirection}: 1px solid $filter-border-color;
      }
      &:last-child {
        border-#{$rdirection}: 0;
      }
    }
  }
}

// Drop-downs

.mpp-dropdown {
  position: relative;
  &__label {
    line-height: $filter-bar-height;
    font-size: $filter-label-font-size;
    font-weight: bold;
    white-space: nowrap;
    .icon {
      margin-#{$ldirection}: 14px;
      @include breakpoint($large-up) {
        position: absolute;
        right: 20px;
        top: 8px;
      }
    }
  }
  &__content {
    // display: none;
    background-color: #fff;
    overflow: hidden;
    max-height: 0;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    z-index: 999;
    @include breakpoint($large-up) {
      position: absolute;
      top: $filter-bar-height;
      #{$ldirection}: 0;
      min-width: 100%;
      border: 1px solid $filter-border-color;
      border-top: 0;
      margin: 0 -1px;
      box-sizing: content-box;
    }
    .over & {
      // display: block;
      max-height: 1000px;
    }
  }
}

// Filter and Sort menus currently supported

.mpp-filter-menu,
.mpp-sort-menu {
  &__option {
    cursor: pointer;
    padding: 4px 0;
    white-space: nowrap;
    @include breakpoint($large-up) {
      padding: 4px;
    }
    &.active {
      font-weight: bold;
    }
  }
}

// Standard drop-downs

.mpp-filter__container,
.mpp-sort__container {
  @include filter-content();
}
/* MPP - Style filter/sort select element */
.mpp-filter-bar {
  @include breakpoint($med-small-down) {
    @include swap_direction(margin, 0);
    width: 100%;
    border: 0;
  }
  @include swap_direction(margin, 0 auto 24px);
  width: 85%;
  h3.mpp__filters__headline {
    display: none;
  }
  &__item {
    &--select {
      display: none;
    }
  }
  &__items {
    width: 100%;
    .mpp-filter-bar__item {
      @include breakpoint($med-small-down) {
        @include swap_direction(padding, 0);
        width: 100%;
      }
      .mpp-dropdown__label {
        @include breakpoint($med-small-down) {
          width: 100%;
          height: 60px;
          line-height: 60px;
          background-color: $color-lighter-gray;
          border: 0;
          text-align-last: center;
          text-align: center;
          -webkit-appearance: none;
          border-radius: 0;
          margin-bottom: 5px;
        }
        font-size: 14px;
      }
      &:first-child {
        border-#{$ldirection}: 0;
      }
      width: 33.3%;
      .icon--caret--down,
      .icon--caret--up {
        @include breakpoint($med-small-down) {
          float: #{$rdirection};
          padding-top: 10px;
          margin-#{$rdirection}: 10px;
          margin-top: 0;
          line-height: 55px;
        }
        @include breakpoint($portrait-up) {
          line-height: 32px;
        }
      }
      .down_icon {
        @include breakpoint($med-small-down) {
          position: absolute;
          #{$rdirection}: 25px;
          .icon--caret--down {
            @include breakpoint($med-small-down) {
              margin-top: 25px;
              float: none;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.mpp-filter-bar__picker--native {
  .mpp-filter-bar {
    &__item {
      @include breakpoint($med-small-down) {
        position: relative;
        &--select {
          display: block;
          width: 100%;
          top: 0;
          position: absolute;
          opacity: 0;
          box-sizing: border-box;
          height: 100%;
          line-height: 60px;
          text-align: center;
          option {
            text-align: center;
          }
        }
      }
    }
  }
}

// Do not show native select element for PC
.no-touchevents.pointerevents {
  .mpp-filter-bar__picker--native {
    .mpp-filter-bar__item--select {
      display: none;
    }
  }
}

.touchevents {
  .mpp-filter-bar__picker--native {
    .over {
      .mpp-dropdown__content {
        display: none;
      }
    }
  }
}
