///
/// @file components/prouducts/_product_grid.scss
///
/// \brief Styles for multi-product grids
///
///

.product-grid {
  position: relative;
  width: 100%;
  //overflow: hidden;

  &-wrapper {
    margin: 15px auto;

    // Basic responsive is used as a header for some
    .basic-responsive + & {
      margin: 0 auto 15px;
    }
  }
  &__content {
    @include pie-clearfix;
    //max-width: $max-width-large;
    margin: 0 auto 1px;
  }
  &--carousel {
    /// Add padding to the product grid for carousels to avoid
    /// overlapping left/right arrows
    .product-grid__content {
      padding: 0 40px;
    }
  }
  &--small-cols-2,
  &--small-cols-3 {
    .product-grid__item {
      float: #{$ldirection};
      border: none;
    }
  }
  &--small-cols-2 .product-grid__item {
    width: 50%;
  }
  &--small-cols-3 .product-grid__item {
    width: 33.333%;
  }
  &__item {
    width: 100%;
    margin-bottom: 30px;
  }
  .grid__item {
    &--right-col {
      border-#{$rdirection}: 0;
    }
  }
  @include breakpoint($medium-up) {
    &__item {
      float: #{$ldirection};
      width: 33.333%;
    }
    &--medium-cols-1 .product-grid__item {
      float: none;
      margin: auto;
    }
    &--medium-cols-2,
    &--medium-cols-3,
    &--medium-cols-4 {
      .product-grid__item {
        &.grid__item--last-col {
          border-#{$rdirection}: 0;
        }
      }
    }
    &--medium-cols-2 .product-grid__item {
      width: 50%;
    }
    &--medium-cols-3 .product-grid__item {
      width: 33.333%;
    }
    &--medium-cols-4 .product-grid__item {
      width: 25%;
    }
    .product__inventory-status-item {
      margin-top: 6px;
    }
  }
}
