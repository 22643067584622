// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.
.signin-block {
  padding: 1em 0 0;
  width: 100%;
  text-align: left;
  .elc-user-state-logged-in & {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 1em 0 0;
    }
  }
  &__forms {
    .elc-user-state-logged-in & {
      display: none;
    }
  }
  &--signin {
    .signin-block__form--register {
      display: none;
    }
    .signin-block__form--signin {
      display: block;
    }
  }
  &__main,
  &__footer {
    padding: 0 2em;
  }
  &__title {
    text-align: center;
    font-size: 2em;
    margin: 0;
  }
  &__subtitle {
    font-size: 1.5em;
    line-height: 1;
    text-align: center;
  }
  &__field {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
    &--first-name,
    &--last-name {
      width: 49%;
    }
    &--first-name {
      float: left;
    }
    &--last-name {
      float: right;
    }
  }
  &__toggle-password {
    padding: 4px 0 9px;
    &__option {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      &--hide {
        display: none;
      }
      .signin-block--show-password & {
        &--show {
          display: none;
        }
        &--hide {
          display: inline-block;
        }
      }
    }
  }
  &__footer {
    background-color: $color-gray;
    padding: 1em;
  }
  &__forgot-password {
    color: $color-black;
    display: block;
    padding: 11px 0;
  }
  &__submit {
    width: 100%;
    margin-top: 9px;
  }
  &__toggle {
    text-align: left;
    padding: 0 20px 15px;
    &__cta {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__authed {
    @include pie-clearfix;
    display: none;
    @include breakpoint($landscape-up) {
      min-width: 370px;
    }
    &__nav {
      @include breakpoint($landscape-up) {
        min-height: 400px;
        padding: 0 30px 30px;
        float: left;
      }
      &__title {
        padding-left: 20px;
        margin: 0;
        // Wait for js to render the title text as it's raw, user-entered
        // mustache.
        display: none;
        @include breakpoint($landscape-up) {
          font-size: 24px;
          padding: 0;
          line-height: 1;
          margin: 0 0 15px;
        }
        &--rendered {
          display: block;
        }
      }
      &__signout-link {
        font-size: 14px;
        border-top: solid 1px $color-black;
        border-bottom: solid 1px $color-black;
        display: block;
        padding-left: 20px;
        @include breakpoint($landscape-up) {
          border: none;
          display: inline;
          padding: 0;
          line-height: 1;
        }
      }
    }
    &__content {
      @include breakpoint($landscape-up) {
        float: right;
        width: 300px;
        border-left: solid 1px $color-light-gray;
        padding: 0 20px 0 30px;
        min-height: 400px;
      }
    }
    .elc-user-state-logged-in & {
      display: block;
    }
  }
}

// auth states visibility
.elc-user-state-anonymous {
  .hide_anon {
    display: none;
  }
}

.elc-user-state-logged-in {
  .hide_auth {
    display: none;
  }
}
