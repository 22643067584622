// Font overrides
$primary-font: 'Helvetica Neue', Helvetica, sans-serif, 'Microsoft YaHei', 'NewtextITCStd-Regular';
$secondary-font: 'Helvetica Neue', Helvetica, sans-serif, 'Microsoft YaHei', 'NewtextITCStd-Regular';

// Lab Series lucky draw colors
$color-lucky-draw: #8ed8e3;
$color-slick-grey: #cfcfcf;
$color-labseries-blue: #eff6ff;

@import 'global/_font_variables';
@import '../../labseries_base/scss/labseries-base-pc';
@import 'sections/_gwp_pc';
@import 'sections/_customer_service';
@import 'sections/_lucky_draw';

// SMACSS/sections/account
@import 'sections/pc/account/account';
@import 'sections/pc/account/signin_otp';

// SMACSS/Components/checkout
@import 'sections/pc/checkout/checkout';
@import 'sections/pc/checkout/paid_sampling';

// SMACSS/sections/all
@import 'sections/pc/all';
@import 'sections/_all';

// Password Strengthening
@import 'sections/account-password-strengthen';

// Legal consent
@import 'sections/_legal_consent';
