/**
 * Styles specific to the Solid Water Essence landing page
 * that uses a few different nodeblock templates.
 */

.landing-page--solid-water-essence {
  strong {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 700;
  }
} // .landing-page--solid-water-essence

.solid-water-essence-hero {
  margin-bottom: 80px;
  font-weight: 300;
  @include breakpoint($medium-up) {
    margin-bottom: 64px;
  }
  &__subheading {
    padding-bottom: 0.25em;
    border-bottom: 1px solid $color-black;
  }
  .icon--video-play {
    margin-top: -4.5em;
    font-size: 60px;
    @include breakpoint($medium-up) {
      margin-top: 0;
      margin-left: 6.5em;
    }
  }
  .content-block-large {
    &__inner {
      top: 50px;
      @include breakpoint($medium-up) {
        top: 0;
      }
    }
    &__text .button {
      display: none;
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
  } // .content-block-large
} // .solid-water-essence-hero

.solid-water-essence-carousel {
  .carousel-controls {
    top: 25%;
    @include breakpoint($medium-up) {
      top: 50%;
    }
    .slick-arrow {
      color: $color-white;
    }
  }
  .content-block-large {
    &__content {
      width: 100%;
      font-weight: 300;
    }
    &__image {
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
    &__text img {
      width: 100%;
    }
  } // .content-block-large

  &__stats-image {
    width: 100%;
  }
  &__stats {
    @include clearfix;
    margin-top: 1em;
  }
  &__stat-one {
    float: left;
    width: 50%;
    padding-right: 7%;
  }
  &__stat-two {
    float: right;
    width: 50%;
    padding-left: 7%;
  }
  .slick-slide[data-slick-index='1'] {
    .content-block-large {
      &__text--1 {
        margin-bottom: 2em;
      }
      &__text--3 {
        margin: 2em 10%;
        width: 80%;
        @include breakpoint($medium-up) {
          margin: 2em 0;
          width: 100%;
          border-color: $color-white;
        }
      }
      &__text--4 p {
        line-height: 1em;
      }
    } // .content-block-large
  }
  .slick-slide[data-slick-index='2'] {
    .content-block-large {
      &__content {
        padding: 2em 1em;
        background-color: rgba(255, 255, 255, 0.7);
      }
      &__text--1 {
        margin-bottom: 2em;
      }
    } // .content-block-large
  }
} // .solid-water-essence-carousel

.solid-water-essence-two-column {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
  .hero-2-col {
    &__header {
      height: 5em;
      line-height: 5em;
      background: #ffffff url('/media/export/cms/solid_water_essence/two-column__header-background--desktop.png')
        no-repeat center center;
    }
    &__left-split--50-50,
    &__right-split--50-50 {
      height: 570px;
      background-size: 368px;
      background-position: top center;
    }
    &__copy p {
      margin: 0;
    }
  } //.hero-2-col

  .hero-large__inner-vpos--top {
    position: relative;
    top: 290px;
  }
} // .solid-water-essence-carousel

.solid-water-essence-bottom-hero {
  .content-block-large {
    &__text--1 {
      height: 5em;
      line-height: 5em;
      background: url('/media/export/cms/solid_water_essence/bottom-hero__header-background--desktop.png') no-repeat
        center center;
    }
    &__cta-wrap {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        margin-top: 10px;
      }
    }
  } // .content-block-large
} // .solid-water-essence-bottom-hero
