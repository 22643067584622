.cs-page__header {
  .cs-page__title {
    border: none;
    @include swap_direction(padding, 15px 20px 10px 15px);
    h1 {
      font-size: 36px;
      line-height: 42px;
      text-transform: uppercase;
      text-align: #{$ldirection};
      margin: 0;
    }
  }
}

.cs-page__navigation {
  float: #{$ldirection};
  clear: none;
  width: 25%;
  @include breakpoint($medium-down) {
    width: 100%;
    float: none;
    clear: both;
  }
}

.customertab {
  @include breakpoint($medium-down) {
    padding-top: 20px;
  }
  li {
    border-top: 1px solid #dbdbdb;
    @include breakpoint($medium-down) {
      width: 100%;
    }
    a {
      color: #333;
      display: block;
      padding: 5px 0;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint($medium-down) {
        margin: 0 0 0 20px;
        padding: 20px 0 15px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    &:last-child {
      border-bottom: 1px solid #dbdbdb;
    }
  }
}

.cs-page__content {
  float: #{$rdirection};
  width: 70%;
  @include breakpoint($medium-down) {
    width: 100%;
    float: none;
  }
  &-item {
    padding: 0 20px;
  }
  .customer_service_cs {
    border-bottom: 2px solid #dbdbdb;
    float: #{$ldirection};
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    width: 30%;
    @include breakpoint($medium-down) {
      width: 100%;
    }
    @include breakpoint($medium-up) {
      height: 345px;
    }
    &:last-child {
      @include breakpoint($medium-down) {
        border-bottom: none;
      }
    }
    h3 {
      color: #333;
      font-size: 16px;
      font-weight: bold;
      padding: 20px 0 15px 0;
      @include breakpoint($medium-down) {
        font-size: 28px;
        line-height: 1.2em;
        padding: 0;
      }
      text-transform: uppercase;
    }
    .customer_service_inner {
      color: #646464;
      font-size: 12px;
      height: 125px;
      padding: 22px 0 25px;
      @include breakpoint($medium-down) {
        font-size: 14px;
        height: auto;
      }
    }
    ul,
    ol {
      color: #026585;
    }
    li {
      color: #6a6a6a;
      padding: 0.3em 0;
      list-style: none outside none;
      a {
        color: #646464;
      }
    }
  }
  .cust_right {
    margin-#{$rdirection}: 10px;
  }
  .customer-service-content-privacy {
    ul {
      margin-#{$ldirection}: 20px;
      & > li {
        text-transform: uppercase;
        font-weight: bold;
      }
      & ul {
        & > li {
          list-style: disc;
          text-transform: capitalize;
        }
        & > ul {
          > li {
            list-style: circle;
            font-weight: normal;
          }
          & > ul > li {
            list-style: square;
            font-style: italic;
          }
        }
      }
    }
  }
  .customer-service-text {
    margin-bottom: 30px;
    line-height: 1.2em;
    .section {
      margin: 15px;
    }
    h2 {
      font-size: 13px;
      font-weight: bold;
      line-height: 23px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    h3 {
      font-size: 13px;
      font-weight: bold;
      line-height: 23px;
      margin-top: 0;
      text-transform: capitalize;
    }
    h4 {
      font-size: 13px;
      line-height: 23px;
      margin-bottom: 10px;
      text-transform: capitalize;
      font-weight: normal;
    }
    ul {
      margin: 0 0 10px 20px;
      font-size: 12px;
      & > li {
        list-style: disc;
      }
    }
    p {
      margin-bottom: 10px;
      line-height: 1.2em;
      font-size: 12px;
    }
  }
}

.contact-questions {
  .error_messages {
    color: $color-red;
  }
  label {
    font-size: 12px;
    width: 100%;
  }
  .strong {
    font-weight: bold;
  }
  .txt_upper label {
    text-transform: uppercase;
    line-height: 2em;
  }
  .question_section {
    display: none;
    padding: 5px 0;
    &.section_default {
      display: block;
    }
    @include breakpoint($medium-up) {
      width: 700px;
    }
  }
  .question-regarding {
    margin-bottom: 10px;
    tr {
      padding: 5px 0;
    }
  }
  .question_label {
    font-size: 16px;
  }
  .section_chooser {
    margin-top: 5px;
  }
  input[type='radio'] {
    @include swap_direction(margin, 5px 5px 5px 0);
  }
  input[type='text'] {
    height: 18px;
    font-size: 12px;
    display: block;
    padding-#{$rdirection}: 10px;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 221px;
    }
    &:focus {
      border-color: $color-grey;
    }
    &.order_number {
      display: block;
      @include breakpoint($medium-up) {
        display: inline;
      }
      top: 7px;
      position: relative;
    }
  }
  .personal-info-form-table {
    margin-top: 1.5em;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 400px;
    }
    input,
    textarea {
      font-size: 12px;
      padding: 0 0 0 5px;
      line-height: 0;
    }
    td {
      @include swap_direction(padding, 0 10px 10px 0);
    }
    label {
      display: block;
      padding-bottom: 5px;
    }
    .textarea {
      line-height: 1em;
      padding: 5px;
      @include breakpoint($medium-up) {
        width: 450px;
      }
    }
    .left {
      float: #{$ldirection};
      clear: none;
      padding-#{$rdirection}: 10px;
      @include breakpoint($medium-down) {
        width: 33%;
      }
      .three_col_fields {
        @include breakpoint($medium-up) {
          width: 121px;
        }
      }
      .city {
        @include breakpoint($medium-up) {
          width: 210px;
        }
      }
      select.three_col_fields {
        width: 90px;
      }
      &:last-child {
        padding: 0;
      }
    }
  }
  .contact_border {
    .button-loading {
      letter-spacing: 2.5px;
      height: 30px;
      line-height: 2;
      font-family: sans-serif;
      font-size: 14px;
      font-weight: unset;
    }
  }
}
