@mixin loyalty-panel-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 10px;
  @include breakpoint($medium-up) {
    border: 1px solid #eee;
    border-width: 1px 0;
  }
}

@mixin loyalty-panel-title-remove {
  text-align: initial;
  text-transform: none;
  font-weight: normal;
  padding: 0;
  margin-bottom: 0;
  @include breakpoint($medium-up) {
    border: 0;
  }
}

// NAVIGATION EXAMPLE
#loyalty__navigation {
  .profile-pic-main {
    display: none;
  }
  /*
  @include breakpoint($medium-up) {
    .account-utilities {
      float: left;
      width: 25%;
      padding-right: 20px;
    }
  }
  */
}
/*
@include breakpoint($medium-up) {
  #loyalty__content {
    padding-left: 25%;
  }
}
*/
// END NAVIGATION EXAMPLE

#loyalty__panel__test {
  @include loyalty-panel;
}
