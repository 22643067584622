$color-lightgrey: #a0a0a0;

.account_page {
  #registration-wrapper {
    #registration {
      .profile {
        .title_element {
          display: block;
          input[type='radio'] {
            width: 15px;
            display: inline-block;
            margin: 0px 5px 0px 0px;
            vertical-align: middle;
          }
          label {
            margin-right: 15px;
          }
        }
        .full_name_container {
          clear: both;
        }
        .password_container {
          label {
            .label-content {
              display: inline-block;
            }
          }
        }
        .registration-page__sms-info {
          .form_element {
            clear: both;
            #address-info-mobile {
              input[type='checkbox'] {
                width: 15px;
                display: inline-block;
              }
              #status_menu_mobile {
                .sms_bound {
                  margin-right: 10px;
                }
                a {
                  margin-right: 5px;
                  color: $color-blue;
                }
              }
            }
          }
          .registration-page__sms-verification {
            input[type='button'] {
              background: $color-black;
              color: $color-white;
              font-weight: bold;
              padding: 0 12px;
              text-transform: uppercase;
              display: inline-block;
              line-height: 35px;
              width: 100px;
            }
          }
        }
      }
      #member_profile_container {
        .gender_container {
          margin-top: 0px;
          input {
            display: inline-block;
            margin: 0px 5px 0px 0px;
            vertical-align: middle;
          }
          label.label {
            float: left;
            margin-right: 15px;
          }
        }
      }
      .email-promotion-content {
        .pc_email_promo_container {
          label {
            span {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .quick_registration {
    #new-account {
      .form-item {
        input[type='checkbox'] {
          margin-top: 6px;
        }
      }
    }
  }
  &#registration {
    #registration-wrapper {
      #registration {
        .profile {
          .title_container {
            width: 100%;
          }
        }
      }
    }
  }
  &#address_book {
    #container,
    #colorbox {
      .address_form_container {
        width: 100%;
        margin-bottom: 20px;
        .address {
          max-width: 100%;
          .form_element.title_container {
            width: 100%;
            .title_element {
              display: block;
              label {
                margin-right: 15px;
              }
            }
          }
          .form_element {
            width: 45%;
            input[type='radio'] {
              display: inline-block;
              margin-right: 5px;
            }
            input[type='text'] {
              width: 80%;
              height: 38px;
            }
            select {
              width: 92%;
              height: 38px;
            }
          }
          .address-form__state-container {
            clear: left;
            width: 25%;
          }
          .address-form__city-container,
          .address-form__district-container {
            width: 25%;
          }
          .postal_code_container {
            clear: both;
          }
          .phone_1_container {
            clear: none;
          }
          .address-form__delivery-range-container {
            width: 45%;
            padding-top: 50px;
            .label {
              margin-left: 35px;
            }
          }
          .default_ship_container {
            line-height: normal;
            margin: 0;
            .label-content {
              margin-left: 1em;
            }
          }
        }
        .main-button-wrapper {
          margin: 20px;
          text-align: right;
        }
      }
      #address_form_container {
        width: 95%;
        overflow: hidden;
        .form_element {
          input[type='text'] {
            width: 86%;
          }
        }
        .full_name_container,
        .address1_container {
          clear: left;
          width: 50%;
          input[type='text'] {
            width: 100%;
          }
        }
      }
    }
  }
  &#order_detail {
    .purchases {
      #product-container {
        .itemstatus {
          width: 120px;
          text-align: center;
        }
        .name {
          width: 400px !important;
        }
      }
    }
  }
  #newmyaccountsubnav {
    li.subnavitem {
      float: none;
      display: inline-block;
      text-align: center;
      width: 19%;
      a {
        float: none;
        display: inline-block;
        width: 100%;
        padding: 3px 0px 4px 0px;
      }
      &:last-child {
        a {
          border-right: none;
        }
      }
    }
  }
  #wrapper {
    .order-details {
      td {
        width: 25%;
      }
    }
    .logistics-details {
      border-color: $color-grey;
      border-style: solid;
      border-width: 1px 1px 2px 2px;
      width: 100%;
      margin-bottom: 10px;
      th {
        border: 1px solid $color-grey;
        padding: 15px 0px 15px 73px;
        font-size: 16px;
      }
      td {
        padding: 10px 20px;
      }
      .logistics-details-content {
        background-color: $color-primary-100;
        width: 100%;
        .date {
          width: 20%;
        }
        .weekday {
          width: 10%;
        }
      }
    }
  }
}

#colorbox {
  .sms_popup {
    .sms_popup_bind_h2 {
      padding-top: 1em;
    }
  }
}

#purchases {
  #product-container {
    .add-to-bag {
      .button {
        height: 30px;
        width: 60px;
        padding-top: 10px;
      }
    }
  }
}

#signin {
  #birthday_container {
    span {
      display: block;
    }
    .form-item {
      width: 25%;
      display: inline-block;
    }
  }
}
#birthday_container {
  .change-birth-text {
    margin: 7% 0 0 37%;
    color: $color-red;
  }
}
.sms_popup {
  text-align: center;
  .bd_phone {
    .mobile_bd_label {
      display: inline-block;
      width: 80px;
      text-align: left;
    }
  }
}

#print_order {
  .order_summary {
    td.order_summary_subheading {
      padding: 0px 10px 10px 0;
      p {
        font-family: arial;
        font-size: 13px;
        margin: 0px;
      }
    }
    .order_summary_row {
      td {
        font-family: arial;
        font-size: 13px;
        p {
          font-family: arial;
          font-size: 13px;
          padding: 5px 0;
          border-top: 1px dotted $color-lightgrey;
          margin: 0;
        }
      }
    }
    .summary_total_row {
      td {
        font-weight: bold;
        p {
          border-bottom: 1px solid $color-black;
          border-top: 1px solid $color-black;
        }
      }
    }
    td.discount_summary_detail {
      p {
        font-family: arial;
        font-size: 13px;
        padding: 5px 0;
        border-top: 1px dotted $color-lightgrey;
        margin: 0;
      }
    }
    .gift_summary_detail {
      p {
        font-family: arial;
        font-size: 13px;
        padding: 5px 0;
        margin: 0;
      }
    }
    .gift_summary_cost {
      font-family: arial;
      font-size: 13px;
      height: 43px;
      padding-top: 6px;
      p {
        font-family: arial;
        font-size: 13px;
        margin: 0;
      }
    }
  }
  #product-container {
    .products_total {
      padding: 20px 36px 20px 20px;
      text-align: right;
    }
  }
}
