.basic-carousel-formatter {
  position: relative;
  // padding: 0 70px;
  .content-block-large--video--copy-below .content-block-large__inner {
    position: static;
    margin-top: 8px;
  }
  .basic-carousel__slide .content-block-large__inner {
    padding: 0 10px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .content-block-large {
    &__image--foreground {
      display: none;
      @include breakpoint($portrait-up) {
        display: block;
      }
      img {
        width: 56%;
        max-width: 442px;
      }
    }
  }
  &--full-width {
    @include breakpoint($large-up) {
      position: relative;
      left: 50%;
      right: 50%;
      width: 100vw;
      margin: 0 -50.6vw;
    }
    .content-block-large {
      &__cta-wrap {
        margin-top: 0;
      }
      &__cta a {
        min-width: 120px;
        padding: 0 20px;
        @include breakpoint($portrait-up) {
          min-width: 0;
        }
      }
    }
    .font-color--light {
      color: $color-black;
      @include breakpoint($portrait-up) {
        color: $color-white;
      }
    }
  }
  .basic-carousel {
    .slick-dots {
      border-bottom: none;
      bottom: 40px;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      @include breakpoint($portrait-up) {
        bottom: 0;
        padding: 10px 0 10px;
      }
      li {
        border-bottom: none;
        color: $color-dark-grey;
        font-family: $font--helvetica-neue-regular;
        font-size: 15px;
        height: auto;
        margin: 0;
        padding: 0 5px;
        width: auto;
        @include breakpoint($portrait-up) {
          border-bottom: 1px solid $color-dark-grey;
          padding: 0 15px 8px;
          &:first-child {
            padding-#{$ldirection}: 0;
          }
          &:last-child {
            padding-#{$rdirection}: 0;
          }
          &.slick-active,
          &:hover {
            border-bottom: 3px solid $color-black;
            color: $color-black;
            padding-bottom: 6px;
          }
        }
        &.slick-active {
          button {
            &:before {
              background-color: $color-gray;
              color: $color-gray;
            }
          }
        }
      }
    }
    &-overlay {
      .slick-dots {
        @include breakpoint($portrait-up) {
          background-color: $transparent--white;
        }
      }
    }
  }
}
