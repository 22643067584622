.product-brief {
  &__container {
    padding: 10px;
    text-align: center;
    @include breakpoint($portrait-up) {
      padding: 20px 34px 0;
    }
  }
  /* Used to add spacing below product titles w/o affecting the equalheight calculations */
  &__product-title-spacer {
    height: 20px;
  }
  &__name,
  &__subline,
  &__description,
  &__subheading {
    text-align: $ldirection;
    .product-grid--sets & {
      text-align: center;
    }
  }
  &__name {
    @include h4;
    font-size: $product-title-font-size;
    font-weight: bold;
    a {
      color: $color-black;
    }
  }
  &__subheading {
    font-size: 14px;
    margin-top: 10px;
  }
  &__description {
    a {
      color: $color-black;
    }
  }
  &__subline {
    font-size: $product-subline-font-size;
  }
  &__price {
    font-size: 14px;
    display: inline-block;
  }
  &__sku-shade-swatch {
    display: inline-block;
    margin-#{$rdirection}: 0.5em;
    padding: 0.75em;
  }
  &__cta-container {
    @include pie-clearfix;
    padding-top: 10px;
    font-weight: bold;
    line-height: $button-line-height;
    &--left {
      float: $ldirection;
      text-align: $ldirection;
      width: 50%;
    }
    &--right {
      float: $ldirection;
      text-align: $rdirection;
      width: 50%;
    }
    .icon {
      line-height: 35px;
      margin-#{$ldirection}: 6px;
    }
  }
  &__cta {
    padding: 0 20px;
    padding-#{$rdirection}: 12px;
    .product-brief__price {
      margin-left: 1em;
      padding-left: 1em;
      border-left: 2px solid $color-white;
    }
  }
  // equal-height cta's
  &__cta-eh {
    padding-bottom: 8px;
    @include breakpoint($portrait-up) {
      padding-bottom: 0;
    }
  }
  &__quickshop-launch {
    font-size: 11px;
    line-height: 30px;
    padding: 0 12px;
  }
}
