/* Dropdown control */
.selectBox-dropdown {
  min-width: 150px;
  position: relative;
  border: 1px solid #000;
  text-decoration: none;
  text-align: left;
  color: #000;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: normal;
  .selectBox-label {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    // display: inline-block;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  .selectBox-arrow {
    position: absolute;
    top: 10px;
    right: 5px;
    width: 19px;
    height: 100%;
    @include icon('caret--down');
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  position: absolute;
  z-index: 99999;
  min-height: 1em;
  max-height: 200px;
  border: 1px solid #000;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
/* Options */
.selectBox-options {
  font-size: 14px;
  letter-spacing: normal;
  list-style: none;
  display: block;
  padding: 5px 0;
  margin: 0;
  cursor: pointer;
  overflow: auto;
  li {
    line-height: 25px;
    &:first-child {
      padding-top: 0;
    }
    a {
      display: block;
      color: #000;
      padding: 0px 10px;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        color: $color-pink;
      }
    }
  }
}
