.cyog {
  &-category {
    border-top: $black 1px solid;
    &:last-child {
      border-bottom: $black 1px solid;
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
      padding: 1em 0;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      @include icon('plus', after);
      &::after {
        float: right;
        right: 10px;
        position: relative;
      }
      &.open {
        @include icon('minus', after);
      }
    }
    &__product-container {
      display: none;
    }
  }
  &-product {
    position: relative;
    text-align: center;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      width: 182px;
      height: 500px;
      padding: 10px;
      margin: 20px 0;
      float: left;
      margin-left: 7px;
      border: 1px solid #b4d0e4;
    }
    &__thumb {
      @include breakpoint($medium-up) {
        width: 100px;
        height: 214px;
      }
    }
    &__title {
      font-weight: bold;
      @include breakpoint($medium-up) {
        height: 5.5em;
      }
    }
    &__price {
      margin-top: 1em;
    }
    &__selectbox-wrapper {
      @include breakpoint($medium-up) {
        position: absolute;
        bottom: 20px;
        left: 0;
        margin: 0 auto;
        width: 100%;
        font-size: 12px;
      }
    }
    &__selectbox {
      font-size: 12px;
      margin-top: 10px;
      margin: 10px auto 0;
      width: 100px;
      height: 2em;
      padding: 0.25em;
      border: solid 1px $black;
      background-color: $color-light-grey;
      text-transform: uppercase;
      cursor: pointer;
      &.selected {
        color: white;
        background-color: $black;
      }
    }
  }
  &_add_all_to_bag {
    &__button {
      margin: 15px auto 0 auto;
      bottom: 10px;
      display: block;
      width: 100%;
      height: 65px;
      font-size: 18px;
      padding: 0.75em;
      text-align: center;
      background-color: $black;
      cursor: pointer;
      display: none;
      @include breakpoint($medium-up) {
        position: relative;
        width: 150px;
        height: 35px;
        font-size: 12px;
        padding: 0;
      }
    }
    &__wrapper {
      background-color: $white;
      width: 100%;
      position: fixed;
      bottom: 0;
      padding-bottom: 10px;
      z-index: 9;
      @include breakpoint($medium-up) {
        position: relative;
        padding: 0;
        margin-top: 30px;
      }
    }
    &__loading {
      display: none;
      text-align: center;
    }
  }
}
