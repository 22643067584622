.p-w-r .pr-star-v4 {
  font-family: 'IcoMoon-regular';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  background: none;
  color: #00528b;
}

.pr-star-v4-100-filled,
.pr-star-v4-75-filled {
  background-image: none;
  &:before {
    content: '\ece2';
  }
}

.pr-star-v4-50-filled,
.pr-star-v4-25-filled {
  background-image: none;
  &:before {
    content: '\ece1';
  }
}

.pr-star-v4-0-filled {
  background-image: none;
  &:before {
    content: '\ece0';
  }
}
/* Mixin function for pr-button display */

@mixin pr-button-display {
  background: $color-black;
  color: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
}
/* start of main power review container */
#power_review_container {
  @include swap_direction(padding, 0 10px);
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__details,
#pr-reviewsnippet,
.search-wrapper__results,
.filter-carousel__content {
  .p-w-r {
    .product-grid-wrapper & {
      float: $ldirection;
      margin: 0 0 15px 0;
    }
    a {
      color: $color-blue;
      &:hover {
        color: $color-black;
        text-decoration: underline;
      }
    }
    span,
    p {
      color: $color-black;
      vertical-align: top;
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: 14px;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal {
        display: none;
      }
      &-table-cell {
        @include swap_direction(padding, 0);
      }
      &-helper-text {
        color: $color-black;
      }
      &-clear-all-radios {
        color: $color-blue;
        letter-spacing: 0;
        &:hover {
          color: $color-black;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-star-v4 {
        @include swap_direction(margin, 0 2px 0 0);
        @include svg-icon-inline-mask('star_v2.svg');
        padding: 3px;
        height: 20px;
        width: 21px;
        color: $color-black;
        background: $color-black;
        &-0-filled {
          @include svg-icon-inline-mask('star-outline_v2.svg');
        }
      }
      /* To Change Bar Graph */
      &-review-snapshot {
        .pr-ratings-histogram {
          &-barValue {
            background: $color-dark-blue;
          }
        }
        &-block {
          @include swap_direction(margin, 10px);
          border: 1px solid $color-dark-blue;
          min-height: auto;
          @include breakpoint($medium-down) {
            @include swap_direction(margin, 0);
            border-bottom: 1px dotted $color-grey;
            border-width: 0 0 1px 0;
            min-height: 90px;
          }
          dt {
            display: none;
          }
        }
      }
      &-accessible-btn {
        @include pr-button-display();
      }
      &-header-table {
        .pr-header-title {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          &.pr-snippet-compact {
            .pr-snippet-stars-reco-stars {
              .pr-snippet-stars-container {
                margin-top: 5px;
              }
            }
          }
          .pr-snippet-read-and-write {
            .spp__container & {
              @include breakpoint($portrait-up) {
                margin-top: 5px;
              }
              display: inline-block;
              a {
                text-transform: uppercase;
                text-decoration: underline;
                color: $color-black;
                font-weight: bold;
                padding: 0 7.5px;
              }
            }
            .pr-snippet-write-review-link {
              .spp-cross-sells__products &,
              .product-grid-wrapper &,
              .search-wrapper & {
                display: none;
              }
            }
          }
          &.pr-snippet-minimal {
            .pr-snippet-stars-container {
              @include breakpoint($portrait-up) {
                display: inline-block;
                margin-top: 5px;
              }
              display: block;
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-black;
        }
        &.pr-rd-display-tablet {
          .pr-review {
            border-bottom: 1px dotted $color-grey;
          }
          .pr-rd-flag-review-container {
            vertical-align: bottom;
          }
        }
        .pr-rd-description-text {
          color: $color-black;
        }
        .pr-rd-footer {
          @include swap_direction(padding, 0 0 10px 0);
        }
        .pr-rd-main-header {
          background: none;
          border-bottom: 1px dotted $color-grey;
          border-top: 1px solid $color-grey;
          h1 {
            display: none;
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        text-transform: uppercase;
        color: $color-black;
      }
      .pr-rating {
        &-number {
          border: 1px solid $color-dark-blue;
        }
        &-date {
          border: none;
        }
      }
      .title {
        color: $color-black;
        text-transform: capitalize;
      }
      .pr-dark {
        color: $color-black;
        font-weight: bold;
      }
    }
    a:focus,
    :focus {
      outline: none;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}

.p-w-r .pr-snippet-stars-png,
.p-w-r .pr-review-display,
.p-w-r .pr-review-snapshot {
  .pr-star-v4-100-filled,
  .pr-star-v4-75-filled,
  .pr-star-v4-50-filled,
  .pr-star-v4-25-filled,
  .pr-star-v4-0-filled {
    background-image: none;
  }
}
/* Spp Page Review Container */
.spp__container {
  .product-full--xl-image {
    &.product-full {
      padding-bottom: 0px;
    }
    .product-full__details {
      padding-left: 0px;
    }
  }
  .spp_customer_reviews {
    @include swap_direction(padding, 20px);
    border: 1px solid $color-grey;
    @include breakpoint($medium-down) {
      border: none;
    }
  }
  .spp_reviews {
    @include swap_direction(padding, 0 0 18px 0);
    @include swap_direction(margin, 0 0 10px 0);
    border-bottom: 1px dotted $color-grey;
    &_heading {
      float: $ldirection;
      h2 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &_button {
      text-align: #{$rdirection};
      input[type='submit'] {
        letter-spacing: 0px;
      }
    }
  }
  .product-full__details {
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-reco {
        display: none;
      }
      .pr-snippet-stars-reco-inline {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
  }
  #pr-reviewdisplay {
    .p-w-r {
      & :focus {
        outline: none;
        box-shadow: none;
      }
      .pr-review-snapshot-simple,
      .pr-snippet-standard,
      .pr-snippet-compact,
      .pr-snippet-minimal {
        @include swap_direction(margin, 5px 0);
        .pr-review-snapshot-snippets {
          .pr-snippet-read-and-write a {
            display: none;
          }
        }
        .pr-snippet-reco-to-friend-green {
          background: none;
          & circle {
            display: none;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
          .pr-reco-value {
            @include swap_direction(margin, 0 0 10px 15px);
            font-size: 35px;
            @include breakpoint($medium-down) {
              @include swap_direction(margin, 0);
              font-size: 28px;
            }
          }
        }
        .pr-reco-to-friend-message {
          font-size: 16px;
          line-height: 24px;
          @include breakpoint($medium-down) {
            @include swap_direction(padding, 0);
            width: 80%;
          }
        }
      }
      .pr-accordion-btn {
        letter-spacing: 0px;
        font-weight: bold;
      }
      .pr-review {
        border-bottom: 1px dotted $color-grey;
      }
      .pr-review-snapshot {
        position: relative;
        .pr-review-snapshot-content-block {
          @include swap_direction(margin, 0);
        }
        a.pr-snippet-write-review-link {
          display: none;
        }
      }
      .pr-review-snapshot-snippets {
        @include breakpoint($large-up) {
          position: relative;
        }
        .pr-snippet-stars-reco-reco {
          position: absolute;
          right: 56px;
          bottom: 94px;
          #{$ldirection}: 0;
          @include breakpoint($medium-up) {
            bottom: 105px;
            padding-top: 20px;
          }
          @include breakpoint($large-up) {
            #{$ldirection}: auto;
            top: 85px;
            bottom: auto;
            padding-top: 0;
          }
        }
      }
      .pr-snippet-reco-to-friend {
        max-width: 100%;
        div,
        span {
          display: block !important;
          @include breakpoint($medium-down) {
            display: inline !important;
          }
        }
      }
    }
    .pr-review-snapshot {
      &-block {
        .pr-review-snapshot-block-headline,
        .pr-snapshot-highlight {
          display: none;
        }
        .pr-snapshot-tag-def {
          text-align: center;
          @include breakpoint($medium-down) {
            text-align: left;
            display: inline-block;
          }
          &:first-of-type {
            padding-top: 0;
          }
        }
      }
      &.pr-snapshot-mobile {
        .pr-review-snapshot-simple {
          .pr-reco-value {
            font-weight: bold;
          }
        }
      }
      .pr-review-snapshot-snippets-headline {
        color: $color-black;
      }
      .pr-review-snapshot-block {
        &-cons {
          .pr-review-snapshot {
            &-tags {
              display: none;
            }
          }
        }
        &-pros {
          .pr-review-snapshot {
            &-block-headline {
              display: block;
              background-color: $color-white;
              text-align: center;
              @include breakpoint($medium-down) {
                text-align: left;
              }
            }
          }
          dt {
            display: block;
            background-color: $color-white;
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-review-headline {
        @include swap_direction(padding, 4px 0 0 0);
        color: $color-black;
        text-transform: capitalize;
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          width: 100%;
        }
        .pr-rd-to-top {
          padding-#{$ldirection}: 31%;
          text-align: center;
          @include breakpoint($medium-down) {
            text-align: center;
            padding: initial;
          }
        }
        .pr-rd-pagination-btn {
          text-transform: capitalize;
        }
      }
      .pr-flagging-radio {
        padding-#{$ldirection}: 20px;
      }
    }
    .pr-thumbs-icon {
      display: none;
    }
    .pr-helpful {
      &-count {
        color: $color-blue;
        &:hover {
          color: $color-black;
          text-decoration: underline;
        }
      }
      &-btn {
        @include swap_direction(padding, 0 5px);
        @include swap_direction(margin, 0 3px);
        border: 0;
        border-right: 1px solid $color-black;
        background: none;
        text-transform: capitalize;
      }
      &-yes {
        .pr-helpful-count {
          &:before {
            content: 'yes-';
            visibility: visible;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            content: 'no-';
            visibility: visible;
          }
        }
      }
      &-active {
        .pr-helpful-count {
          color: $color-medium-blue;
          &:hover {
            color: $color-medium-blue;
          }
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .form-group {
      &.pr-media_image-form-group,
      &.pr-media_videourl-form-group {
        label.pr-btn-fileinput {
          background-color: $color-black;
          width: 30% !important;
          @include breakpoint($medium-down) {
            width: 22% !important;
          }
          @include breakpoint(($medium-down) (orientation portrait)) {
            width: 40% !important;
          }
          span {
            color: $color-white;
            &:after {
              color: $color-white;
            }
          }
        }
        .form-group {
          input {
            display: none;
          }
        }
        .pr-btn-delete {
          background-color: $color-black;
          border-color: $color-black;
          padding: 8px 12px;
          span {
            float: none;
          }
        }
        .pr-media-preview {
          .pr-loading-indicator {
            margin: 8%;
          }
        }
      }
      #pr-pros-group {
        a {
          background-color: $color-black;
          color: $color-white;
          text-decoration: none;
          &:after {
            color: $color-white;
          }
        }
      }
      .form-control:focus {
        border-color: $color-grey;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    .pr-alert-container {
      display: block;
    }
  }
}

#pr-war-form {
  .pr-cons-form-group,
  .pr-bestuses-form-group,
  .pr-wasthisagift-form-group {
    display: none;
  }
}
/* ASK & ANSWER IMPLEMENTION */
.spp__container {
  &.show-details {
    .spp_customer_reviews {
      display: block;
    }
  }
}

.question-processed {
  @include breakpoint($portrait-up) {
    margin-top: 7px;
  }
  display: inline-block;
  margin-top: 5px;
  .p-w-r {
    .pr-qa-snippet-container {
      .pr-ask-question-cta,
      .pr-ask-question-subtext {
        display: none;
      }
      .pr-snippet-qa {
        @include breakpoint($portrait-up) {
          height: auto;
          padding-bottom: 0px;
        }
        display: table-cell;
        vertical-align: bottom;
        padding-bottom: 8px;
        height: 45px;
      }
    }
  }
  &.answer-count-zero {
    margin-top: 0px;
  }
  .pr-snippet-qa {
    .pr-snippet-qa-metrics {
      .pr-qa-large,
      .pr-answered-question-circle {
        display: none;
      }
      .pr-snippet-qa-answer-link {
        text-decoration: underline;
        color: $color-black;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &.pr-snippet-qa-empty {
      margin-bottom: 10px;
      .pr-ask-question-link {
        text-decoration: underline;
        color: $color-black;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 0px;
      }
    }
  }
}

#pr-question-form-link {
  @include swap_direction(padding, 12px 50px);
  background-color: $color-black;
  outline: none;
  font-size: 13px;
  color: $color-white;
  display: flex;
  float: $rdirection;
  border: none;
  text-transform: uppercase;
  font-family: $primary-font;
  letter-spacing: 0;
  border-radius: 0;
  .pr-qa-display-link {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: $color-white;
    letter-spacing: 0;
    &:hover {
      background-image: none;
      text-shadow: none;
    }
  }
}

.tabs-content {
  .p-w-r {
    .pr-qa-display {
      @include swap_direction(padding, 0);
      .pr-qa-sort-headline {
        @include breakpoint($portrait-up) {
          display: inline-block;
        }
        font-family: $primary-font;
        font-size: 20px;
        font-weight: 400;
        color: $color-black;
        text-align: $ldirection;
        float: none;
        display: inherit;
      }
      .pr-qa-display-headline {
        @include swap_direction(margin, 0);
        overflow: hidden;
        h1,
        .pr-header-required {
          display: none;
        }
        .pr-waqwaa {
          max-width: 100%;
          .pr-question-form {
            font-family: $primary-font;
            width: auto;
            max-width: 650px;
            margin: 0px auto;
            .pr-waq-legal-text {
              span {
                color: $color-black;
                line-height: 17px;
              }
              a {
                color: $color-text;
                text-decoration: underline;
              }
            }
          }
        }
      }
      &.pr-qa-display-tablet {
        .pr-qa-display-ask-question {
          padding-left: 0;
          float: none;
          width: auto;
          input[type='text'] {
            width: 60% !important;
          }
        }
        .pr-qa-display-answer-by-container {
          &.pr-qa-display-answer-by-container-unverified {
            margin-left: 0;
          }
          &.pr-qa-display-answer-by-container-expert {
            border: none;
          }
          .pr-qa-display-answer-icon {
            display: none;
          }
        }
      }
      .pr-subscript {
        font-size: 16px;
        color: $color-black;
      }
      .pr-qa-display-item-details {
        time {
          margin-left: 0;
          display: block;
          font-size: 16px;
          color: $color-black;
        }
      }
      .pr-qa-display-item {
        .pr-qa-display-item-icon {
          @include breakpoint($med-small-down) {
            @include swap_direction(margin, 10px 0 0 20px);
          }
          @include swap_direction(margin, 10px 0 0 30%);
          background-color: $color-black;
        }
      }
      .pr-cross-icon {
        display: none;
      }
      .pr-qa-display-ask-question {
        margin-top: 10px;
        margin-bottom: 0;
        overflow: hidden;
        font-family: $primary-font;
        .pr-qa-display-thankyou-headline {
          text-align: center;
          float: none;
        }
        #pr-question-form-content {
          clear: both;
        }
      }
      .pr-qa-display-search {
        @include breakpoint($portrait-up) {
          @include swap_direction(padding, 20px 0 10px);
        }
        @include swap_direction(padding, 10px 0);
        background-color: $color-white;
        form {
          label {
            display: none;
          }
          .pr-qa-display-searchbar {
            .pr-qa-search-empty,
            &:focus {
              @include breakpoint($portrait-up) {
                border-bottom: 1px solid $color-normal-grey;
                background: $color-white !important;
              }
              border: 0;
              background: $color-black !important;
              .pr-search-icon {
                path {
                  @include breakpoint($portrait-up) {
                    fill: $color-black;
                  }
                  fill: $color-white;
                }
              }
            }
            input {
              @include breakpoint($portrait-up) {
                @include swap_direction(padding, 0 45px 0 0);
                width: auto !important;
                float: $rdirection;
                border-width: 0 0 1px 0;
              }
              @include swap_direction(padding, 0 20px);
              width: 100%;
              border: 1px solid $color-normal-grey;
              color: $color-black;
              border-radius: 3px;
              font-family: $primary-font;
              line-height: 34px;
              &:active {
                @include breakpoint($portrait-up) {
                  border-width: 0 0 1px 0;
                  border-color: $color-normal-grey;
                  box-shadow: none;
                }
                & + button {
                  &.pr-qa-search-empty {
                    @include breakpoint($portrait-up) {
                      background: none !important;
                    }
                  }
                }
              }
              &::placeholder {
                @include breakpoint($portrait-up) {
                  color: $color-black;
                }
                font-size: $body-font-size;
                font-family: $primary-font;
                text-transform: uppercase;
              }
              &:focus {
                @include breakpoint($portrait-up) {
                  border-width: 0 0 1px 0;
                  border-color: $color-normal-grey;
                  box-shadow: none;
                }
                & + button {
                  &.pr-qa-search-empty {
                    background-color: $color-white;
                  }
                }
              }
              &:focusbutton {
                &.pr-qa-search-filled,
                &.pr-qa-search-empty {
                  background-color: $color-white;
                  border: 1px solid $color-grey;
                  border-left: 0;
                }
              }
            }
            button {
              @include breakpoint($portrait-up) {
                background-color: $color-white !important;
                border-width: 0 0 1px 0 !important;
                border-color: $color-grey !important;
              }
              &:active,
              &:focus {
                border: 1px solid $color-grey;
              }
              .pr-search-icon {
                svg {
                  path {
                    fill: $color-black;
                  }
                }
              }
            }
            input:focusbutton {
              border: 1px solid $color-grey;
              background-color: $color-white;
              .pr-search-icon {
                svg {
                  path {
                    fill: $color-black;
                  }
                }
              }
            }
          }
        }
        .pr-qa-display-clear-search-button {
          padding-top: 5px;
          color: $color-black;
          &:hover {
            color: $color-black;
          }
        }
        .pr-qa-display-search-no-results {
          @include swap_direction(padding, 40px 0);
          font-family: $primary-font;
        }
      }
      .pr-qa-display-answer {
        .pr-qa-display-answer-icon {
          display: none;
        }
        .pr-helpful-voting {
          .pr-helpful-active {
            border: 1px solid $color-black;
            background: $color-black !important;
            span {
              svg {
                path {
                  fill: $color-white;
                }
              }
            }
            .pr-helpful-count {
              color: $color-white;
            }
            &:hover {
              color: $color-white;
            }
          }
          .pr-thumbs-icon {
            svg {
              path {
                fill: $color-black;
              }
              &:hover {
                fill: $color-white;
              }
            }
          }
          .pr-helpful-btn {
            @include swap_direction(padding, 5px 10px 5px 5px);
            background-color: $color-white;
            min-width: auto;
            border: none;
            border-radius: 2px;
            &:hover {
              background: $color-black;
              outline: none;
              .pr-thumbs-icon {
                svg {
                  path {
                    fill: $color-white;
                  }
                }
              }
              .pr-helpful-count {
                color: $color-white;
              }
            }
            &.pr-helpful-yes {
              border-right: 1px solid $color-black;
            }
          }
        }
      }
      span {
        color: $color-black;
      }
      .pr-qa-display-answer-link-container {
        .pr-btn-answer {
          @include swap_direction(padding, 12px);
          @include swap_direction(margin, 0);
          border: 1px solid $color-black;
          color: $color-black;
          &:hover {
            color: $color-white;
            background: $color-black;
            .pr-qa-display-text {
              color: $color-white;
            }
          }
          &:focus {
            .pr-qa-display-text {
              text-shadow: none;
              background-image: none;
            }
          }
        }
        .pr-qa-display-text {
          color: $color-black;
          &:hover {
            color: inherit;
          }
        }
      }
      .pr-qa-display-question {
        @include swap_direction(margin, 25px 0);
        .pr-qa-display-questionlist {
          @include breakpoint($portrait-up) {
            clear: both;
            overflow: hidden;
            padding-bottom: 40px;
          }
          @include swap_direction(margin, 0 0 20px);
          border-bottom: 1px solid $color-grey;
          border-left: 0;
          font-family: $primary-font;
          .pr-qa-display-item {
            @include breakpoint($portrait-up) {
              margin-top: 0;
              width: 75%;
              float: $rdirection;
            }
            padding-left: 35px;
            margin-top: 75px;
            .pr-qa-display-item-icon {
              @include swap_direction(margin, 10px 0 0 20px);
              width: 35px;
              height: 35px;
              padding-top: 4px;
            }
            .pr-qa-display-item-text {
              padding-top: 13px;
              font-weight: bold;
              color: $color-black;
            }
            &-details {
              @include breakpoint($portrait-up) {
                position: static;
              }
              position: absolute;
              top: -55px;
              left: 8px;
            }
          }
          .pr-qa-display-answer-link-container {
            @include breakpoint($portrait-up) {
              padding-top: 0;
              width: 75%;
              float: $rdirection;
            }
            @include swap_direction(padding, 20px 0 0 35px);
            @include swap_direction(margin, 0);
            .pr-qa-display-text {
              letter-spacing: 0;
            }
          }
          .pr-qa-display-add-answer {
            @include breakpoint($portrait-up) {
              width: 75%;
              float: $rdirection;
            }
            padding-top: 20px;
            .pr-qa-display-form {
              padding-left: 34px;
              .pr-answer-form {
                .form-group {
                  label {
                    padding-bottom: 10px;
                  }
                  .form-control {
                    box-shadow: none;
                    border-radius: 0;
                    &::placeholder {
                      font-size: $body-font-size;
                    }
                  }
                }
              }
            }
          }
          .pr-qa-display-answer {
            @include breakpoint($large-up) {
              margin-left: 0;
              padding-left: 0 !important;
            }
            @include breakpoint($portrait-up) {
              clear: both;
              width: 71%;
              margin-bottom: 0;
              float: $rdirection;
              padding-left: 8px;
            }
            margin-left: 35px;
            margin-top: 20px;
            &-by-container {
              border-left: 1px solid $color-black;
              padding-left: 25px;
            }
          }
        }
      }
      .pr-qa-display-btn-text {
        color: $color-black;
        span {
          color: $color-black;
        }
      }
      .pr-caret-icon__line {
        stroke: $color-black;
      }
      .pr-qa-display-show-more-questions {
        background-color: $color-white;
        border: 1px solid $color-black;
        &:hover {
          background-color: $color-black;
          .pr-qa-display-btn-text {
            color: $color-white;
            span {
              color: $color-white;
            }
          }
          .pr-caret-icon__line {
            stroke: $color-white;
          }
        }
      }
      .pr-btn-show-more {
        &.pr-qa-display-link {
          display: none;
        }
      }
      .btn-group-radio-vertical {
        .pr-btn {
          &.active,
          &:hover {
            background-color: $color-black;
            color: $color-white;
            outline: none;
          }
        }
      }
      .pr-answer-form {
        .pr-header-required {
          display: none;
        }
        .pr-submit {
          .pr-accessible-btn {
            background-color: $color-black;
            border: 1px solid $color-black;
            color: $color-white;
            text-transform: uppercase;
            &:hover {
              background-color: $color-white;
              border: 1px solid $color-black;
              color: $color-black;
            }
          }
        }
      }
      .pr-question-form {
        .pr-header-required {
          display: none;
        }
        fieldset {
          font-family: $primary-font;
        }
        .pr-waq-legal-text {
          span {
            color: $color-black;
            line-height: 17px;
            font-size: 13px;
            a {
              color: $color-black;
              text-decoration: underline;
            }
          }
        }
        .pr-submit {
          float: $rdirection;
          .pr-accessible-btn {
            @include swap_direction(padding, 12px 50px);
            background-color: $color-black;
            border: 1px solid $color-black;
            color: $color-white;
            text-transform: uppercase;
            font-size: 14px;
            border-radius: 0;
            &:hover {
              background-color: $color-white;
              border: 1px solid $color-black;
              color: $color-black;
            }
          }
        }
      }
    }
  }
}

#pr-questiondisplay {
  display: none;
  .p-w-r {
    .pr-question-form {
      .form-group {
        .pr-control-label {
          padding-bottom: 10px;
        }
        .form-control {
          box-shadow: none;
          border-radius: 0;
          &::placeholder {
            font-size: $body-font-size;
          }
        }
      }
    }
  }
  .pr-qa-display-headline {
    .pr-qa-display-sort {
      @include breakpoint($portrait-up) {
        @include swap_direction(padding, 10px 0 25px);
        float: $rdirection;
        width: 100%;
      }
      @include swap_direction(padding, 10px 0);
      .pr-qa-sort-group {
        @include breakpoint($portrait-up) {
          @include swap_direction(padding, 0);
          float: $rdirection;
        }
        @include swap_direction(padding, 15px 0);
        text-align: center;
        position: relative;
        float: none;
        width: 255px;
        margin: 0 auto;
        label {
          display: inline-block;
          padding-right: 5px;
          font-family: $primary-font;
          &:after {
            content: ':';
          }
        }
        select::-ms-expand {
          display: none;
        }
        .pr-qa-sort {
          @include breakpoint($portrait-up) {
            width: 170px;
          }
          @include swap_direction(padding, 10px 20px);
          font-family: $primary-font;
          border-radius: 4px;
          height: auto;
        }
        &:after,
        &:before {
          @include breakpoint($portrait-up) {
            right: 28px;
            top: 20px;
          }
          content: '\E004';
          font-size: 10px;
          font-family: 'icons';
          color: #333;
          right: 35px;
          top: 34px;
          padding: 0 0 2px;
          border: 0;
          position: absolute;
          pointer-events: none;
        }
        &:before {
          @include breakpoint($portrait-up) {
            right: 18px;
            top: 10px;
          }
          content: '\E007';
          right: 25px;
          top: 24px;
        }
      }
    }
    .pr-qa-display-thankyou-headline {
      float: none;
      text-align: center;
      font-family: $primary-font;
    }
  }
  .pr-qa-display-clear-search-button {
    font-family: $primary-font;
  }
  .add-your-answer-button--hide {
    .pr-qa-display-answer-link-container {
      display: none;
    }
  }
}

.tabs-nav {
  @include swap_direction(margin, 10px 0 0);
  display: flex;
  align-items: center;
  justify-content: center;
  clear: both;
  li {
    @include swap_direction(padding, 15px 0px);
    width: 300px;
    font-size: 16px;
    text-align: center;
    color: $color-medium-gray;
    position: relative;
    font-family: $primary-font;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    @include breakpoint($portrait-up) {
      font-size: 22px;
      padding-bottom: 30px;
    }
    &.tab-active {
      color: $color-black;
      &:after {
        @include breakpoint($portrait-up) {
          border-width: 0 10px 10px;
        }
        content: '';
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 6px;
        border-color: $color-black transparent;
        right: 45%;
        bottom: 0;
      }
    }
  }
  @include breakpoint($medium-down) {
    border-bottom: 1px solid $color-black;
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      font-family: $primary-font;
      .pr-header {
        #pr-warning {
          margin-top: 20px;
        }
        .table-cell {
          @include swap_direction(padding, 0 0 15px);
        }
      }
      .pr-question-form {
        .form-group {
          &.pr-has-error {
            label {
              span {
                color: $color-red;
              }
            }
          }
          #questionCategory {
            .pr-btn {
              &.active {
                background-color: $color-black;
              }
            }
          }
          label {
            padding-bottom: 5px;
          }
        }
        .pr-submit {
          .pr-accessible-btn {
            @include swap_direction(padding, 12px 50px);
            border-radius: 0px;
          }
        }
      }
    }
  }
}

.product-full__cta-section--pc {
  clear: both;
}

.product-full__review-snippet {
  .p-w-r {
    .pr-review-snippet-container {
      display: inline-block;
      float: $ldirection;
    }
  }
}
