.product {
  &__image-wrapper {
    position: relative;
    .product-brief__quickshop-wrapper {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    &:hover {
      .product-brief__quickshop-wrapper {
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
  }
  &__image {
    &--med {
      min-height: 225px;
      max-width: 50%; // Fix missing images breaking the product grid on iOS
    }
    &--large {
      min-height: 225px;
      max-width: 100%;
    }
  }
}
