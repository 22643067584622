// Generic landing page layout styles

.blog-landing {
  font-family: Arial, Helvetica, sans-serif;
  &__item {
    margin-bottom: 14px;
    // width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__info {
    padding: 10px;
    @include breakpoint($portrait-up) {
      float: #{$ldirection};
      width: 35%;
      @include swap_direction(padding, 50px 20px 0 40px);
    }
  }
  &__section {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
    color: #0c5889;
  }
  &__title {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 32px;
  }
  &__subtitle {
    font-size: 23px;
    margin-bottom: 15px;
    line-height: 1em;
  }
  &__teaser {
    padding: 12px 0;
  }
  &__thumbnail {
    @include breakpoint($portrait-up) {
      float: #{$rdirection};
      width: 65%;
    }
    img {
      max-width: 100%;
    }
  }
  &__thumbnail--large {
    width: 100%;
  }
  &__read-more {
    // width: 156px;
    // height: 29px;
    background: $color-dark-blue;
    color: $white;
    font-size: 14px;
    // padding: 6px;
    text-transform: uppercase;
    @include icon('caret--right', after);
    &:hover {
      color: $white;
    }
  }
}
