.content-block-large.content-block-large--video {
  cursor: pointer;
  .content-block-large__image-trigger {
    position: absolute;
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 30px;
    color: $white;
    text-decoration: none;
  }
  &.content-block-large--video--copy-below {
    .content-block-large__image {
      position: relative;
      &-trigger {
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        position: absolute;
      }
    }
    .content-block-large__inner,
    .content-block-large__content {
      position: static;
      transform: none;
    }
  }
  .icon--dark {
    color: $black;
  }
  .content-block-large__youku {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}

// Colorbox overlay
.overlay--content-block-video {
  &#cboxOverlay {
    background: rgba($black, 0.5);
  }
  #cboxClose {
    #{$rdirection}: 0;
  }
  #cboxWrapper {
    background: transparent;
  }
  #cboxContent {
    margin: 0;
    background: transparent;
  }
  #cboxLoadedContent {
    background: transparent;
    padding: 35px 0;
    iframe {
      display: block;
    }
  }
}

.overlay--content-block-video--content {
  .embed-container {
    margin-bottom: 30px;
  }
  #cboxLoadedContent {
    background: $white;
    padding: 45px 70px;
  }
}
