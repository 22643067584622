.techicons-container {
  @include swap_direction(margin, 40px 0);
  h2.basic-carousel {
    &__title {
      margin-bottom: 8%;
      text-align: center;
      font-family: $font--helvetica-neue-regular;
      font-size: 12px;
      font-weight: bold;
      padding-top: 7%;
      @include breakpoint($portrait-up) {
        font-size: 20px;
      }
    }
  }
}

.tech-icons {
  text-align: center;
  &__main-header {
    font-family: $font--helvetica-neue-regular;
    font-size: 12px;
    @include swap_direction(margin, 10% 0);
  }
  &__header {
    font-family: $font--helvetica-neue-regular;
    font-size: 12px;
    margin-top: 12%;
    @include breakpoint($portrait-up) {
      font-size: 18px;
    }
    p {
      @include swap_direction(margin, 0);
    }
  }
  &__subheader {
    font-family: $font--helvetica-neue-light;
    font-size: 12px;
    width: 32%;
    @include swap_direction(margin, 0 auto 10%);
    @include breakpoint($portrait-up) {
      font-size: 18px;
      width: 60%;
    }
  }
}
