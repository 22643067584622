.ecommerce {
  &.section-about-skincare-basics {
    .header-large {
      color: $color-black;
      &__text {
        font-size: 28px;
        padding-top: 3px;
        span {
          color: $color-white !important;
        }
      }
      &--no-image {
        font-weight: bold;
      }
    }
    .site-content {
      .header-links {
        padding: 30px 0px;
        &__title {
          font-size: 36px;
          line-height: 1;
        }
      }
      .product-brief__price {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
  .site-content {
    .selectBox-dropdown {
      min-width: 250px;
      margin-top: 10px;
    }
    .selectBox-dropdown-menu {
      width: 225px !important;
    }
    .header-links {
      margin-bottom: 20px;
    }
    .product-brief__cta {
      padding: 0 20px;
    }
  }
  &.section-menforlabseries {
    background-color: $color-labseries-blue;
    .basic-formatter__item {
      padding-top: 0px;
    }
    .Bottom-carousel-menforlabseries {
      .content-block-large {
        &__inner {
          position: relative;
          top: -120px;
          font-size: 28px;
        }
        &__cta {
          background-color: $color-black;
          color: $color-white;
          a {
            color: $color-white;
            font-size: 14px;
          }
          &-wrap {
            margin-top: 17px;
          }
        }
      }
      .slick-prev {
        top: 74%;
        left: 98px;
        &:before {
          font-size: 25px;
          color: $color-slick-grey;
        }
      }
      .slick-next {
        top: 74%;
        margin-right: 98px;
        &:before {
          font-size: 25px;
          color: $color-slick-grey;
        }
      }
    }
  }
  .site-footer {
    background-color: $color-white;
  }
  &.section-customer-service-terms-conditions {
    .cs-page {
      &__content-item {
        .basic-responsive {
          p {
            line-height: 1.45em;
          }
        }
      }
    }
  }
}

.site-header {
  &__menu-list {
    .menu--lvl-1 {
      a[href='/gifts'] {
        color: $color-black !important;
      }
    }
  }
  &--sticky {
    .site-header__menu-list {
      .menu--lvl-1 {
        a[href='/gifts'] {
          color: $color-white !important;
        }
        a:hover {
          color: $color-black !important;
        }
      }
    }
  }
  .qr-js-code {
    text-align: center;
    img {
      width: 217px;
      height: 217px;
    }
  }
  .esearch-product {
    &__headline {
      min-height: auto;
    }
    &__subline {
      line-height: 1.3em;
    }
  }
  .site-email-signup__submit {
    margin-bottom: 0;
  }
  &__content {
    h3 {
      line-height: 1.5em;
      padding: 10px 0px 15px;
    }
  }
}

.store-locator {
  .store-locator__results-container {
    .store-locator__tooltip {
      .column {
        border-bottom: 2px solid $color-grey;
      }
    }
  }
}

#wo_chatbox {
  float: right;
  #wo_offline_image {
    bottom: 0px !important;
    position: fixed;
    width: auto !important;
    right: 0px;
  }
}
