// This file is generated by `gulp icons`, do not modify

$icons: (
  angle-left: '\E001',
  angle-right: '\E002',
  bag: '\E003',
  caret--down: '\E004',
  caret--left: '\E005',
  caret--right: '\E006',
  caret--up: '\E007',
  cart: '\E008',
  checkbox--checked: '\E009',
  checkbox: '\E00A',
  circle-caret--left: '\E00B',
  circle-caret--right: '\E00C',
  close: '\E00D',
  email: '\E00E',
  facebook: '\E00F',
  hamburger: '\E010',
  head--outline: '\E011',
  head--solid: '\E012',
  heart--filled: '\E013',
  heart--outline: '\E014',
  instagram: '\E015',
  livechat: '\E016',
  location--filled: '\E017',
  location: '\E018',
  logo: '\E019',
  map-marker: '\E01A',
  minus: '\E01B',
  pinterest--circle: '\E01C',
  pinterest: '\E01D',
  plus: '\E01E',
  radio--checked: '\E01F',
  radio: '\E020',
  search--left: '\E021',
  search: '\E022',
  stars: '\E023',
  twitter: '\E024',
  video-play: '\E025',
  youtube--play: '\E026',
  youtube: '\E027'
);
