.text-image-block {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  border: 1px solid $color-light-gray;
  border-width: 1px 0;
  flex-flow: column;
  @include breakpoint($portrait-up) {
    flex-flow: row;
  }
  &__image {
    flex: 0 1 100%;
    @include breakpoint($portrait-up) {
      flex: 1 1 auto;
      text-align: #{$rdirection};
    }
  }
  &__inner {
    flex: 0 1 100%;
    @include breakpoint($portrait-up) {
      flex: 0 1 auto;
      width: 60%;
    }
  }
  &__header {
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
  }
  &--flipped {
    .text-image-block__image {
      order: 0;
      text-align: center;
      margin-bottom: 10px;
      @include breakpoint($portrait-up) {
        margin-bottom: 0;
        text-align: #{$ldirection};
      }
    }
    .text-image-block__inner {
      order: 1;
    }
  }
}
