/**
 * Generic typeahead
 */
.typeahead-wrapper {
  .typeahead-suggestions__item {
    padding: 4px 0;
  }
  .esearch-product {
    &__price {
      margin: 0 10px;
    }
    &__headline {
      line-height: 1.3em;
      a {
        color: #333;
      }
    }
  }
  .typeahead-search__see-all-results {
  }
  @include breakpoint($medium-up) {
    .product-grid__content {
      border-top: 0;
    }
  }
  // @setup new brand - this can be removed
  // temp image FPO
  .endeca-test-image--small {
    margin: 0 auto;
  }
}

/**
 * Search form in page content - special positioning
 */
.site-content {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      z-index: 999;
      background-color: $color-white;
      border: $color-black solid 2px;
      padding: 1em;
    }
  }
}
