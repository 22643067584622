///
/// @file components/prouducts/_product_rating.scss
///
/// \brief Styles for product rating using stars as font icon
///
///

$stars_size: 100px !default; // same as font size
$stars_height: 30px !default; // add a border to the :after to test a new @setup

.product-rating {
  text-align: left;
  &__stars-wrapper {
    margin: 0 auto;
    position: relative;
    width: $stars_size;
  }
  &__stars {
    @include icon('stars', after);
    color: $color-gray;
    font-size: $stars_size;
    height: $stars_height;
    line-height: $stars_height;
    overflow: hidden;
    text-align: left;
    width: $stars_size;
    &:after {
      height: $stars_height;
      line-height: $stars_height;
    }
    &--off {
      position: relative;
    }
    &--on {
      color: $color-pink;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}
