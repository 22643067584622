.product__inventory-status-item {
  margin-bottom: 6px;
  color: $color-red;
  text-align: left;
}

.product-full {
  padding: 1em 0;
  position: relative;
  min-height: 510px;
  &__name {
    font-size: 24px;
    margin: 5px 0 0 0;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1em;
    @include breakpoint($medium-down) {
      text-align: #{$ldirection};
      // color: $color-dark-blue;
      font-size: 20px;
    }
  }
  &__subline {
    @include breakpoint($medium-down) {
      text-align: #{$ldirection};
      font-size: 12px;
    }
  }
  &__details {
    margin-#{$ldirection}: 50%;
    padding: 12px 0;
    @include breakpoint($medium-down) {
      margin: 0;
    }
  }
  &__estimated-delivery {
    display: none;
  }
  &__breadcrumb {
    width: 100%;
    background: #737373;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    @include breakpoint($medium-down) {
      background: none;
      text-align: #{$ldirection};
      border-bottom: 1px $color-grey solid;
      padding-#{$ldirection}: 20px;
    }
    &-link {
      &:before {
        @include breakpoint($medium-down) {
          content: ' > ';
        }
      }
      &:first-child:before {
        content: none;
      }
    }
    a {
      color: $white;
      @include breakpoint($medium-down) {
        color: $black;
      }
    }
  }
  &__image {
    position: absolute;
    width: 50%;
    top: 1em;
    text-align: center;
    width: 300px;
    margin: 20px 50px 0 50px;
    @include breakpoint($medium-down) {
      margin: 20px 0;
      position: relative;
      width: 100%;
      img {
        //width: 120px;
        max-width: 100%;
        height: 250px;
      }
    }
  }
  &__carousel {
    &__thumbnail {
      cursor: pointer;
      width: 55px;
      margin: 0 7px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__description {
    padding-top: 10px;
  }
  &__section {
    border-top: $black 1px solid;
    &--iln {
      display: none;
    }
    &.last_section {
      border-bottom: $black 1px solid;
    }
    &--text {
      display: none;
      font-size: 12px;
      line-height: 15px;
      padding: 0 40px 15px 10px;
    }
    &--title {
      cursor: pointer;
      text-transform: uppercase;
      padding: 10px;
      @include breakpoint($medium-down) {
        padding: 10px 30px 10px 10px;
      }
      @include icon('plus', before);
      &:before {
        display: inline-block;
        font-family: 'icons';
        font-size: 12px;
        height: 100%;
        position: absolute;
        right: 10px;
        font-weight: normal;
        text-rendering: optimizelegibility;
      }
    }
    &.active &--title {
      @include icon('minus', before);
    }
    &.active &--text {
      display: block;
    }
    &.unexpandable &--title:before {
      content: none;
    }
  }
  &__ingredients-label {
    cursor: pointer;
  }
  &__cta-section--mobile {
    text-align: left;
    .product-full__installment_price {
      float: $ldirection;
      margin-top: 10px;
      width: 100%;
    }
  }
  .product-size {
    margin: 0.5em 0;
    @include breakpoint($medium-up) {
      clear: both;
      float: $ldirection;
    }
  }
  .product-sku-size {
    font-size: 16px;
    display: inline-block;
  }
  .product-replenishment__header {
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 0.25em;
  }
  .product-replenishment-select {
    margin-bottom: 20px; // 1em;
    &__select {
      width: 180px;
      @include breakpoint($medium-down) {
        width: 90%;
      }
    }
  }
  .replenishment__description--link {
    cursor: pointer;
  }
  .replenishment-overlay {
    padding: 20px;
    width: 600px;
    &__title {
      color: #025084;
      text-transform: uppercase;
      margin-bottom: 12px;
      font-weight: bold;
    }
  }
  .sku-menu__container {
    margin: 1em 0;
  }
  &__btn__container {
    @include clearfix;
  }
  .product__inventory-status-container {
    clear: both;
    @include breakpoint($medium-up) {
      margin: 10px;
    }
    @include breakpoint($large-up) {
      clear: none;
    }
  }
  .afterpay-link {
    width: 15px;
    height: 15px;
    background: $black;
    border-radius: 50%;
    text-align: center;
    padding-right: 1px;
    font-size: 9px;
    font-weight: 400;
    text-transform: lowercase;
    text-decoration: none !important;
    color: $white !important;
    line-height: 1.56;
    vertical-align: text-bottom;
  }
  .product-sku-price {
    display: inline-block;
    margin-right: 1em;
    padding-right: 1em;
    line-height: 1em;
  }
  .product__button--add-to-bag,
  .product__button--loading {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 1em 1.25em;
    font-size: 16px;
    line-height: 1em;
    text-align: center;
    @include breakpoint($medium-up) {
      display: block;
      float: $ldirection;
    }
    .product-sku-price {
      margin-right: 0;
      margin-left: 1em;
      padding-right: 0;
      padding-left: 1em;
      border-left: 2px solid $color-white;
    }
  }
  &__spp-tabs {
    display: block;
    float: $ldirection;
    list-style-type: none;
    padding: 0;
    width: 320px;
    margin: 15px 0 20px 0;
    @include breakpoint($medium-up) {
      display: none;
    }

    // Disabling tabs on spp's
    display: none;
    li {
      float: $ldirection;
      background-color: #eae7e7;
      border: 1px solid #459ac9;
      color: #000;
      display: block;
      font-size: 12px;
      padding: 5px 20px;
      cursor: pointer;
      font-weight: bold;
      font-size: 12px;
      width: 106px;
      text-align: center;
      &.selected {
        background-color: #459ac9;
        color: #fff;
        border-bottom-width: 0;
        padding-bottom: 7px;
        cursor: default;
        position: relative;
        &:after {
          content: url(/media/images/icons/blue_tabs_bottom_arrow.png);
          display: block;
          height: 0;
          line-height: 0;
          position: absolute;
          bottom: 0;
          #{$ldirection}: 1px;
          top: 36px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  &__carousel {
    &__thumbnails {
      max-width: 276px;
      margin: 0 auto;
      padding: 12px 0 0 0;
      display: none;
      @include breakpoint($large-up) {
        display: inline-block;
      }
      .thumbs-track {
        // When the number of images is lesser than four, the thumbnail images are aligned to the left of the page,
        // To make it center aligned with respect to the carousel, '!imporatnt' is used here.
        width: auto !important;
      }
    }
    &__thumbnail {
      width: auto;
      img {
        width: 55px;
      }
    }
  }
  &--xl-image {
    min-height: 582px;
    .product-full {
      &__image {
        @include breakpoint($large-up) {
          width: 50%;
          margin: 0;
        }
      }
      &__carousel__slide,
      &__image--single {
        img {
          width: 100%;
          height: auto;
          max-width: 470px;
        }
      }
      &__details {
        padding: 12px 0 0 12px;
      }
    }
    &.product-full {
      padding: 1.5em 0;
    }
  }
  &__review-snippet {
    .p-w-r {
      margin: 0;
    }
  }
}

.spp-cross-sells {
  &__header {
    font-size: 30px;
    font-weight: bold;
    @include swap_direction(padding, 25px 0 0 50px);
    text-transform: uppercase;
    @include breakpoint($medium-down) {
      font-size: 22px;
      padding: 0;
    }
  }
  .product-brief__name a {
    color: black;
    font-size: 17px;
    text-transform: uppercase;
    height: 72px;
    display: block;
    font-weight: bold;
  }
  .product-brief__subline {
    display: none;
  }
  .product__inventory-status {
    float: $rdirection;
    clear: none;
  }
  .product-brief__quickshop-launch {
    width: 98px;
    height: 28px;
  }
}
