.loading {
  background: url(/sites/esteelauder/themes/estee_base/img/ajax-loading.gif) no-repeat center center;
  * {
    opacity: 0.8;
  }
}
$color-lightblue: #00427f;
$color-darkblue: #040a2b;
$color-grey-border: #ccc;
$color-grey-background: #eee;

.checkout {
  &.viewcart {
    #viewcart-panel {
      .viewcart-buttons {
        width: 100%;
        float: left;
        margin: 25px 0px;
        padding-top: 25px;
        .continue-buttons {
          text-align: right;
          .left {
            display: inline-block;
            font-size: 17px;
            float: left;
            border-bottom: none;
            padding: 7px 0px 10px 0px;
            font-weight: bold;
          }
          .continue_shopping {
            display: inline-block;
            color: $color-black;
            font-weight: bold;
            margin-top: 0px;
            padding: 10px 15px;
            border: 1px solid $color-black;
            &:hover {
              text-decoration: none;
            }
          }
          .or-text {
            padding: 0px 30px;
          }
          .continue-checkout {
            display: inline-block;
            background: $color-lightblue url(/media/images/global/white_right_arrow.png) no-repeat scroll right center;
            color: $color-white;
            font-weight: bold;
            padding: 10px 31px 10px 15px;
          }
        }
      }
      .bottom-viewcart-buttons {
        padding-bottom: 20px;
        border-bottom: 1px solid $color-grey;
      }
      &.empty {
        .viewcart-buttons {
          margin: 25px 0px;
          padding-top: 25px;
          border-top: 1px solid $color-grey;
        }
      }
    }
    .bottom_contents {
      float: left;
      margin-bottom: 20px;
      margin-top: 50px;
      width: 100%;
      .recommended_products,
      .past_purchases {
        .recommended-products__list,
        .past-purchases__list {
          li {
            min-height: 310px;
            float: left;
            position: relative;
            padding: 10px 12px;
            width: 156px;
            border: 2px solid $color-grey;
            margin-right: 20px;
            .product_image {
              display: block;
              text-align: center;
            }
            .item_info {
              text-align: center;
              .add_button {
                bottom: 10px;
                position: absolute;
                left: 25px;
              }
              .subline,
              .subhead {
                a {
                  color: $color-black;
                }
              }
            }
          }
        }
      }
      .titles {
        td {
          border: 1px solid $color-black;
          border-bottom: none;
          padding: 5px 20px;
          color: $color-darkblue;
          &.selected {
            background-color: $color-grey;
          }
        }
      }
      .content {
        border-top: 1px solid $color-black;
        padding-top: 60px;
      }
    }
    .left.checkout__content {
      width: 755px;
      float: left;
      .viewcart-panel {
        &__content {
          .cart-items {
            clear: both;
            border: 1px solid $color-grey;
            float: left;
            width: 100%;
            .cart-header {
              border-bottom: 1px solid $color-grey;
              div {
                float: left;
                margin: 10px;
                font-weight: bold;
              }
              .products {
                width: 47%;
                padding-left: 6%;
              }
              .qty {
                width: 13%;
              }
              .price {
                width: 13%;
              }
              .total {
                width: 13%;
              }
            }
            .cart-item {
              &__thumb {
                width: 17%;
                text-align: center;
              }
              &__desc {
                width: 34%;
                .cart-item__price {
                  display: none;
                }
                .product_name,
                .product_subname {
                  a {
                    color: $color-black;
                  }
                }
              }
              &__qty {
                width: 14%;
                &-label {
                  display: none;
                }
              }
              &__price {
                width: 15%;
                &-label {
                  display: none;
                }
              }
              &__total {
                width: 9%;
              }
            }
            .samples-panel {
              border-bottom: 1px solid $color-grey;
              h2 {
                margin: 1em;
              }
              .sample-products {
                .sample-li-wrapper {
                  display: inline-block;
                  width: 44%;
                  padding: 10px 0px;
                  margin: 5px 15px;
                  text-align: center;
                  input[type='checkbox'] {
                    display: none;
                  }
                  .product {
                    label {
                      span {
                        display: block;
                      }
                    }
                  }
                  &.selected {
                    outline: 1px solid $color-grey;
                  }
                  .sample-select-button {
                    display: none;
                  }
                }
              }
            }
            .cart-items-bottom {
              .right {
                float: right;
                padding: 10px;
                margin-top: 30px;
                .order_head {
                  display: none;
                }
              }
              .offer-code {
                float: left;
                #offer-code-panel {
                  #offer-code {
                    display: block;
                    #one-offer-only {
                      margin: 0px;
                    }
                    input[type='text'] {
                      width: 155px;
                      height: 36px;
                      margin: 0 15px;
                    }
                    input[type='submit'] {
                      height: 36px;
                      padding-top: 4px;
                    }
                  }
                }
              }
            }
            .cart-items-empty {
              padding: 1em;
              color: $color-red;
              font-size: 20px;
              border-bottom: 1px solid $color-grey;
            }
          }
        }
      }
      .promo-panel {
        .signin-to-see-cart {
          margin-top: 25px;
        }
      }
    }
    .right.checkout__sidebar {
      max-width: 180px;
      float: right;
      margin-top: 20px;
      .customer-service {
        border: 1px solid $color-grey;
        margin-top: 18px;
        h3 {
          padding: 10px;
          border-bottom: 1px solid $color-grey;
        }
        .every_block {
          margin: 5px 10px;
        }
      }
      #order-summary-panel {
        border: 1px solid $color-grey;
        margin-top: 20px;
        #order-status {
          display: block;
          .order-summary-panel__title {
            padding: 10px;
            border-bottom: 1px solid $color-grey;
            margin-bottom: 5px;
          }
          span {
            margin-left: 10px;
          }
          #status-table.order-summary__content {
            margin: 5px 10px;
            .order-summary__table {
              th,
              td {
                border-top: none;
                &.spacer {
                  border-top: 1px solid $color-grey-border;
                }
              }
            }
          }
        }
      }
      #guarantee-panel {
        border: 1px solid $color-grey;
        margin-top: 20px;
        .bz_detail {
          padding: 10px;
          .dash_bottom {
            border-bottom: 1px solid $color-grey;
            margin-bottom: 5px;
          }
          .ems_fedex {
            width: 100%;
          }
        }
      }
      #links-panel {
        border: 1px solid $color-grey;
        margin-top: 20px;
        .links-panel__title {
          padding: 10px;
          border-bottom: 1px solid $color-grey;
          margin-bottom: 5px;
        }
        .links_list {
          padding: 0px 10px;
          li {
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }
  &#viewcart {
    .footer {
      clear: both;
    }
    #viewcart_banner {
      display: none;
    }
    .samples-overlay {
      .samples-panel {
        h2 {
          margin: 1em;
        }
        .sample-products {
          .sample-li-wrapper {
            display: inline-block;
            width: 44%;
            padding: 10px 0px;
            margin: 5px 15px;
            text-align: center;
            input[type='checkbox'] {
              display: none;
            }
            .product {
              label {
                span {
                  display: block;
                }
              }
              img {
                width: auto;
              }
            }
            &.selected {
              background-color: $color-grey;
              outline: 1px solid $black;
            }
            .sample-select-button {
              background: $color-black;
              color: $color-white;
              font-size: 14px;
              letter-spacing: 2.5px;
              padding: 0 11px;
              display: inline-block;
              line-height: 30px;
              margin: 10px;
              cursor: pointer;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        .sample_continue {
          float: right;
          margin: 20px;
        }
      }
    }
    .messages {
      margin-top: 1em;
      margin-left: 0px;
    }
  }
  #colorbox.generic-helplinks-overlay {
    .cs-page__content {
      width: 100%;
    }
    .sidebar-page__content {
      width: 100%;
      padding: 1em;
    }
    .cs-page__navigation {
      display: none;
    }
  }
  .messages {
    padding: 0px;
    background-color: $color-white;
    border-bottom: none;
    color: $color-red;
    margin-left: 1em;
  }
  &#index {
    .left.checkout__content {
      width: 755px;
      float: left;
      .checkout__panel-title {
        padding: 10px;
        background-color: $color-grey-background;
        font-weight: bold;
        margin-bottom: 0px;
        a {
          text-decoration: underline;
        }
      }
      .active {
        .checkout__panel-title {
          background-color: $color-grey;
          border: 1px solid $color-black;
          margin-bottom: 1em;
        }
      }
      .finished {
        .content {
          opacity: 0.5;
        }
        &.panel {
          margin-top: 20px;
        }
      }
      #sign-in-panel {
        display: inline-block;
        border: 1px solid $color-grey;
        margin-bottom: 10px;
        width: 100%;
        .signin_container {
          padding: 1em;
          float: left;
          .left {
            width: 48%;
            display: inline-block;
            padding-right: 20px;
            .checkout_signin {
              padding-bottom: 40px;
              border-bottom: 1px solid $color-grey;
              #checkout_signin {
                margin-bottom: 15px;
                #forgot_pw_note {
                  margin-bottom: 0px;
                }
              }
              &_guest_user {
                padding-top: 35px;
              }
            }
          }
          .right {
            width: 48%;
            display: inline-block;
            vertical-align: top;
            #checkout_signin_new_user {
              .sign-in-component__show-password-wrapper {
                margin-bottom: 10px;
                input[type='checkbox'] {
                  vertical-align: middle;
                }
                label {
                  margin-top: 0px;
                }
              }
              .birthday-container {
                select {
                  width: 20%;
                  margin-right: 0.5em;
                }
              }
            }
          }
          input[type='text'],
          input[type='password'],
          input[type='email'] {
            margin: 5px 0px 10px 0px;
            height: 38px;
          }
          input[type='submit'] {
            background: $color-lightblue url(/media/images/global/white_right_arrow.png) no-repeat scroll right center;
            color: $color-white;
            font-weight: bold;
            padding-right: 30px;
            margin-top: 15px;
          }
          .text--checkbox-label {
            margin-top: 10px;
            label.label {
              width: 93%;
              span {
                margin-left: 0.5em;
              }
            }
          }
          input[type='checkbox'] {
            vertical-align: top;
          }
          .sign-in-component {
            &__header {
              margin: 0 0 24px;
              font-size: 30px;
            }
          }
        }
      }
      #shipping-page-info-panel {
        border: 1px solid $color-grey;
        margin-bottom: 10px;
        .content {
          margin: 1em;
        }
        #address_form_container {
          fieldset {
            max-width: 100%;
            .form-item {
              width: 49%;
              &.right {
                padding-left: 20px;
              }
              input[type='text'],
              select {
                height: 38px;
                margin-top: 3px;
              }
              input[type='radio'] {
                width: 13px;
                vertical-align: middle;
                margin-right: 3px;
              }
            }
            .address-form__title-container {
              label {
                margin-right: 10px;
              }
            }
            .address-form__city-container,
            .address-form__state-container,
            .address-form__district-container {
              width: 32%;
            }
          }
        }
        #delivery-info-panel {
          margin: 15px 0px;
          select {
            height: 38px;
            margin-bottom: 5px;
          }
          .left {
            float: left;
            width: 49%;
            margin-bottom: 20px;
            .something {
              width: 90%;
            }
          }
          .right {
            float: right;
            width: 49%;
            margin-bottom: 20px;
            textarea {
              width: 90%;
              height: 38px;
            }
          }
        }
        .others {
          label {
            span.label-content {
              margin-left: 1em;
              margin-bottom: 5px;
            }
          }
        }
        #emailpromo-info-panel {
          table {
            width: 100%;
          }
          fieldset {
            max-width: 100%;
            .form_element {
              label {
                span.label-content {
                  margin-left: 1em;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
        .shipping-edit-content {
          .others {
            border-top: 1px solid $color-grey;
            border-bottom: 1px solid $color-grey;
            display: inline-block;
            width: 100%;
            .left {
              float: left;
              width: 49%;
              margin-bottom: 20px;
            }
            .right {
              float: right;
              width: 49%;
              margin-bottom: 20px;
            }
            .form_element {
              margin-bottom: 10px;
            }
            input[type='text'] {
              height: 38px;
              margin: 3px 0px 5px 0px;
              display: block;
            }
            .form-item {
              width: 100%;
              textarea {
                height: 75px;
                width: 80%;
                margin: 3px 0px 5px 0px;
                display: block;
              }
            }
          }
          #continue-checkout-btm {
            margin-top: 20px;
            text-align: right;
          }
        }
        #shipping-address-display {
          .order_detail_link {
            padding: 10px;
            .right {
              float: right;
              text-decoration: underline;
            }
          }
          .row {
            border-top: 1px dashed $color-grey;
            margin-top: 10px;
            .col-6 {
              float: left;
              width: 50%;
              padding: 5px 0;
            }
          }
        }
      }
      #shipping-info-panel {
        .button_links {
          text-align: right;
        }
        .address_controls {
          padding-top: 10px;
          border-bottom: 1px solid $color-grey;
          .radio.field {
            width: 100%;
            float: left;
            margin-bottom: 10px;
            input[type='radio'] {
              float: left;
              margin-right: 5px;
              margin-top: 3px;
            }
          }
          span {
            float: left;
            ul {
              float: left;
            }
            li {
              float: left;
              border-bottom: none;
              padding: 0px;
            }
          }
        }
      }
      #review-panel {
        border: 1px solid $color-grey;
        margin-bottom: 10px;
        #payment-info-panel {
          #payment-container {
            #payment_tab {
              li {
                margin: 0;
                width: 50%;
                float: left;
                border: 1px solid $color-grey;
                padding: 0.8em 0;
                text-align: center;
                font-size: 1.2em;
                cursor: pointer;
                &.selected {
                  border: 1px solid $color-black;
                  border-bottom: none;
                  & + li {
                    border-left: none;
                  }
                }
                &:last-child {
                  width: 34%;
                }
              }
            }
            #selected_box {
              width: 100%;
              clear: both;
              padding: 5px;
              border-left: 1px solid $color-grey;
              border-top: none;
              li {
                margin-top: 0px !important;
                padding-bottom: 0px !important;
              }
              .payone {
                margin: 20px 30px;
              }
              .paytwo {
                clear: both;
                margin-left: 40px;
                margin-bottom: 40px;
                .payt_left,
                .payt_right {
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 15px;
                  input[type='radio'] {
                    vertical-align: top;
                  }
                }
                .payt_left {
                  width: 53%;
                }
                .payt_right {
                  width: auto;
                }
                label {
                  float: none;
                  display: inline-block;
                  vertical-align: bottom;
                }
                .use_pc {
                  float: left;
                  max-width: 175px;
                  margin-left: 10px;
                }
                .use_img {
                  float: left;
                }
              }
              #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_chinapay {
                margin-left: 40px;
              }
              #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_wechatpay {
                margin-bottom: 50px;
              }
              #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_chinapay_label {
                margin-left: 10px;
                .use_img {
                  display: inline-block;
                  padding-bottom: 30px;
                }
              }
              #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_cod {
                margin: 25px 10px 0px 40px;
              }
              #tab_header_bank {
                margin: 20px !important;
              }
              #banks {
                margin-top: 0px !important;
                input[type='radio'] {
                  vertical-align: top;
                  margin-top: 15px;
                }
                li {
                  padding-bottom: 35px !important;
                  float: left;
                }
              }
              .submit.right {
                float: right;
                margin: 10px;
              }
            }
            .sms_code_container {
              padding: 1em;
              .form_element {
                margin-bottom: 1em;
                input[type='text'] {
                  height: 38px;
                  margin-right: 15px;
                }
                input[type='button'] {
                  background: $color-black;
                  color: $color-white;
                  border: 0;
                  font-weight: bold;
                  padding: 12px 12px 10px 12px;
                }
                input[type='checkbox'] {
                  vertical-align: middle;
                }
              }
              .verify_code_container {
                .error {
                  background: url(/media/export/cms/account/sms/wrong_icon.png) no-repeat scroll 96% center;
                  background-size: 15px 15px;
                }
                .success {
                  background: url(/media/export/cms/account/sms/right_icon.png) no-repeat scroll 96% center;
                  background-size: 15px 15px;
                }
              }
            }
          }
        }
      }
      #review_viewcart_panel {
        .content {
          padding: 1em;
        }
        margin-bottom: 10px;
        border: 1px solid $color-grey;
        .cart-items {
          clear: both;
          border: 1px solid $color-grey;
          float: left;
          width: 100%;
          .cart-header {
            border-bottom: 1px solid $color-grey;
            div {
              float: left;
              margin: 10px;
              font-weight: bold;
            }
            .products {
              width: 47%;
              padding-left: 6%;
            }
            .qty {
              width: 13%;
            }
            .price {
              width: 13%;
            }
            .total {
              width: 13%;
            }
          }
          .cart-item {
            &__thumb {
              width: 17%;
              text-align: center;
            }
            &__desc {
              width: 34%;
              .cart-item__price {
                display: none;
              }
            }
            &__qty {
              width: 14%;
              &-label {
                display: none;
              }
            }
            &__price {
              width: 15%;
              &-label {
                display: none;
              }
            }
            &__total {
              width: 9%;
            }
          }
          .review_viewcart_subtotal {
            float: right;
            padding: 10px;
          }
        }
      }
    }
    .site-footer {
      clear: both;
    }
    .pg_wrapper {
      width: 960px;
      margin: 0 auto;
      padding: 0;
    }
    #colorbox {
      .address_form_container {
        h2 {
          border-bottom: 1px solid $color-grey;
          padding-bottom: 15px;
        }
        .address {
          max-width: 100%;
          .form_element.title_container {
            width: 100%;
            .title_element {
              display: block;
              label {
                margin-right: 15px;
              }
            }
          }
          .form_element {
            width: 45%;
            margin-bottom: 15px;
            input[type='radio'] {
              display: inline-block;
              margin-right: 5px;
              width: 15px;
              vertical-align: middle;
            }
            input[type='text'] {
              width: 100%;
              height: 38px;
              display: block;
              margin-top: 5px;
            }
            select {
              width: 100%;
              height: 38px;
              margin-top: 5px;
            }
          }
          .full_name_container,
          .address1_container {
            clear: left;
          }
          .address-form__state-container {
            clear: left;
            width: 21%;
          }
          .address-form__city-container,
          .address-form__district-container {
            width: 21%;
          }
          .postal_code_container {
            clear: both;
          }
          .phone_1_container {
            clear: none;
          }
          .country_container {
            display: none;
          }
          .default_ship_container {
            .label-content {
              margin-left: 1em;
            }
          }
          .phone_label_container {
            width: 100%;
          }
          .address-form__delivery-range-container {
            margin-top: 30px;
          }
        }
        .main-button-wrapper {
          margin: 20px;
          text-align: right;
        }
      }
      #address_form_container {
        fieldset.address {
          .form_element {
            label,
            span.required_mark {
              display: inline-block;
            }
          }
          .phone_label_container,
          .ship_type_container,
          .address3_container,
          .phone_2_container {
            display: inline-block;
          }
        }
      }
    }
  }
  .need-help-experts {
    .need-help-title {
      background-position: -4px -6px;
      height: 60px;
      width: 160px;
    }
    .need-help-chat-email,
    .need-help-phone {
      display: none;
    }
  }
  #status-table {
    td.price {
      padding-left: 10px;
      text-align: left;
    }
    .spacer {
      border-top: 1px solid $color-grey-border;
      margin-top: 8px;
      padding-bottom: 8px;
    }
  }
}

#confirm {
  #confirmation-number,
  #confirmation-email {
    font-size: 13px;
  }
}
/* 3step Indicator */
.confirmation-content {
  padding-top: 156px;
}

.checkout-progress-bar {
  float: left;
  width: 755px;
  @include swap_direction(margin, 30px 0 10px 0);
  &__list {
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 0 20px;
    &-item {
      position: relative;
      display: table-cell;
      padding-top: 12px;
      border-top: 2px solid $color-grey-border;
      color: $color-grey-border;
      text-align: center;
      white-space: nowrap;
      a {
        text-decoration: none;
      }
      &:before {
        position: absolute;
        top: -8px;
        left: 50%;
        width: 14px;
        height: 14px;
        margin-#{$ldirection}: -8px;
        background-color: $color-grey-border;
        border: 1px solid $color-grey-border;
        border-radius: 8px;
        content: ' ';
        line-height: 14px;
      }
      &--shipping {
        text-align: left;
        &:before {
          left: 26px;
        }
      }
      &--review {
        text-align: right;
        &:before {
          left: auto;
          right: 12px;
        }
      }
      &--complete {
        color: $color-black;
        a {
          color: $color-black;
        }
        &:before {
          background-color: $color-black;
        }
      }
    }
  }
}
/* End of 3step Indicator */
