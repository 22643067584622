// Article styles

.blog-post {
  font-family: Arial, Helvetica, sans-serif;
  @include breakpoint($medium-up) {
    width: 900px;
  }
  &__title {
    font-size: 45px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 40px;
    padding-#{$ldirection}: 30px;
  }
  &__subtitle {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    padding-bottom: 30px;
  }
  &__header {
    font-size: 18px;
  }
  &__hero {
    display: block;
    @include breakpoint($medium-up) {
      float: $rdirection;
      @include swap_direction(margin, 0 0 10px 20px);
      width: 644px;
    }
    img {
      @include breakpoint($medium-down) {
        width: 100%;
      }
    }
  }
}

.blog-content-wrap {
  padding: 0 12px;
  @include breakpoint($portrait-up) {
    padding: 0;
  }
}
