$basic-grid-gutter: 12px;

.basic-grid {
  @include pie-clearfix;
  @include breakpoint($portrait-up) {
    padding-left: $basic-grid-gutter;
    padding-right: $basic-grid-gutter;
    margin-right: (-1 * $basic-grid-gutter);
    margin-left: (-1 * $basic-grid-gutter);
  }
  &__item {
    padding: 0 0 $basic-grid-gutter 0;
    .basic-grid--small-cols-2 &,
    .basic-grid--small-cols-3 &,
    .basic-grid--small-cols-4 & {
      float: left;
      padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
    }
    .basic-grid--small-cols-2 & {
      width: 50%;
    }
    .basic-grid--small-cols-3 & {
      width: 33.333%;
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
    }
    .content-block-large,
    .content-block-large__image,
    .content-block-large__image img {
      height: 100%;
    }
    @include breakpoint($portrait-up) {
      float: left;
      width: 33.333%;
      padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
      .basic-grid--medium-cols-1 & {
        float: none;
        width: auto;
        padding: 0 0 $basic-grid-gutter;
      }
      .basic-grid--medium-cols-2 & {
        width: 50%;
      }
      .basic-grid--medium-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--medium-cols-4 & {
        width: 25%;
      }
    }
  }
  &__header {
    margin: 0 0 20px 0;
  }
  &__subheader {
    margin: 0 0 20px 0;
  }

  // In cases where basic formatters are used ensure the content fits
  .basic-responsive {
    word-break: break-word;
  }
}

.basic-grid-formatter {
  .basic-grid {
    @include breakpoint($portrait-up) {
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
    }
  }
  &--full-width {
    @include breakpoint($large-up) {
      position: relative;
      left: 50%;
      right: 50%;
      width: 100vw;
      margin: 0 -50.6vw;
    }
  }
}
