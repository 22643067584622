.header-large {
  height: auto;
  position: relative;
  width: 100%;
  &__inner {
    position: relative;
    @include breakpoint($portrait-up) {
      position: absolute;
      z-index: 3;
      top: 1em;
      bottom: auto;
      .header-large--no-image & {
        position: relative;
      }
    }
  }
  &__content {
    padding: 15px;
    @include breakpoint($portrait-up) {
      padding: 20px;
      padding-bottom: 25px;
    }
  }
  &__text {
    p {
      line-height: inherit;
      margin: 0;
    }
  }
  &__image,
  &__image img {
    width: 100%;
  }
  // If we have no image
  &--no-image {
    padding: 0;
    @include breakpoint($portrait-up) {
      height: 100px;
    }
  }
  &__content {
    line-height: 1;
  }
}
