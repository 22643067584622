/**** GIFTCARDS ****/
@include breakpoint($med-small-down) {
  .giftcards {
    .gift-card-mobile,
    .pg_wrapper {
      padding-top: 151px;
    }
    #content_wrapper {
      input[type='submit'] {
        float: none;
        width: 100%;
        margin: 10px 0;
        width: auto;
        font-size: 12px;
        margin-#{$ldirection}: 0px;
      }
      .overlay-wrapper {
        .close-link {
          display: inline-block;
          margin: 10px 10px;
        }
      }
    }
    #landing {
      .overlay-wrapper {
        .close-link {
          display: inline-block;
          margin: 10px 10px;
        }
      }
    }
    div#content_wrapper {
      font-size: 13px;
      font-weight: normal;
      margin: 10px 13px;
      text-align: justify;
      ol {
        margin: 10px 20px;
        text-align: justify;
      }
      li {
        b {
          margin: 10px 0px;
        }
      }
    }
  }
  #egiftcard {
    .gift-card-mobile {
      padding-top: 151px;
    }
    #content_wrapper {
      input[type='submit'] {
        float: none;
        width: 100%;
        margin: 10px 0;
        width: auto;
        font-size: 12px;
        margin-#{$ldirection}: 0px;
      }
      .overlay-wrapper {
        .close-link {
          display: inline-block;
          margin: 10px 10px;
        }
      }
    }
    #landing {
      .overlay-wrapper {
        .close-link {
          display: inline-block;
          margin: 10px 10px;
        }
      }
    }
    div#content_wrapper {
      font-size: 13px;
      font-weight: normal;
      margin: 10px 13px;
      text-align: justify;
      text-align: left;
      ol {
        margin: 10px 20px;
        text-align: justify;
      }
      li {
        b {
          margin: 10px 0px;
        }
      }
    }
    div#content {
      h2 {
        border: 0;
        font-size: 25px;
        font-weight: bold;
        padding-top: 5px;
        text-transform: none;
        background: none;
        margin-bottom: 0;
      }
    }
    .form_element {
      margin-bottom: 10px;
      textarea {
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        height: 28px;
        height: 90px;
        font-size: 12px;
      }
      input {
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        height: 28px;
      }
    }
    #content {
      h2 {
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
      }
    }
    .egiftcard-info {
      p {
        a {
          color: #014281;
          font-weight: bold;
        }
        b {
          color: #014281;
        }
      }
      .overlay-wrapper {
        float: #{$ldirection};
        padding-#{$rdirection}: 5px;
      }
    }
    .return-btn {
      font-weight: normal;
      line-height: 29px;
      height: 30px;
      margin-#{$ldirection}: 5px;
    }
    .add_progress {
      width: 85px;
      line-height: 30px;
      height: 30px;
      margin: 10px 0;
      display: inline-block;
    }
  }
  #sample {
    div#content_wrapper {
      font-size: 13px;
      font-weight: normal;
      margin: 10px 13px;
      text-align: justify;
      ol {
        margin: 10px 20px;
        text-align: justify;
      }
      li {
        b {
          margin: 10px 0px;
        }
      }
    }
  }
  #giftcard-about {
    div#content_wrapper {
      font-size: 13px;
      font-weight: normal;
      margin: 10px 13px;
      text-align: justify;
      text-align: left;
      ol {
        margin: 10px 20px;
        text-align: justify;
      }
      li {
        b {
          margin: 10px 0px;
        }
      }
    }
    div#content {
      h2 {
        border: 0;
        font-size: 25px;
        font-weight: bold;
        padding-top: 5px;
        text-transform: none;
        background: none;
        margin-bottom: 0;
        background: none;
        padding: 5px 0 0 0;
      }
    }
    #content {
      h2 {
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
      }
      h1 {
        font-size: 19px;
        padding: 10px 0 0px;
        display: block;
        font-weight: bold;
      }
      h3 {
        padding: 10px 0 0 0;
        margin: 0px;
        font-size: 14px;
      }
    }
    h1.terms_conditions {
      border-top: 1px solid #e0e9e4;
      padding-top: 13px !important;
      margin-top: 7px;
    }
    #content_body {
      font-size: 14px;
      ul {
        li {
          list-style-type: disc;
          margin-#{$ldirection}: 17px;
          padding: 3px 0;
          font-size: 14px;
        }
      }
      a {
        color: #014281;
        font-weight: bold;
      }
      .gift_card_phone {
        a {
          font-size: 14px;
          font-weight: normal;
          color: $color-black;
        }
      }
    }
  }
  #giftcard-retrieval {
    div#content_wrapper {
      font-size: 13px;
      font-weight: normal;
      margin: 10px 13px;
      text-align: justify;
      text-align: left;
    }
    #content_wrapper {
      input[type='submit'] {
        width: auto;
        font-size: 12px;
        margin-#{$ldirection}: 0px;
      }
    }
    #content {
      h2 {
        border: none;
        background: transparent;
        margin-bottom: 0 !important;
        padding: 0 0 5px 0 !important;
        font-size: 19px;
        text-transform: none !important;
      }
      p {
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
      }
      p#print-records {
        font-size: 15px;
        font-weight: normal !important;
      }
    }
    #egc-retrieval {
      margin-top: 10px;
      p {
        font-weight: bold;
        span {
          font-weight: normal !important;
        }
      }
    }
  }
  #giftcard-balance {
    div#content {
      h2 {
        border: 0;
        font-size: 25px;
        font-weight: bold;
        padding-top: 5px;
        text-transform: none;
        background: none;
        margin-bottom: 0;
        padding: 10px;
        font-size: 18px;
      }
      .form_column {
        p {
          padding: 10px 0;
          margin: 0;
        }
      }
    }
    #content {
      .box-content {
        padding: 0 13px 0;
      }
      .fs.giftcard {
        margin-top: 0;
      }
      #giftcard_balance {
        input[type='submit'] {
          float: none;
          width: auto;
          font-size: 12px;
          margin-#{$ldirection}: 0px;
        }
        .form_element {
          margin: 15px 0;
          input {
            display: block;
            width: 100%;
            margin-top: 5px;
          }
        }
      }
    }
    div.form_element {
      input {
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        height: 28px;
      }
    }
  }
  #giftcard-terms {
    div#content_wrapper {
      font-size: 13px;
      font-weight: normal;
      margin: 10px 13px;
      text-align: justify;
      text-align: left;
      ol {
        margin: 10px 20px;
        text-align: justify;
      }
      li {
        b {
          margin: 10px 0px;
        }
      }
    }
  }
  .giftcards {
    .giftcard_sample_look {
      margin: 35px 5px 10px;
      height: 90%;
      width: 93%;
    }
    .fs.giftcard {
      margin-top: 20px;
    }
    .gc-page-header {
      position: relative;
      margin: 10px 13px;
      .grey_button {
        padding: 1px 7px;
        margin: 0;
        position: absolute;
        top: 25%;
        right: 10px;
        background-image: none !important;
      }
    }
    ul#global_nav {
      li.egift_sections {
        a {
          text-transform: none;
        }
      }
    }
    ul#home_nav {
      li.egift_sections {
        a {
          text-transform: none;
        }
      }
    }
    .egift_breadcum {
      font-size: 11px;
      color: $color-black;
      padding: 7px 0 5px 15px;
      margin-bottom: 8px;
      border-bottom: 1px solid #cccccd;
      font-weight: bold;
      a {
        font-weight: normal;
        color: $color-black;
      }
    }
    .egift_navigation {
      padding-bottom: 6px;
    }
    .sub_nav_egifts {
      margin: 0 10px;
      border-bottom: 1px solid #b0b0b0;
      padding-bottom: 5px;
      font-size: 12px;
      font-weight: bold;
      color: #014281;
      a {
        font-size: 12px;
        font-weight: bold;
        color: #014281;
      }
      span {
        color: $color-black;
      }
    }
    .about_contact {
      font-size: 14px;
      font-weight: bold;
      padding: 10px 0 0 4px;
    }
    .giftcard_terms {
      div {
        display: inline;
      }
      p {
        padding: 10px 0;
        text-transform: lowercase;
      }
    }
    .value_container_textarea {
      label {
        padding-bottom: 0px;
      }
    }
  }
}
