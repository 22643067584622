.livechat__overlay__cbox {
  #cboxLoadedContent {
    padding: 0;
  }
  #cboxContent {
    margin: 0;
  }
}

.livechat__overlay__inner {
  position: relative;
  .livechat__overlay__image {
    display: block;
  }
  .livechat__overlay__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 100%;
    padding: 114px 0 0 8px;
    text-align: center;
  }
  .livechat__overlay__button {
    display: inline-block;
    line-height: 38px;
    padding: 0 18px;
    background: $color-black;
    color: $color-white;
    font-weight: bold;
    font-size: 14px;
  }
}
