///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules.
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 5px) {
  // hide the actual element
  //leaving commented in case brand decided to add a new custom checkbox
  //position: absolute;
  //left: -9999px;
  ~ label,
  ~ .label {
    &::before {
      margin-right: $padding;
    }
  }
  //leaving just in case brand decides to add a new custom checkbox.
  &:checked {
    ~ label,
    ~ .label {
    }
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-left: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-left: -$mid-border-width;
      } @else {
        margin-left: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// RTL support
@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

/// Typography related

@mixin h1 {
  @include font-smoothing;
  font-size: 2em;
  line-height: 2.5em;
}

@mixin h2 {
  @include font-smoothing;
  font-size: 1.5em;
  line-height: 2em;
}

@mixin h3 {
  @include font-smoothing;
  font-size: 1.17em;
  line-height: 1.5em;
}

@mixin h4 {
  @include font-smoothing;
  font-size: 1.12em;
  line-height: 1.25em;
}

/* Facebook Social Login */
@mixin h5 {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
}

@mixin font-small {
  font-size: 0.9em;
  @include breakpoint($large-up) {
    font-size: 18px;
  }
}

@mixin font-medium {
  font-size: 1.5em;
  @include breakpoint($large-up) {
    font-size: 2em;
  }
}

@mixin font-large {
  font-size: 2.5em;
  @include breakpoint($large-up) {
    font-size: 3em;
  }
}

/// Buttons and Links
@mixin button {
  @include font-smoothing;
  background: $color-black;
  color: $color-white;
  border: 0;
  font-weight: bold;
  padding: 0 12px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  line-height: $button-line-height;
  white-space: nowrap;
  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}

@mixin link {
  &:hover {
  }
  .touch & {
  }
}

// Outline style on focused elements for keyboard accessibility.
@mixin outline {
  outline: 1px solid $color-focus !important;
}

// Target all the headings
@mixin all-headings {
  h1, h2, h3,
  h4, h5, h6 {
    @content;
  }
}

// 2021 redesign
// Headline styles
@mixin font-heading {
  font-family: $font--helvetica-neue-bold;
  letter-spacing: $primary-letter-spacing;
}

// H1 - Content Blocks - xl display type
@mixin title--h1 {
  @include font-heading;
  font-size: get-rem(138px);
  line-height: get-line-height(138px, 112px);
  @include breakpoint($medium-up) {
    font-size: get-rem(246px);
    line-height: get-line-height(246px, 200px);
  }
}
// H2 - Content Blocks - headline style
@mixin title--h2 {
  @include font-heading;
  font-size: get-rem(80px);
  line-height: get-line-height(80px, 65px);
  @include breakpoint($medium-up) {
    font-size: get-rem(100px);
    line-height: get-line-height(100px, 80px);
  }
}
// H3 - primarily used on SPP
@mixin title--h3 {
  @include font-heading;
  font-size: get-rem(60px);
  line-height: get-line-height(60px, 48px);
  @include breakpoint($medium-up) {
    font-size: get-rem(75px);
    line-height: get-line-height(75px, 60px);
  }
}
// H4 - primarily used on MPP
@mixin title--h4 {
  @include font-heading;
  font-size: get-rem(52px);
  line-height: get-line-height(52px, 42px);
  @include breakpoint($medium-up) {
    font-size: get-rem(69px);
    line-height: get-line-height(69px, 56px);
  }
}
// H5 - primarily used on MPP
@mixin title--h5 {
  @include font-heading;
  font-size: get-rem(24px);
  line-height: $primary-line-height;
  @include breakpoint($medium-up) {
    font-size: get-rem(60px);
    line-height: get-line-height(60px, 44px);
  }
}
// H6 - Content Blocks - headline style
@mixin title--h6 {
  @include font-heading;
  font-size: get-rem(48px);
  line-height: get-line-height(48px, 51px);
}
// H7 - primarily used on SPP'
@mixin title--h7 {
  @include font-heading;
  font-size: get-rem(30px);
  line-height: $primary-line-height;
}
// H8 - primarily used on MPP / SPP
@mixin title--h8 {
  @include font-heading;
  font-size: get-rem(24px);
  line-height: $primary-line-height;
}

// Bopy copy styles 2021 redesign
// Body 1 - Content Blocks copy
@mixin body--1 {
  font-family: $font--helvetica-neue-bold;
  font-size: get-rem(35px);
  letter-spacing: $primary-letter-spacing;
  line-height: get-line-height(35px, 40px);
  @include breakpoint($medium-up) {
    font-size: get-rem(35px);
    line-height: get-line-height(35px, 40px);
  }
}
// Body 2 - primarily used on SPP
@mixin body--2 {
  font-family: $font--helvetica-neue-regular;
  font-size: get-rem(40px);
  letter-spacing: $primary-letter-spacing;
  line-height: get-line-height(40px, 40px);
  @include breakpoint($medium-up) {
    font-family: $font--helvetica-neue-light;
    font-size: get-rem(36px);
    line-height: $primary-line-height;
  }
}
// Body 3 - primarily used on MPP
@mixin body--3 {
  font-family: $font--helvetica-neue-light;
  font-size: get-rem(32px);
  letter-spacing: $primary-letter-spacing;
  line-height: $primary-line-height;
  @include breakpoint($medium-up) {
    font-size: get-rem(34px);
  }
}
// Body 4 - primarily used on MPP
@mixin body--4 {
  font-family: $font--helvetica-neue-light;
  font-size: get-rem(30px);
  letter-spacing: $primary-letter-spacing;
  line-height: $primary-line-height;
}
// Body 5 - primarily used on MPP / SPP
@mixin body--5 {
  font-family: $font--helvetica-neue-regular;
  font-size: get-rem(18px);
  letter-spacing: $primary-letter-spacing;
  line-height: $primary-line-height;
  @include breakpoint($portrait-up) {
    font-size: get-rem(24px);
  }
}
// Body 6 - primarily used on MPP
@mixin body--6 {
  font-family: $font--helvetica-neue-regular;
  font-size: get-rem(20px);
  letter-spacing: $primary-letter-spacing;
  line-height: $primary-line-height;
  @include breakpoint($medium-up) {
    font-family: $font--helvetica-neue-light;
  }
}
// Body 7 - primarily used on SPP
@mixin body--7 {
  font-family: $font--helvetica-neue-light;
  font-size: get-rem(16px);
  letter-spacing: $primary-letter-spacing;
  line-height: $primary-line-height;
}
// Body 8 - primarily used on SPP
@mixin body--8 {
  font-family: $font--helvetica-neue-regular;
  font-size: get-rem(20px);
  letter-spacing: $primary-letter-spacing;
  line-height: $primary-line-height;
}

// CTA styles 2021 redesign
@mixin btn($theme: 'light', $type: 'solid') {
  display: inline-block;
  font-family: $font--helvetica-neue-bold;
  font-size: get-rem(18px);
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0;
  overflow: unset;
  width: auto;
  padding: 18px 25px;
  @include breakpoint($landscape-up) {
    min-width: 180px;
  }
  @if $type == 'outline' {
    @if $theme == 'dark' {
      border: get-rem(2px) solid $color-white;
      color: $color-white;
    } @else {
      border: get-rem(2px) solid $color-black;
      color: $color-black;
    }
  } @else {
    // solid.
    @if $theme == 'dark' {
      background: $color-white;
      color: $color-black;
      border: get-rem(2px) solid $color-white;
    } @else {
      background: $color-black;
      color: $color-white;
      border: get-rem(2px) solid $color-black;
    }
  }
}

// IE11 only
@mixin ie11-only {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin cta() {
  display: inline-block;
  font-family: $font--helvetica-neue-bold;
  font-size: get-rem(26px);
  line-height: 1;
  text-transform: capitalize;
  overflow: unset;
  padding: 0;
  @if $theme == 'dark' {
    color: $color-white;
  } @else {
    color: $color-black;
  }
  @include breakpoint($medium-up) {
    font-size: get-rem(35px);
  }
}

@mixin spp-price-format() {
  color: $color-black;
  font-family: $font--helvetica-neue-bold;
  letter-spacing: $base-letter-spacing;
  font-size: get-rem(16px);
  line-height: get-line-height(16px, 12px);
  margin-bottom: 10px;
  @include breakpoint($portrait-up) {
    font-size: get-rem(27px);
    line-height: get-line-height(27px, 27px);
  }
}

@mixin spp-headings() {
  @include font-heading;
  text-transform: uppercase;
  font-size: get-rem(30px);
  line-height: get-line-height(30px, 30px);
  letter-spacing: $base-letter-spacing;
  margin-bottom: 10px;
  @include breakpoint($landscape-up) {
    font-size: get-rem(75px);
    line-height: get-line-height(75px, 70px);
    margin-bottom: 20px;
  }
}

@mixin svg-icon-inline-mask($filename) {
  -webkit-mask: inline-image('icons/src/#{$filename}');
  mask: inline-image('icons/src/#{$filename}');
}

// standalone shared animations.
@mixin shimmer($duration: 1s, $easing: linear) {
  animation: elc-shimmer $duration 60 $easing;
  backface-visibility: hidden;
  background: $color-shimmer-grey-light
    linear-gradient(
      -45deg,
      $color-shimmer-grey-light 0%,
      $color-shimmer-grey-light 40%,
      $color-shimmer-grey 50%,
      $color-shimmer-grey-light 60%,
      $color-shimmer-grey-light 100%
    );
  background-size: 250% 250%;
  perspective: 1000px;
  transform: rotateZ(360deg);
  will-change: transform;
  [dir='rtl'] & {
    animation: elc-shimmer-rtl $duration 60 $easing;
    backface-visibility: hidden;
    background: $color-shimmer-grey-light
      linear-gradient(
        45deg,
        $color-shimmer-grey-light 0%,
        $color-shimmer-grey-light 40%,
        $color-shimmer-grey 50%,
        $color-shimmer-grey-light 60%,
        $color-shimmer-grey-light 100%
      );
    background-size: 250% 250%;
    perspective: 1000px;
    transform: rotateZ(360deg);
    will-change: transform;
  }
}

@mixin fade-in($duration: 1s, $easing: linear) {
  animation: fade-in $duration $easing forwards;
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $primary-font;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $primary-font;
  font-size: 12px;
  line-height: get-line-height(12px, 18px);
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $primary-font;
  font-size: 14px;
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $primary-font;
  font-size: 12px;
  line-height: get-line-height(12px, 14px);
}
