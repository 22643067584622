.button {
  @include button;
  &--dark {
    background: $color-black;
    color: $color-white;
    border: none;
  }
  &--disabled {
    background: $color-light-gray;
    color: $color-black;
    border: 1px solid $color-light-gray;
    .icon {
      display: none;
    }
    &:hover {
      color: $color-black;
    }
  }
  &--small {
    font-size: 11px;
    // padding: 1px 4px 0 4px;
    @include border-radius(5px);
  }
  // close button with x icon inside an href
  // assumes relatively positioned parent
  &--close {
    background: transparent;
    position: absolute;
    z-index: 2;
    right: 1em;
    top: 1em;
    text-decoration: none;
    width: 22px;
    height: 22px;
    border: $border;
    .icon {
      color: $black;
      font-size: 20px;
    }
  }
  // Used for inline marketing CTAs CX-7174
  &--transparent {
    background: none;
    border: 1px solid $color-black;
    color: $color-black;
  }
  &--transparent-white {
    background: none;
    border: 1px solid $color-white;
    color: $color-white;
  }
  &--light {
    background: $color-white;
    color: $color-black;
    border: 1px solid $color-black;
  }
}

///
/// Links
///

.link {
  @include link;
}

.btn {
  &--primary {
    // .btn--primary-light
    &-light {
      @include btn('dark', 'solid');
      &:hover {
        color: $color-black;
      }
    }
    // .btn--primary-dark
    &-dark {
      @include btn('light', 'solid');
    }
  }
  &--secondary {
    // .btn--secondary-light
    &-light {
      @include btn('dark', 'outline');
    }
    // .btn--secondary-dark
    &-dark {
      @include btn('light', 'outline');
    }
  }
  &--cta {
    // .btn--cta-light
    &-light {
      @include btn('dark', 'cta');
    }
    // .btn--cta-dark
    &-dark {
      @include btn('light', 'cta');
    }
  }
  &--play-video {
    font-family: $font--helvetica-neue-bold;
    font-size: get-rem(26px);
    line-height: 1;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    color: $color-white;
    padding: 0;
    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-#{$ldirection}: 24px solid $color-white;
      border-bottom: 14px solid transparent;
      margin-#{$rdirection}: 12px;
    }
    &:hover {
      color: inherit;
    }
    &--dark {
      color: $color-black;
      &:before {
        border-#{$ldirection}: 24px solid $color-black;
      }
      &:hover {
        color: inherit;
      }
    }
  }
}
