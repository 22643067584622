.section-customer-service-privacy-policy {
  .site-content {
    .cs-page {
      &__title {
        padding-left: 0;
        h1 {
          font-size: 24px;
        }
      }
      &__content {
        width: 50%;
        left: 50%;
      }
      &__content-item {
        padding: 0;
        .customer-service-text {
          line-height: 23px;
          .section {
            margin: 0;
          }
          ul {
            margin-left: 0;
            font-weight: bold;
            font-size: 13px;
            text-decoration: underline;
            line-height: 23px;
            margin-top: 0;
            text-transform: capitalize;
            margin-bottom: 10px;
            float: left;
            width: 100%;
            li {
              list-style-type: none;
            }
          }
        }
      }
    }
    p {
      line-height: 1.45em;
    }
  }
}
