// Blog pagination styles

.blog-pagination {
  padding: 12px 0;
  text-align: center;
  ul {
    li {
      padding: 0 6px;
      display: inline-block;
      font-weight: bold;
      a {
        font-weight: normal;
      }
      &:first-child {
        padding-#{$ldirection}: 0;
      }
      &:last-child {
        padding-#{$rdirection}: 0;
      }
    }
  }
}
