.mpp-tile {
  position: relative;
  &__image {
    img {
      width: 100%;
    }
  }
  &__content {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 10px;
    padding-top: 10px;
    @include breakpoint($portrait-up) {
      padding-top: 25px;
    }
    .header--medium {
      font-size: 24px;
      @include breakpoint($portrait-up) {
        font-size: 32px;
      }
    }
    p {
      line-height: 1;
      margin-bottom: 0;
    }
  }
  &__text {
    @include swap_direction(margin, 0 20px 10px);
  }
  &__cta {
    a {
      line-height: 28px;
    }
    .button {
      &--transparent {
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
