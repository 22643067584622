$hex-width: 40px;
$hex-per-row: 8;

.product-shade-picker {
  @include pie-clearfix;
  &__smoosh {
    display: inline-block;
    margin: 0 auto 20px;
    &-img {
      display: block;
    }
    @include breakpoint($medium-up) {
      float: right;
      margin: 0 0px 0 20px;
    }
  }
  &__shades {
    @include pie-clearfix;
    font-size: 0;
    white-space: nowrap;
    width: 100%;
    @include breakpoint($medium-up) {
      // Max width of the number of hexes we want per row
      // times the hex width, plus 8 margin offset per hex
      max-width: ($hex-width * $hex-per-row + (8 * $hex-per-row));
      float: left;
      width: auto;
    }
  }
  &__shade {
    @include box-shadow(0 0 0 1px $color-gray);
    @include transition(box-shadow 0.3s);
    float: left;
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
    margin-right: 10px;
    @include breakpoint($medium-up) {
      width: $hex-width;
      height: $hex-width;
    }
    &:hover,
    &.active {
      @include box-shadow(0 0 0 1px $color-black);
    }
  }
}
