.technology-block {
  text-align: center;
  color: $color-black;
  position: relative;
  @include breakpoint($large-up) {
  }
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }
  &__content {
    padding: 40px 10px;
    @include breakpoint($large-up) {
      padding: 50px 30px;
      position: absolute;
      #{$ldirection}: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 60%;
    }
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__header {
    display: inline-block;
    position: relative;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: bold;
    &:after {
      border-bottom: 1px solid $color-black;
      position: absolute;
      content: '';
      bottom: -12px;
      width: 80%;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
      @include breakpoint($large-up) {
        border-bottom-color: $color-black;
      }
    }
  }
  &__copy {
    margin-bottom: 30px;
    line-height: 2.5em;
    font-size: 14px;
  }
  &__list {
    @include breakpoint($large-up) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__item {
    padding: 0 5px;
    overflow: hidden;
    max-width: 80%;
    margin: 20px auto 25px;
    @include breakpoint($large-up) {
      width: 50%;
      margin: auto;
      margin-bottom: 20px;
    }
    &-icon {
      width: 70px;
      height: 60px;
      float: #{$ldirection};
      position: relative;
      margin-bottom: 10px;
      margin-#{$rdirection}: 25px;
      @include breakpoint($large-up) {
        margin: auto;
        float: none;
      }
      img {
        @include breakpoint($large-up) {
          position: absolute;
          top: 50%;
          #{$ldirection}: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &-stat {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 5px;
      text-align: #{$ldirection};
      @include breakpoint($large-up) {
        text-align: center;
      }
    }
    &-copy {
      text-align: #{$ldirection};
      overflow: hidden;
      @include breakpoint($large-up) {
        text-align: center;
      }
    }
  }
  &--future-rescue {
    .technology-block__bg {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
      @include breakpoint($large-up) {
        position: relative;
      }
    }
    .technology-block__content {
      position: relative;
      z-index: 2;
      color: $color-white;
      @include breakpoint($large-up) {
        position: absolute;
        padding: 50px 30px;
        #{$rdirection}: 40px;
        #{$ldirection}: auto;
        top: 50%;
        transform: translateY(-50%);
        width: 60%;
      }
    }
    .technology-block__lead {
      margin: 5px auto 15px;
    }
    .technology-block__header {
      font-size: 26px;
      line-height: 1.1em;
      margin-bottom: 5px;
      &:after {
        content: none;
      }
    }
    .technology-block__shop {
      margin: 5px auto 20px;
    }
    .technology-block__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 300px;
      @include breakpoint($large-up) {
        margin-top: 0;
      }
    }
    .technology-block__item {
      padding: 0 5px;
      overflow: hidden;
      width: 50%;
      margin: auto;
      margin-bottom: 20px;
      text-align: center;
      &-icon {
        float: none;
        margin: auto;
        margin-bottom: 10px;
        img {
          position: absolute;
          top: 50%;
          #{$ldirection}: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &-stat,
      &-copy {
        text-align: center;
      }
    }
  }
}
