.store-locator {
  padding: 20px 15px 0 15px;
  @include breakpoint($portrait-up) {
    @include clearfix;
    padding: 30px 22px;
  }
  &__search-form {
    @include breakpoint($portrait-up) {
      float: left;
      width: 30%;
    }
    .is-directions & {
      display: none;
    }
    select {
      display: block;
      width: 100%;
      margin: 3px 0 10px 0;
      font-size: 12px;
    }
  }
  &__directions {
    display: none;
    @include breakpoint($portrait-up) {
      width: 30%;
      float: left;
    }
    .is-directions & {
      display: block;
    }
  }
  &__or,
  label {
    text-transform: uppercase;
    font-weight: bold;
  }
  input.store-locator__input,
  input.directions-form__input {
    // padding: 5px;
    // line-height: normal;
    display: block;
    width: 100%;
    // height: auto;
    margin: 3px 0 10px 0;
    // font-size: 12px;
  }
  &__or {
    margin: 10px 0;
  }
  &__title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 10px;
    .is-results & {
      display: none;
    }
  }
  &__results-title,
  &__new-search {
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__info {
    display: none;
    .is-results & {
      display: block;
    }
  }
  .results-count {
    padding-bottom: 20px;
  }
  &__results-copy {
    @include breakpoint($portrait-up) {
      margin-bottom: 30px;
    }
  }
  .search-submit {
    margin: 5px 0 0 0;
    width: 100%;
    @include breakpoint($portrait-up) {
      width: auto;
    }
  }
  &__form-note {
    color: $color-medium-gray;
    margin: 10px 0 20px 0;
    font-size: 10px;
    @include breakpoint($portrait-up) {
      margin-bottom: 0;
    }
  }
  &__landing-content,
  &__body {
    @include breakpoint($portrait-up) {
      float: right;
      width: 67%;
      margin: 2px 0 0 3%;
    }
    .is-results & {
      display: none;
    }
  }
  &__landing-content {
    .is-results & {
      display: none;
    }
  }
  &__landing-img,
  &__landing-html {
    margin: 0 0 15px 0;
  }

  // Hide the map on mobile displays
  // Remove this block to re-show it
  &__map-canvas-container {
    display: none;
    @include breakpoint($portrait-up) {
      display: block;
    }
  }
  &__map-canvas-container,
  &__map-canvas {
    .is-results & {
      min-height: 440px;
      @include breakpoint($portrait-up) {
        height: 100%;
      }
    }
  }
  &__results-title,
  &__results-copy,
  &__new-search,
  &__body,
  &__results-container {
    display: none;
    .is-results & {
      display: block;
    }
  }
  &__results-container {
    @include breakpoint($portrait-up) {
      padding-top: 40px;
      clear: both;
    }
    .doors_results {
      width: 100%;
    }
    .store-locator__tooltip {
      .column {
        @include clearfix;
        border-bottom: 1px solid #ccc;
        padding: 15px 0 20px 0;
        cursor: pointer;
        @include breakpoint($portrait-up) {
          display: table;
          width: 100%;
        }
      }
      &-name,
      &-info,
      &-directions {
        @include breakpoint($portrait-up) {
          display: table-cell;
          width: 33.3333%;
          vertical-align: middle;
          padding: 30px 0 30px 30px;
        }
      }
      &-directions {
        @include breakpoint($portrait-up) {
          text-align: center;
          padding-left: 0;
        }
      }
      .marker {
        color: $color-dark-gray;
        font-weight: 900;
      }
    }
  }
  &__directions-button {
    margin: 10px auto 0 auto;
  }
  &__results-header {
    @include clearfix;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: $color-gray;
    display: none;
    @include breakpoint($portrait-up) {
      display: block;
    }
  }
  &__results-header-col {
    width: 33.3333%;
    float: left;
    text-transform: uppercase;
    line-height: 25px;
    font-weight: bold;
    padding-left: 30px;
  }
  &__directions-start,
  &__directions-end {
    display: none;
  }
  .directions-form {
    margin: 0 0 15px 0;
    &__results {
      width: 100%;
    }
    &__submit {
      margin-top: 5px;
    }
    &__back-to-search {
      cursor: pointer;
      margin-top: 12px;
    }
  }

  // Genie
  .hidden,
  .inactive,
  .hide {
    display: none !important;
  }
  #map-canvas {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

// Travel/Retail locations
.store-locator {
  &__travelretail-form {
  }
}

// Navigatonal tabs
.store-locator {
  &__tabs {
    margin: 0 0 30px 0;
    li {
      display: block;
      margin-bottom: 4px;
      padding: 10px 20px;
      background-color: $color-grey;
      cursor: pointer;
      @include breakpoint($portrait-up) {
        display: inline-block;
        margin: 0;
      }
      &.active {
        background-color: $color-dark-blue;
        color: $color-white;
      }
    }
  }
}
