.benefits {
  text-align: center;
  position: relative;
  @include breakpoint($portrait-up) {
    color: $color-white;
  }
  img {
    display: block;
    max-width: 100%;
  }
  &__content {
    padding: 40px 10px;
    @include breakpoint($portrait-up) {
      padding: 50px 30px;
      position: absolute;
      #{$rdirection}: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;
    }
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__header {
    display: inline-block;
    position: relative;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: bold;
    &:after {
      border-bottom: 1px solid $color-black;
      position: absolute;
      content: '';
      bottom: -12px;
      width: 80%;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
      @include breakpoint($portrait-up) {
        border-bottom-color: $color-white;
      }
    }
  }
  &__copy {
    margin-bottom: 30px;
    line-height: 2.5em;
    font-size: 14px;
  }
  table {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    @include breakpoint($portrait-up) {
      width: 400px;
      margin: auto;
    }

    // Columns
    .benefits__benefit {
      width: 50%;
      @include breakpoint($portrait-up) {
        width: 60%;
      }
      // Individual stat cells / blocks
      &-outer {
        width: 60%;
        margin: auto;
        display: block;
        text-align: #{$ldirection};
        @include breakpoint($portrait-up) {
          width: 90%;
          display: block;
          background: rgba($color-gray, 0.2);
          padding: 0 20px;
        }
      }
      &-wrapper {
        display: block;
        padding: 20px 0;
        border-bottom: 1px solid $color-gray;
        @include breakpoint($portrait-up) {
          border-bottom-color: $color-white;
        }
        span {
          display: block;
        }
      }
      &-headline {
        text-transform: uppercase;
        font-weight: bold;
        margin: 6px 0;
        font-size: 16px;
      }
      &-stat {
        font-size: 50px;
        font-weight: bold;
        line-height: 1;
        @include breakpoint($portrait-up) {
          font-size: 54px;
        }
      }
      &-subline {
        font-size: 14px;
      }
      &--promo {
        border-#{$ldirection}: 1px solid $color-gray;
        padding-#{$ldirection}: 0px;
        vertical-align: middle;
        @include breakpoint($portrait-up) {
          border: none;
          width: 45%;
          background: rgba($color-gray, 0.2);
          .benefits__benefit-outer {
            background: none;
          }
        }
        &-wrapper {
          border: none;
        }
      }
    }
    .footnote {
      font-size: 11px;
      border: none;
      padding-bottom: 0;
      padding-top: 5px;
      display: block;
      @include breakpoint($portrait-up) {
        padding-bottom: 10px;
      }
    }
  }
  &__quickshop {
    margin-top: 30px;
  }
}
