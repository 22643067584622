.terms-tooltip {
  bottom: 2px;
  position: relative;
  cursor: pointer;
  .icon--information {
    width: 16px;
    margin-#{$ldirection}: 2px;
    height: 16px;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    background-color: $color-white;
    color: $color_black;
    width: 190px;
    border: 1px solid $color-grey;
    padding: 10px;
    position: absolute;
    z-index: 1;
    #{$ldirection}: -96px;
    opacity: 0;
    transition: opacity 0.3s;
    height: auto;
    font-size: 12px;
    font-weight: normal;
    text-align: #{$ldirection};
    font-family: $font--helvetica-neue-regular;
    letter-spacing: normal;
    line-height: 1.4;
  }
  .tooltiptext-under {
    top: 165%;
    @include breakpoint($landscape-up) {
      top: 150%;
      width: 235px;
      #{$ldirection}: -123px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      #{$ldirection}: 55%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color_black transparent;
    }
  }
  .tooltiptext-over {
    @include breakpoint($landscape-up) {
      bottom: 125%;
    }
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      #{$ldirection}: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: $color_black transparent transparent transparent;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}
