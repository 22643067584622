.change-password {
  .password-field {
    .password-field {
      &__info {
        &-checkout {
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 0;
          }
        }
        &-reset {
          @include breakpoint($medium-up) {
            #{$ldirection}: 30%;
            width: 30%;
          }
        }
        &:before {
          @include breakpoint($medium-up) {
            #{$ldirection}: -5%;
            border: 7px solid transparent;
            border-#{$rdirection}-color: $color-gray;
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__rules {
        li {
          display: flex;
        }
      }
    }
  }
  .sign-in-component__password-wrapper {
    .password-field {
      &__info {
        &-checkout {
          #{$ldirection}: 75%;
        }
      }
    }
  }
}
