.expert-skincare {
  padding-top: 40px;
  &__titles {
    text-align: center;
    h1 {
      line-height: 1;
      margin-bottom: 5px;
    }
    h2 {
      max-width: 70%;
      margin: auto;
      padding: 0 20px;
    }
  }
  &__inner {
    position: relative;
    padding-bottom: 20px;
  }
  &__bg {
    display: block;
    width: 100%;
    height: auto;
  }
  .array {
    height: 100%;
    width: 100%;
    a {
      display: block;
      position: absolute;
      color: #000;
      max-width: 20%;
      @include breakpoint($large-up) {
        max-width: 25%;
      }
      &:hover span {
        background-color: #0065b1;
      }
      img {
        width: 100%;
      }
      span {
        color: $white;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
      &.wash {
        width: 170px;
        left: 6%;
        top: 25%;
        @include breakpoint($large-up) {
          top: 28%;
        }
        span {
          left: -4px;
          top: 92px;
          @include breakpoint($large-up) {
            top: 184px;
          }
        }
        .desc {
          top: 0;
          z-index: 10;
          @include breakpoint($large-up) {
            left: -29px;
            top: 224px;
            width: 185px;
          }
        }
      }
      &.shave {
        width: 235px;
        left: 30%;
        top: 8%;
        @include breakpoint($large-up) {
          top: 5%;
        }
        span {
          left: -16px;
          top: 81px;
          @include breakpoint($large-up) {
            top: 181px;
          }
        }
        .desc {
          top: 0;
          z-index: 9;
          left: -41px;
          width: 190px;
          @include breakpoint($large-up) {
            top: 221px;
          }
        }
      }
      &.rescue {
        width: 130px;
        left: 58%;
        top: 27%;
        @include breakpoint($large-up) {
          top: 40%;
        }
        span {
          left: -33px;
          top: 86px;
        }
        .desc {
          left: -58px;
          width: 195px;
          top: 0;
          z-index: 8;
          @include breakpoint($large-up) {
            top: 126px;
          }
        }
      }
      &.defense {
        width: 170px;
        left: 76%;
        top: 5%;
        @include breakpoint($large-up) {
          top: 6%;
        }
        span {
          top: 98px;
          right: 0;
          @include breakpoint($large-up) {
            top: 198px;
          }
        }
        .desc {
          top: 0px;
          right: 0;
          width: 240px;
          text-align: right;
          z-index: 7;
          @include breakpoint($large-up) {
            left: -66px;
            top: 238px;
          }
        }
      }
      .desc {
        display: none;
        position: absolute;
        background: #fff;
        opacity: 0.95;
        padding: 25px;
        h2 {
          text-transform: uppercase;
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 24px;
          @include breakpoint($large-up) {
            font-size: 30px;
            line-height: 30px;
          }
        }
        h3 {
          font-size: 13px;
          line-height: 17px;
          font-weight: bold;
        }
        p {
          font-size: 13px;
          line-height: 17px;
        }
      }
      span {
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        background: $black;
      }
    }
  }
}

.expert-skincare .array a:hover span {
  background-color: #0065b1;
}
