.product-flag {
  &__container {
    text-align: $ldirection;
  }
  &__flag {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5em;
    color: $color-dark-blue;
  }
}
