@mixin vertically-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.offer-grid-item {
  float: left;
  position: relative;
  width: 32.333333333%;
  height: 37%;
  margin: 0.5%;
  border: 1px solid #ffffff;

  // When the offer grid is opened, more items are shown.
  .open & {
    height: 19.5%;
  }
  &:hover {
    cursor: pointer;
  }

  // Larger screens show all the items right away.
  @include breakpoint($portrait-up) {
    width: 19%;
    height: 32.3333333333%;
  }

  // Helps position the content vertically, no matter the content.
  &__content {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1em;
    color: #ffffff;
    &--product {
      background-color: #ffffff;
      text-align: center;
    }
  }
  &__number {
    @include vertically-center;
    font-size: 8em;
    line-height: 1em;
    text-align: center;
  }
  &__copy {
    @include vertically-center;
    span {
      line-height: 1em;
    }
  }
  &__image {
    max-height: 100%;
  }
  &__quickshop {
    #cboxLoadedContent {
      padding: 3em;
      text-align: center;
    }
  }
} // .offer-grid-item
