///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
///
///

.gnav-util-formatter__item {
  display: inline-block;
  float: #{$ldirection};
  padding: 0 30px;
  width: 30px;
}

.gnav-util__link {
  display: inline-block;
  padding: 0 1em;
}

.gnav-util__icon {
  color: $black;
  position: relative;
  .icon:before {
    font-size: 22px;
  }
  &__cart-count {
    border-radius: 50%;
    color: $white;
    line-height: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding-top: 8px;
  }
}

.gnav-util__content {
  position: absolute;
  #{$rdirection}: -2px;
  background: $white;
  border: $border;
  width: 322px;
  z-index: 3;
  top: 46px;
  @include breakpoint($large-up) {
    top: 34px;
  }
  &-close {
    background: transparent;
    position: absolute;
    z-index: 2;
    #{$rdirection}: 1em;
    top: 1em;
    text-decoration: none;
    width: 24px;
    .icon {
      color: $black;
      font-size: 20px;
    }
  }
  &-inner {
    padding: 1em 40px 1em 1em;
  }
}

img.gnav-util__avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
