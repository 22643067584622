.section {
  &-gifts,
  &-gifts-page {
    &__title {
      display: none;
    }
    .site-content {
      @include breakpoint($large-up) {
        max-width: none;
      }
      div.basic-formatter__item {
        max-width: auto;
        &:nth-child(-n + 2) {
          padding-top: 0;
        }
        @include breakpoint($large-up) {
          max-width: 960px;
          margin: 0 auto;
          &:nth-child(-n + 2) {
            max-width: 100%;
            padding-top: 5px;
          }
        }
      }
      .product-grid {
        &__item {
          border: 0px;
        }
        &_header {
          font-size: 24px;
          margin-bottom: 0px;
          text-transform: capitalize;
          display: inline-block;
          @include breakpoint($medium-up) {
            margin-bottom: 25px;
          }
        }
        &_sub_header {
          display: none;
        }
      }
      &__item--promo {
        padding-bottom: 55px;
        position: relative;
        .node,
        .content,
        .block {
          height: 100%;
        }
      }
      .product-promo {
        @include swap_direction(padding, 0px);
        margin-bottom: 40px;
        text-align: center;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 20px);
          height: 100%;
          margin-bottom: 0;
          @include swap_direction(margin, 0 auto);
          min-height: 520px;
          padding-bottom: 20px;
        }
        &__wrapper-link {
          height: 100%;
          display: table;
          color: $color-black;
          text-decoration: none;
          width: 100%;
          @include breakpoint($medium-up) {
            display: block;
          }
          &:hover {
            text-decoration: none;
          }
        }
        &__wrapper {
          @include swap_direction(margin, 0 auto);
          max-width: 420px;
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 0);
            max-width: none;
          }
        }
        &__image {
          display: none;
          margin-bottom: 15px;
          vertical-align: bottom;
          width: 100%;
          @include breakpoint($medium-up) {
            margin-bottom: 7%;
          }
        }
        &__headline {
          font-size: 12px;
          letter-spacing: 0.24em;
          @include swap_direction(padding, 0 0 15px);
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            font-size: 14px;
            padding-bottom: 50px;
          }
          &.product-promo__headline--hide {
            display: none;
            @include breakpoint($medium-up) {
              display: block;
            }
          }
        }
        &__title {
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0.1em;
          line-height: 22px;
          margin-bottom: 15px;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            font-size: 22px;
            line-height: 27px;
          }
          p {
            line-height: inherit;
            @include swap_direction(margin, 0);
          }
          span {
            color: $color-pink;
            display: block;
            font-size: 12px;
            line-height: 17px;
            @include breakpoint($medium-up) {
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
        &__copy {
          font-size: 12px;
          line-height: 17px;
          margin-bottom: 15px;
          @include breakpoint($medium-up) {
            font-size: 14px;
            line-height: 19px;
          }
          &.product-promo__copy--hide {
            display: none;
            @include breakpoint($medium-up) {
              display: block;
            }
          }
        }
        &__link {
          @include breakpoint($medium-up) {
            bottom: 20px;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
            position: absolute;
            width: 100%;
          }
          span {
            border-bottom: 2px solid $color-pink;
            color: $color-pink;
            font-size: 12px;
            line-height: 17px;
            @include breakpoint($medium-up) {
              font-size: 14px;
              line-height: 19px;
            }
            &:hover {
              border-color: $color-black;
              color: $color-black;
            }
          }
        }
        &__link--alt {
          span {
            border: 1px solid $color-pink;
            @include swap_direction(padding, 5px 10px);
            &:hover {
              border-color: $color-black;
            }
          }
        }
        &.product-promo--bottom {
          height: 260px;
          @include breakpoint($medium-up) {
            height: 100%;
          }
        }
      }
    }
  }
  &-gifts,
  &-gifts-under-50,
  &-gifts-under-100,
  &-gifts-for-the-jet-setter,
  &-gifts-for-the-style-guy,
  &-gifts-for-the-night-owl,
  &-gifts-for-the-gentleman,
  &-gifts-for-the-tastemaker,
  &-gift-sets-exclusives,
  &-gift-sets-newcomer,
  &-gift-sets-sophisticate,
  &-gift-sets-age-fighter,
  &-gift-sets-globetrotter,
  &-gift-sets-gym-goer,
  &-gift-sets-all-gifts {
    .header-links {
      background: transparent;
      text-align: center;
      margin-bottom: 0;
      border-bottom: 0;
      padding: 0;
      @include breakpoint($portrait-up) {
        margin-bottom: 20px;
        border-bottom: 1px solid $color-light-grey-tone;
      }
      &__title {
        display: none;
      }
      &__link {
        flex: 1 1 auto;
        display: inline-block;
        margin: 0 15px;
        padding: 20px 0;
        font-size: 14px;
        color: $color-black;
        font-weight: bold;
        @include breakpoint($medium-up) {
          display: initial;
        }
      }
      @include breakpoint($large-up) {
        .one_row &__link,
        .two_row &__link {
          width: 150px;
          display: inline-flex;
          height: 23px;
          justify-content: left;
          align-items: center;
          padding-bottom: 0px;
          padding-top: 0px;
          margin-bottom: 10px;
        }
        .one_row &__link {
          margin-top: 10px;
        }
      }
      .gift-set-select {
        width: 100%;
        border: 0;
        background-color: $color-light-grey-tone;
        height: 50px;
        line-height: 50px;
        &:hover {
          text-decoration: none;
        }
        .selectBox {
          &-label {
            height: 50px;
            line-height: 50px;
          }
          &-arrow {
            top: 3px;
            transform: rotate(-90deg);
            &:before {
              font-size: 25px;
            }
          }
        }
        &.selectBox-menuShowing {
          .selectBox-arrow {
            transform: rotate(0deg);
            $rdirection: 15px;
          }
        }
      }
    }
    .gift-set-header-links,
    .gift-banner {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
