.welcome15-overlay {
  .site-email-signup {
    &__success {
      width: 360px;
      margin: 0 auto;
      text-align: #{$ldirection};
      @include breakpoint($medium-down) {
        height: 260px;
        margin: 40px auto 0;
      }
      &-header {
        font-size: 20px;
        color: $color-blue;
        text-transform: uppercase;
        margin-bottom: 0.5em;
      }
      &-offer {
        margin-bottom: 2em;
      }
    }
    &__terms-details {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 0;
      height: 0;
      background: $white;
      overflow: hidden;
      padding: 30px;
      z-index: 100;
      display: none;
    }
    &__close-terms {
      text-transform: uppercase;
    }
    &__legal-text {
      padding-top: 10px;
    }
  }
}

.welcome-15_site-email-signup {
  .form-text {
    width: 250px;
    height: 30px;
    position: relative;
    top: 2px;
  }
}
