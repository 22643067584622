.blog-video-image {
  &__full-width {
    img,
    iframe {
      width: 100%;
    }
    padding-top: 143px;
    @include breakpoint($large-up) {
      padding-top: 0;
    }
  }
}

.site-content {
  &-container {
    padding-top: 0;
  }
  .blog-image {
    @include swap_direction(margin, 3% auto 0 auto);
    iframe,
    img {
      width: 100%;
    }
    width: 85%;
    @include breakpoint($large-up) {
      width: 100%;
    }
  }
  .blog-text {
    font-size: 18px;
    font-family: $font--helvetica-neue-regular;
    margin-bottom: 3%;
    margin-top: 3%;
  }
}

.video-or-image-carousel {
  &__video {
    iframe {
      @include breakpoint($portrait-only) {
        min-height: 341px;
      }
      @include breakpoint($large-up) {
        min-height: 502px;
      }
    }
  }
  &__iframe {
    &--video {
      @include breakpoint($portrait-only) {
        min-height: 341px;
      }
      @include breakpoint($large-up) {
        min-height: 502px;
      }
    }
  }
}
