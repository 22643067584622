.loyalty__panel__join {
  @include loyalty-panel;
  display: block;
  clear: both;
  &.is-member {
    display: none;
  }
  &.index-1 {
    #loyalty__page__about & {
      .loyalty__panel__join__title {
        display: none;
      }
    }
  }
  @include breakpoint($medium-up) {
  }
}
