/* .csrHide { display: none; } */
#csr_header {
  position: absolute;
  width: 750px;
  height: auto;
  border: 5px red solid;
  padding: 10px;
  font-size: 11px;
  font-family: 'AkzidenzGrotesk', arial, sans-serif;
  background-color: #f7f7f7;
  z-index: 9999;
}

#csr_header th,
#csr_header td {
  font-family: 'AkzidenzGrotesk', arial, sans-serif;
}

#csr_info_box {
  border-left: none;
}

#csr_header a {
  font-size: 1.25em;
  display: block;
  text-decoration: none;
}

#csr_header a:hover {
  text-decoration: underline;
}

.csr_table {
  width: 100%;
  margin: 0;
  table-layout: auto;
  line-height: normal;
}

.csr_table td {
  border-left: 1px red solid;
  padding: 0 20px;
  border-bottom: none;
}

#csr_dragger {
  background: red;
  width: 40px;
  border: none;
  cursor: move;
  padding: 0;
}

.shadow {
  -webkit-box-shadow: 3px 3px 4px 5px #ccc;
  -moz-box-shadow: 3px 3px 4px 5px #ccc;
  box-shadow: 3px 3px 4px 5px #ccc;
}
